import React, { useState, useEffect } from "react";
import { useSearchBreedsQuery } from "../api/graphql.gen";
import css from "../styles/BreedOverview.module.scss";
import "rc-slider/assets/index.css";
import { Chip, TextField } from "@mui/material";
import { Search, Tune } from "@mui/icons-material";
import SearchFilter from "../components/SearchFilter";
import NoResult from "../components/NoResult";
import { useIsMobile } from "../hooks/useIsMobile";
import { getBlobSrc } from "../utils/getBreedImage";
import { Link } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import Fade from "react-reveal/Fade";

const BreedOverview: React.FC = () => {
  const [searchInput, setSearchInput] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [size, setSize] = useState<string[]>([]);
  const [coop, setCoop] = useState<string[]>([]);
  const [grooming, setGrooming] = useState<string[]>([]);
  const [activity, setActivity] = useState<string[]>([]);
  //const [breedTypes, setBreedTypes] = useState<string[]>([]);

  const [filterOptions, setFilterOptions] = useState<string[]>([]);

  const { data: searchBreeds, isLoading } = useSearchBreedsQuery({
    query: searchInput,
    size: size.length > 0 ? size : undefined,
    coop: coop.length > 0 ? coop : undefined,
    activity: activity.length > 0 ? activity : undefined,
    grooming: grooming.length > 0 ? grooming : undefined,
    //breedType: breedTypes.length > 0 ? breedTypes : undefined,
  });

  useEffect(() => {
    const list: string[] = [];
    //breedTypes.map((item) => list.push(item));
    size.map((_item, index) => {
      if (index === 0) {
        list.push("Størrelse: liten");
      }
      if (index === 1) {
        list.push("Størrelse: medium");
      }
      if (index === 2) {
        list.push("Størrelse: stor");
      }
    });
    activity.map((_item, index) => {
      if (index === 0) {
        list.push("Aktivitet: lav");
      }
      if (index === 1) {
        list.push("Aktivitet: middels");
      }
      if (index === 2) {
        list.push("Aktivitet: høyt");
      }
    });
    coop.map((_item, index) => {
      if (index === 0) {
        list.push("Samarbeid: lite");
      }
      if (index === 1) {
        list.push("Samarbeid: middels");
      }
      if (index === 2) {
        list.push("Samarbeid: mye");
      }
    });
    grooming.map((_item, index) => {
      if (index === 0) {
        list.push("Pelspleie: lite");
      }
      if (index === 1) {
        list.push("Pelspleie: middels");
      }
      if (index === 2) {
        list.push("Pelspleie: mye");
      }
    });
    setFilterOptions(list);
  }, [size, coop, grooming, activity]);

  // const deleteItem = (item: string) => {
  //   const list: string[] = [];
  //   filterOptions.map((filter) =>
  //     filter === item ? undefined : list.push(filter)
  //   );
  //   setFilterOptions(list);
  // };

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    window.analytics.page("Raseoversikt");
  }, []);

  const { isMobile } = useIsMobile(1024);

  const sanitizeBreedName = (breedName: string): string => {
    return breedName.replace(/ /g, "-");
  };

  return (
    <div className={css.BreedOverview}>
      <div className={css.intro}>
        <div className={css.content}>
          <h1>Hunderaser</h1>
          <p>
            Utforsk over 250 hunderaser og finn hunden for deg. Vårt
            rasebibliotek gir deg innsikt i hver rases personlighet, behov og
            helse. Du kan også lagre favorittrasene dine og få varsler om ledige
            valper.{" "}
          </p>
        </div>
      </div>
      <div className={css.searchResult}>
        {!showFilter && (
          <div className={css.searchContainer}>
            <div className={css.horizontalContainer}>
              {isMobile && (
                <Tune
                  onClick={() => setShowFilter(true)}
                  className={css.filterButton}
                />
              )}
              <TextField
                className={css.searchBar}
                fullWidth={false}
                label={
                  <span className={css.inputLabel}>
                    <Search />
                    Søk etter rase
                  </span>
                }
                value={searchInput}
                onChange={(e) => setSearchInput(e.currentTarget.value)}
                style={{ background: "#fff", width: "100%" }}
              />
            </div>
          </div>
        )}
        <div className={css.container}>
          {!isMobile && (
            <SearchFilter
              activityList={(activityLevel) => setActivity(activityLevel)}
              sizeList={(sizes) => setSize(sizes)}
              coopList={(coopList) => setCoop(coopList)}
              groomingList={(groomingList) => setGrooming(groomingList)}
              //breedTypeList={(breedType) => setBreedTypes(breedType)}
            />
          )}
          {isMobile && showFilter ? (
            <SearchFilter
              onClose={() => setShowFilter(false)}
              activityList={(activityLevel) => setActivity(activityLevel)}
              sizeList={(sizes) => setSize(sizes)}
              coopList={(coopList) => setCoop(coopList)}
              groomingList={(groomingList) => setGrooming(groomingList)}
              //breedTypeList={(breedType) => setBreedTypes(breedType)}
            />
          ) : isLoading ? (
            <LoadingScreen />
          ) : searchBreeds?.searchBreeds.length === 0 ? (
            <NoResult />
          ) : (
            <div>
              <Fade>
                <div className={css.chipContainer}>
                  {filterOptions.map((item) => (
                    <Chip
                      className={css.chip}
                      label={item}
                      key={item}
                      color="primary"
                    />
                  ))}
                </div>
                <div className={css.breedGrid}>
                  {searchBreeds?.searchBreeds.map((breed, index) => {
                    return (
                      <Link
                        className={css.item}
                        key={index}
                        to={`/hunderaser/${encodeURIComponent(
                          sanitizeBreedName(breed.name)
                        )}`}
                      >
                        <img
                          src={getBlobSrc(breed.name)}
                          alt={capitalize(breed.name)}
                          onError={(e) => {
                            e.currentTarget.src = "/images/breeds/pond.png";
                          }}
                          className={css.blob}
                        />
                        <h4>{capitalize(breed.name)}</h4>
                      </Link>
                    );
                  })}
                </div>
              </Fade>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BreedOverview;
