import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import css from "../styles/BreedProfile.module.scss";
import {
  useUpdateFavoritesMutation,
  useGetUserQuery,
  useGetUserByEmailQuery,
  User,
  useGetBreedByNameQuery,
} from "../api/graphql.gen";
import {
  Alert,
  Avatar,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Slide,
} from "@mui/material";
import { getBannerSrc, getBlobSrc } from "../utils/getBreedImage";
import {
  translateActivityLevel,
  translateGrooming,
  translateSize,
} from "../utils/translateBreedEnums";
import Button from "../components/Button";
import { useIsMobile } from "../hooks/useIsMobile";
import { Favorite } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { TransitionProps } from "@mui/material/transitions";
import { Helmet } from "react-helmet-async";
import LoadingScreen from "../components/LoadingScreen";
import ClubData from "../model/ClubData";
interface RouteParams {
  breedId: string;
  breedName: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BreedProfile: React.FC = () => {
  const { breedName } = useParams<RouteParams>();
  const formatedName =
    breedName === "Dansk-svensk-gårdshund"
      ? "Dansk-svensk gårdshund"
      : decodeURIComponent(breedName.replace(/-/g, " "));
  const { data: breedData, isLoading } = useGetBreedByNameQuery({
    name: formatedName,
  });
  const { user: authUser, isAuthenticated, loginWithRedirect } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const { data: userData, refetch } = useGetUserQuery({
    id: userInfo?.getUserByEmail.id ?? -1,
  });
  const breed = breedData?.getBreedByName;
  const { mutate: updateFavorite } = useUpdateFavoritesMutation({
    onSuccess: () => refetch(),
  });

  const history = useHistory();

  const aboutDogsRef = useRef<HTMLDivElement>(null);
  const needsRef = useRef<HTMLDivElement>(null);
  const healthRef = useRef<HTMLDivElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);
  const clubRef = useRef<HTMLDivElement>(null);
  const breedersRef = useRef<HTMLDivElement>(null);

  const clubInfo = ClubData.find(
    (club) => club.breed.toLowerCase() === breed?.name.toLowerCase()
  );

  const { isMobile } = useIsMobile();

  useEffect(() => {
    window.analytics.page(`Raseprofil - ${breed?.name}`);
  }, []);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const blobSrc = getBlobSrc(breed?.name ?? "");

  const addFavorite = () => {
    if (!isAuthenticated) {
      setCreateUserModal(true);
    } else {
      if (breed?.name && userData?.getUser.id) {
        const oldFavorites = userData?.getUser.favorites?.breeds ?? [];
        let newFavorites: string[] = [];
        oldFavorites?.includes(breed.name)
          ? (newFavorites = oldFavorites.filter((item) => item !== breed.name))
          : (newFavorites = [...oldFavorites, breed.name]);
        updateFavorite({ breeds: newFavorites });
      }
    }
  };

  const [aboutVisible, setAboutVisible] = useState(false);
  const [needsVisible, setNeedsVisible] = useState(false);
  const [healthVisible, setHealthVisible] = useState(false);
  const [clubVisible, setClubVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [noBanner, setNoBanner] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (
      breed?.name &&
      userData?.getUser.favorites?.breeds?.includes(breed?.name)
    ) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
    if (breed?.name) {
      setNoBanner(false);
    }
  }, [userData, breed]);

  const showAdStatus = (breeder: User) => {
    const allAds = breeder.ads;
    let adString = "Har ";
    if (allAds && allAds.filter((ad) => ad.adType !== "DELETED").length > 0) {
      if (allAds.filter((ad) => ad.adType === "AVAILABLE").length > 0) {
        adString = adString + "tilgjengelig";
      }
      if (allAds.filter((ad) => ad.adType === "PLANNED").length > 0) {
        adString =
          adString +
          (adString.includes("tilgjengelig") ? ", planlagt" : "planlagt");
      }
      if (allAds.filter((ad) => ad.adType === "COMING").length > 0) {
        adString =
          adString +
          (adString.includes("tilgjengelig") ||
          adString.includes(", planlagt") ||
          adString.includes("planlagt")
            ? ", kommende "
            : "kommende");
      }
      return adString + " kull";
    } else {
      return "Ingen kull";
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {breed?.name && (
        <Helmet>
          <title>{breed?.name}</title>
          <meta property="og:title" content={breed?.name}></meta>
          <meta property="og:description" content={breed?.needs ?? ""}></meta>
          <meta property="og:image" content={getBannerSrc(breed.name)}></meta>
          <meta property="og:type" content="article"></meta>
        </Helmet>
      )}
      {isMobile ? (
        <div className={css.BreedProfile}>
          <div className={css.bannerContainer}>
            <div className={css.middleBanner}>
              {!noBanner && (
                <div>
                  <img
                    src={getBannerSrc(breed?.name ?? "")}
                    onError={() => {
                      setNoBanner(true);
                    }}
                  />
                  {breed?.name === "Shiba" && (
                    <div className={css.imageCredit}>Bente Vedal</div>
                  )}
                  {breed?.name === "Chihuahua langhåret" && (
                    <div className={css.imageCredit}>Camilla Suzann Nilsen</div>
                  )}
                  {breed?.name === "Norsk buhund" && (
                    <div className={css.imageCredit}>
                      Ina Margrethe Gabrielsen
                    </div>
                  )}
                  {breed?.name === "Staffordshire bull terrier" && (
                    <div className={css.imageCredit}>Jeaninie Neegaard</div>
                  )}
                  {breed?.name === "Shetland sheepdog" && (
                    <div className={css.imageCredit}>
                      Silje Marie Foss Gravingen
                    </div>
                  )}
                  {breed?.name === "Tibetansk mastiff" && (
                    <div className={css.imageCredit}>Trine Møller</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={css.kennelContainer}>
            <div className={css.kennelHorizontalContainer}>
              <Avatar
                src={blobSrc}
                alt="Profilbilde"
                sx={{ background: "#fff" }}
                className={css.profilePic}
              />
              <div className={css.kennelInfo}>
                <div className={css.kennelName}>{breed?.name}</div>
                <div className={css.grouping}>
                  {breed?.breedType?.toString().replaceAll(",", ", ")}
                </div>
              </div>
            </div>
          </div>
          {breed?.name === "Border collie" ||
          breed?.name === "Siberian husky" ? (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ width: "90vw", marginTop: "2rem" }}
            >
              Denne rasen er ofte blant de som blir omplassert. Tenk deg grundig
              om, slik at du kan ta det beste valget for deg og for hunden.
            </Alert>
          ) : undefined}
          <div className={css.breedInfo}>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/activity.svg" />
              <div className={css.key}>Aktivitetsnivå</div>
              <div>{translateActivityLevel(breed?.activityLevel ?? "")}</div>
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/size.svg" />
              <div className={css.key}>Størrelse</div>
              <div>{translateSize(breed?.size ?? "")}</div>
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/coop.svg" />
              <div className={css.key}>Samarbeid</div>
              <div>{translateActivityLevel(breed?.coop ?? "")}</div>
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/grooming.svg" />
              <div className={css.key}>Pelspleie</div>
              <div>{translateGrooming(breed?.grooming ?? "")}</div>
            </div>
          </div>

          <div className={css.container}>
            <div className={css.bottomContainer}>
              <div className={css.contentContainer}>
                <div className={css.aboutBreeder} ref={aboutDogsRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>Om hunden</h6>
                      <img
                        src={
                          aboutVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setAboutVisible(!aboutVisible)}
                      />
                    </div>
                    <Collapse
                      in={aboutVisible}
                      timeout="auto"
                      className={aboutVisible ? css.collapse : ""}
                    >
                      {breed?.personality ? (
                        <div>
                          <p>{breed?.personality}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={needsRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>Behov</h6>
                      <img
                        src={
                          needsVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setNeedsVisible(!needsVisible)}
                      />
                    </div>
                    <Collapse
                      in={needsVisible}
                      timeout="auto"
                      className={needsVisible ? css.collapse : ""}
                    >
                      {breed?.needs ? (
                        <div>
                          <p>{breed?.needs}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={healthRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>Helse</h6>
                      <img
                        src={
                          healthVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setHealthVisible(!healthVisible)}
                      />
                    </div>
                    <Collapse
                      in={healthVisible}
                      timeout="auto"
                      className={healthVisible ? css.collapse : ""}
                    >
                      {breed?.health ? (
                        <div>
                          <p>{breed?.health}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={historyRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>Historie</h6>
                      <img
                        src={
                          historyVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setHistoryVisible(!historyVisible)}
                      />
                    </div>
                    <Collapse
                      in={historyVisible}
                      timeout="auto"
                      className={historyVisible ? css.collapse : ""}
                    >
                      {breed?.history ? (
                        <div>
                          <p>{breed?.history}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={clubRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>Klubb</h6>
                      <img
                        src={
                          clubVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setClubVisible(!clubVisible)}
                      />
                    </div>
                    <Collapse
                      in={clubVisible}
                      timeout="auto"
                      className={clubVisible ? css.collapse : ""}
                    >
                      {clubInfo ? (
                        <div
                          className={css.clubContainer}
                          onClick={() =>
                            clubInfo.link
                              ? window.open(clubInfo.link)
                              : undefined
                          }
                        >
                          <Avatar
                            src={clubInfo.img ?? "/images/icons/club.svg"}
                            alt="klubb ikon"
                            className={css.avatar}
                          />
                          <div>{clubInfo.club}</div>
                        </div>
                      ) : (
                        <p>Kommer snart</p>
                      )}
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.breeders} ref={breedersRef}>
            <div className={css.content}>
              <h4>Kenneler</h4>
              {breed?.breeder?.length ? (
                <div className={css.breederGrid}>
                  {breed?.breeder?.map((breeder) => (
                    <div className={css.breederCard} key={breeder.id}>
                      <div className={css.banner}>
                        {breeder.kennel?.bannerImage && (
                          <img
                            src={breeder.kennel?.bannerImage ?? undefined}
                            alt="Bannerbilde"
                          />
                        )}
                      </div>
                      <Avatar
                        src={
                          breeder.profilePicture ?? "/images/default-avatar.png"
                        }
                        className={css.avatar}
                      />
                      <div className={css.name}>{breeder?.kennel?.name}</div>
                      <div className={css.adInfo}>
                        {showAdStatus(breeder as User)}
                      </div>
                      <div className={css.location}>{`${
                        breeder.address?.city.trim() ?? "Ukjent"
                      }, Norge`}</div>
                      <Button
                        label="Besøk profil"
                        fontSize={8}
                        onClick={() =>
                          history.push(`/oppdretter/${breeder.id}`)
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p>Ingen oppdrettere enda</p>
              )}
            </div>
          </div>
          <Fab
            aria-label="like"
            color={isFavorite ? "primary" : "secondary"}
            className={css.favorieButton}
            onClick={addFavorite}
          >
            <Favorite color={isFavorite ? "error" : "disabled"} />
          </Fab>
        </div>
      ) : (
        <div className={css.BreedProfile}>
          <div className={css.bannerContainer}>
            <div className={css.leftBanner} />
            {breed?.name ? (
              <div className={css.middleBanner}>
                <img src={getBannerSrc(breed?.name)} />
                {breed?.name === "Shiba" && (
                  <div className={css.imageCredit}>Bente Vedal</div>
                )}
                {breed?.name === "Chihuahua langhåret" && (
                  <div className={css.imageCredit}>Camilla Suzann Nilsen</div>
                )}
                {breed?.name === "Norsk buhund" && (
                  <div className={css.imageCredit}>
                    Ina Margrethe Gabrielsen
                  </div>
                )}
                {breed?.name === "Staffordshire bull terrier" && (
                  <div className={css.imageCredit}>Jeaninie Neegaard</div>
                )}
                {breed?.name === "Shetland sheepdog" && (
                  <div className={css.imageCredit}>
                    Silje Marie Foss Gravingen
                  </div>
                )}
                {breed?.name === "Tibetansk mastiff" && (
                  <div className={css.imageCredit}>Trine Møller</div>
                )}
              </div>
            ) : (
              <div className={css.noBanner} />
            )}
            <div className={css.rightBanner} />
          </div>
          <div className={css.kennelContainer}>
            <div className={css.kennelHorizontalContainer}>
              <Avatar
                src={getBlobSrc(breed?.name ?? "")}
                alt="Profilbilde"
                sx={{ background: "#fff" }}
                className={css.profilePic}
              />
              <div className={css.kennelInfo}>
                <div className={css.kennelName}>{breed?.name}</div>
                <div className={css.grouping}>
                  {breed?.breedType?.toString().replaceAll(",", ", ")}
                </div>
              </div>
            </div>
          </div>
          {breed?.name === "Border collie" ||
          breed?.name === "Siberian husky" ? (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ width: "50vw", marginTop: "2rem" }}
            >
              Dette er en rase som ofte blir omplassert. Tenk deg grundig om
              slik at du kan ta det beste valget for deg og for hunden. Sammen
              kan vi bidra til å redusere antall omplasseringer av hunder og gi
              dem et trygt og varig hjem.
            </Alert>
          ) : undefined}
          <div className={css.breedInfo}>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/activity.svg" />
              <div className={css.key}>Aktivitetsnivå</div>
              <div>{translateActivityLevel(breed?.activityLevel ?? "")}</div>
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/size.svg" />
              <div className={css.key}>Størrelse</div>
              <div>{translateSize(breed?.size ?? "")}</div>
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/coop.svg" />
              <div className={css.key}>Samarbeid</div>
              <div>{translateActivityLevel(breed?.coop ?? "")}</div>
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/grooming.svg" />
              <div className={css.key}>Pelspleie</div>
              <div>{translateGrooming(breed?.grooming ?? "")}</div>
            </div>
          </div>

          <div className={css.container}>
            <div className={css.sidePanel}>
              <div className={css.fixedWrapper}>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(aboutDogsRef)}
                >
                  Om
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(needsRef)}
                >
                  Behov
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(healthRef)}
                >
                  Helse
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(historyRef)}
                >
                  Historie
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(clubRef)}
                >
                  Klubber
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(breedersRef)}
                >
                  Oppdrettere
                </div>
              </div>
            </div>

            <div className={css.bottomContainer}>
              <div className={css.contentContainer}>
                <div className={css.aboutBreeder} ref={aboutDogsRef}>
                  <div
                    className={css.textContainer}
                    style={{ paddingTop: "1rem" }}
                  >
                    {breed?.personality ? (
                      <div>
                        <p>{breed?.personality}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={needsRef}>
                  <div className={css.textContainer}>
                    <h6>Behov</h6>
                    {breed?.needs ? (
                      <div>
                        <p>{breed?.needs}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={healthRef}>
                  <div className={css.textContainer}>
                    <h6>Helse</h6>
                    {breed?.health ? (
                      <div>
                        <p>{breed?.health}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={historyRef}>
                  <div className={css.textContainer}>
                    <h6>Historie</h6>
                    {breed?.history ? (
                      <div>
                        <p>{breed?.history}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={clubRef}>
                  <div className={css.textContainer}>
                    <h6>Klubb</h6>
                    {clubInfo ? (
                      <div
                        className={css.clubContainer}
                        onClick={() =>
                          clubInfo.link ? window.open(clubInfo.link) : undefined
                        }
                      >
                        <Avatar
                          src={clubInfo.img ?? "/images/icons/club.svg"}
                          alt="klubb ikon"
                          className={css.avatar}
                        />
                        <div>{clubInfo.club}</div>
                      </div>
                    ) : (
                      <p>Kommer snart</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.breeders} ref={breedersRef}>
            <div className={css.content}>
              <h4>Kenneler</h4>
              {breed?.breeder?.length ? (
                <div className={css.breederGrid}>
                  {breed?.breeder?.map((breeder) => (
                    <div className={css.breederCard} key={breeder.id}>
                      <div className={css.banner}>
                        {breeder.kennel?.bannerImage && (
                          <img
                            src={breeder.kennel.bannerImage}
                            alt="Banner bilde oppdretter"
                          />
                        )}
                      </div>
                      <Avatar
                        src={
                          breeder.profilePicture ?? "/images/default-avatar.png"
                        }
                        className={css.avatar}
                      />
                      <div className={css.name}>{breeder?.kennel?.name}</div>
                      <div className={css.adInfo}>
                        {showAdStatus(breeder as User)}
                      </div>
                      <div className={css.location}>{`${
                        breeder.address?.city.trim() ?? "Ukjent"
                      }, Norge`}</div>
                      <Button
                        label="Besøk profil"
                        fontSize={14}
                        onClick={() =>
                          history.push(`/oppdretter/${breeder.id}`)
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p>Ingen oppdrettere enda</p>
              )}
            </div>
          </div>
          <Fab
            aria-label="like"
            color={isFavorite ? "primary" : "secondary"}
            variant="extended"
            className={css.favorieButton}
            size="large"
            onClick={addFavorite}
          >
            <Favorite
              sx={{ mr: 1 }}
              color={isFavorite ? "error" : "disabled"}
            />
            {isFavorite ? "Lagret" : "Lagre"}
          </Fab>
        </div>
      )}
      <Dialog
        open={createUserModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={addFavorite}
        sx={{ borderRadius: 15 }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Opprett bruker</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Lagre denne rasen og bli varslet når nye oppdrettere registerer seg
            og nye kullannonser legges ut.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            label="Opprett bruker"
            skin="dark"
            onClick={() => {
              window.analytics.track("Registrer - Raseprofil/favoritt");
              loginWithRedirect();
            }}
            style={{ marginBottom: 5, width: "90%" }}
          />

          <Button
            label="Avbryt"
            skin="transparent"
            onClick={() => setCreateUserModal(false)}
            style={{ marginBottom: 5, width: "90%" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BreedProfile;
