import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGetUserByEmailQuery, useGetUserQuery } from "../../api/graphql.gen";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/Favorites.module.scss";
import { getBlobSrc } from "../../utils/getBreedImage";
import Footer from "./Footer";

const Favorites: React.FC = () => {
  const { isMobile } = useIsMobile();
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? -1;
  const { data: userData } = useGetUserQuery({ id: userId });
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.analytics.page("Favoritter");
  }, []);

  return (
    <div className={css.Favorites}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Kontoinnstillinger" next="  >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/paw-circle.svg"
          percentage={60}
          title="Favoritter"
          info="Her kan du se alle rasene du har lagret"
        />
        <div className={css.breedGrid}>
          {userData?.getUser.favorites?.breeds?.map((breed: string) => (
            <div
              key={breed}
              className={css.breedContainer}
              onClick={() =>
                history.push(
                  `/hunderaser/${encodeURIComponent(breed.replace(/ /g, "-"))}`
                )
              }
            >
              <img src={getBlobSrc(breed)} alt={breed} />
              <h4>{breed}</h4>
            </div>
          ))}
        </div>
        {!userData?.getUser.favorites?.breeds?.length && (
          <div>Du har ingen favoritter enda</div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Favorites;
