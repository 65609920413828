import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import Button from "../components/Button";
import { useAuth0 } from "@auth0/auth0-react";

export interface Props {
  onClose: () => void;
  open: boolean;
  description: string;
  eventName: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateUserDialog: React.FC<Props> = ({
  open,
  onClose,
  description,
  eventName,
}: Props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      sx={{ borderRadius: 15 }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Opprett bruker</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          label="Opprett bruker"
          skin="dark"
          onClick={() => {
            window.analytics.track(eventName);
            loginWithRedirect();
          }}
          style={{ marginBottom: 5, width: "90%" }}
        />

        <Button
          label="Avbryt"
          skin="transparent"
          onClick={onClose}
          style={{ marginBottom: 5, width: "90%" }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserDialog;
