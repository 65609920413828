import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import React from "react";
import css from "../styles/Footer.module.scss";

const Footer: React.FC = function () {
  return (
    <div className={css.Footer}>
      <div
        className={css.some}
        onClick={() => window.open("https://www.instagram.com/pond.norge/")}
      >
        <Instagram fontSize="small" /> &nbsp; Instagram
      </div>
      <div
        className={css.some}
        onClick={() =>
          window.open("https://www.facebook.com/Pond-108541064932673")
        }
      >
        <Facebook fontSize="small" /> &nbsp; Facebook
      </div>
      <div
        className={css.some}
        onClick={() => window.open("https://www.linkedin.com/company/pondno")}
      >
        <LinkedIn fontSize="small" /> &nbsp; Linkedin
      </div>

      <div className={css.backToTop} onClick={() => window.scroll(0, 0)}>
        Tilbake til toppen
      </div>
    </div>
  );
};

export default Footer;
