/* eslint-disable */
import React from "react";
import { render } from "react-dom";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { createClient } from "@sanity/client";
import { HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";
import "@navikt/ds-css";

const queryClient = new QueryClient();
const domain = "dev-pond.eu.auth0.com";
const clientId = "mYjZ5hAGNpq2rU1QFhoBZe3KoRpOou2w";
const redirectUri = "https://pond.no/login";
const audience = "https://dev-pond.eu.auth0.com/api/v2/";

// const domain = "pond-norge.eu.auth0.com";
// const clientId = "TXHXNOOR9Kd9YaJ0LpZUnqt2sB4BpWS7";
// const redirectUri = "https://test.pond.no/login";

export const client = createClient({
  projectId: "evk9xyav",
  dataset: "production",
  useCdn: true, // set to `true` to fetch from edge cache
  apiVersion: "2021-10-21", // use current date (YYYY-MM-DD) to target the latest API version
  token:
    "skThQbJ0LIxZSDLIGPC8ormtcJ8Cxnb4R8hiasGYuZJ87mR7tgv1z8Zba83xSeH0QbM1SYWRCS625eBQIi2Zl9ldTkv91goYGFt0P6VZ8YzgNcrlzf948aNLfh6pt52kbpLIphuIzNQ9EmEF3U3zJoQuFNAmdKfRSSM41XyXJghtpwiezEXR", // Only if you want to update content with the client
});

render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            redirect_uri: redirectUri,
            audience,
            scope: "openid profile email",
          }}
        >
          <App />
          <CookieConsent
            location="bottom"
            buttonText="Jeg godtar"
            cookieName="cookieApproval"
            style={{
              background: "#e2f6d1",
              color: "#065c53",
              borderTop: "1px solid #065C53",
              zIndex: 99999999,
              alignItems: "center",
            }}
            buttonStyle={{
              color: "#fff",
              background: "#065c53",
              fontSize: "12px",
              paddingLeft: 25,
              paddingRight: 25,
              borderRadius: 100,
            }}
            expires={150}
          >
            Denne nettsiden bruker informasjonskapsler (cookies) for at den skal
            fungere optimalt. Ved å fortsette å bruke siden aksepterer du at vi
            lagrer cookies på din enhet.
          </CookieConsent>
        </Auth0Provider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
