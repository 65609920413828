import { Alert, Avatar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  useGetUserByEmailQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../api/graphql.gen";
import Button from "../../components/Button";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/AccountSettings.module.scss";
import Input from "../../components/NewInput";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import Footer from "./Footer";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import uploadFileToBlob from "../../utils/azure-storage-blob";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "../../components/LoadingScreen";

const AccountSettings: React.FC = () => {
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? -1;
  const { data: userData } = useGetUserQuery({ id: userId });
  const user = userData?.getUser;
  const { isMobile } = useIsMobile();

  const [confirmGhost, setConfirmGhost] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPasswordChanges, setShowPasswordChanges] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showEditError, setShowEditError] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    "/images/default-avatar.png"
  );

  const { mutate: updateProfile } = useUpdateUserMutation({
    onSuccess: () => setSavedChanges(true),
  });

  useEffect(() => {
    window.analytics.page("Kontoinnstillinger");
  }, []);

  const history = useHistory();

  const saveChanges = () => {
    if (!firstName || !lastName || !email || !phoneNumber) {
      setShowEditError(true);
    } else {
      userId !== -1 &&
        updateProfile({
          firstName,
          lastName,
          email,
          phoneNumber,
          profilePicture:
            profilePicture === "/images/default-avatar.png"
              ? null
              : profilePicture,
          street: address,
          zip,
          city,
          breeder: user?.breeder ?? false,
          about: user?.about ?? "",
          password: "",
        });
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const changePassword = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        clientId: "mYjZ5hAGNpq2rU1QFhoBZe3KoRpOou2w",
        email,
        connection: "Username-Password-Authentication",
      }),
    };
    fetch(
      "https://dev-pond.eu.auth0.com/dbconnections/change_password",
      requestOptions
    )
      .then((response) => response.json())
      .then(() => setShowPasswordChanges(true))
      .catch(() => setPasswordError(true));
  };

  useEffect(() => {
    if (user) {
      setLastName(user.lastName);
      setFirstName(user.firstName);
      setEmail(user.email);
      setZip(user.address?.zip ?? "");
      setAddress(user.address?.street ?? "");
      setCity(user.address?.city ?? "");
      setPhoneNumber(user.phoneNumber ?? "");
      setProfilePicture(user.profilePicture ?? "/images/default-avatar.png");
    }
  }, [user]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<File | null>(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (event: any) => {
    // capture file into state
    const file = event.target.files[0];
    const uuidFilename = new File([file], v4() + file.name);
    setFileSelected(uuidFilename);
  };

  useEffect(() => {
    if (fileSelected) {
      onFileUpload();
    }
  }, [fileSelected]);

  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    await uploadFileToBlob(fileSelected);
    setProfilePicture(
      `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
    );

    // reset state/form
    setFileSelected(null);
    setUploading(false);
  };

  return (
    <div className={css.AccountSettings}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND/ Dashboard"
          next="Kontoinnstillinger >"
        />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          percentage={60}
          image="/images/icons/dashboard/settings-circle.svg"
          title="Kontoinnstillinger"
          info="Profilen din på POND.no er satt sammen av informasjon hentet fra kontoen din. Jo mer informasjon du legger til her, jo mer kan vi tilpasse POND for deg."
        />
        <div className={css.mainContainer}>
          <div className={css.leftContainer}>
            {uploading ? (
              <LoadingScreen />
            ) : (
              <Avatar
                src={profilePicture}
                alt="Profilbilde"
                className={css.avatar}
              />
            )}
            <Button
              label="Bytt profilbilde"
              onClick={() => {
                hiddenFileInput.current && hiddenFileInput.current.click();
              }}
            />
            <input
              type="file"
              hidden
              ref={hiddenFileInput}
              onChange={onFileChange}
            />
            <Button
              label="Slett bilde"
              skin="noColor"
              onClick={() => setProfilePicture("/images/default-avatar.png")}
            />
          </div>
          <div className={css.infoContainer}>
            <div className={css.inputContainer}>
              <Input
                id="firstName"
                placeholder="Fornavn"
                value={firstName}
                required
                onChange={(value) => setFirstName(value)}
                type="firstName"
                className={css.input}
              />
            </div>
            <div className={css.inputContainer}>
              <Input
                id="lastName"
                placeholder="Etternavn"
                required
                value={lastName}
                onChange={(value) => setLastName(value)}
                type="lastName"
                className={css.input}
              />
              {/*<div className={css.addPerson}>
                <div>+ Legg til person</div>
                <Tooltip title="Under utvikling" placement="right">
                  <HelpOutlineRounded className={css.helpButton} />
                </Tooltip>
            </div>*/}
            </div>

            <div className={css.inputContainer}>
              <Input
                id="email"
                placeholder="E-post"
                required
                disabled
                value={email}
                onChange={(value) => setEmail(value)}
                type="email"
                className={css.input}
              />
            </div>
            <div className={css.inputContainer}>
              <Input
                id="phonenumber"
                placeholder="Telefonnummer"
                required
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                type="phonenumber"
                className={css.input}
              />
            </div>
            <div className={css.inputContainer}>
              <Input
                id="address"
                placeholder="Adresse"
                value={address}
                onChange={(value) => setAddress(value)}
                type="street"
                className={css.input}
              />
            </div>
            <div className={css.dualInputContainer}>
              <div className={css.inputContainer}>
                <Input
                  id="city"
                  placeholder="Sted"
                  value={city}
                  onChange={(value) => setCity(value)}
                  type="city"
                  className={css.input}
                />
              </div>
              <div className={css.inputContainer}>
                <Input
                  id="zip"
                  placeholder="Postnummer"
                  value={zip}
                  onChange={(value) => setZip(value)}
                  type="zip"
                  className={css.input}
                />
              </div>
            </div>
            <div className={css.inputContainer}>
              <Button
                label="Endre passord"
                skin="transparent"
                onClick={changePassword}
              />
              {showPasswordChanges && (
                <Alert
                  style={{ marginTop: 10 }}
                  severity="success"
                  onClose={() => setShowPasswordChanges(true)}
                >
                  Følg instruksjonene i e-posten vi har sendt deg for å endre
                  passord
                </Alert>
              )}
              {passwordError && (
                <Alert
                  style={{ marginTop: 10 }}
                  severity="error"
                  onClose={() => setPasswordError(true)}
                >
                  Noe gikk galt! Kan ikke endre passord om du logget inn med
                  Google eller Facebook.
                </Alert>
              )}
            </div>
            {!!user?.breeder && (
              <div className={css.section}>
                <Button
                  label="Spøkelsesmodus"
                  onClick={() => setConfirmGhost(true)}
                />
                <p>
                  Når du har valgt spøkelsesmodus vil ingen kunne kontakte deg.
                  Profilen din er fortsatt synlig men ingen vil kunne sende deg
                  meldinger, eller forespørre om valp.
                </p>
              </div>
            )}
            <div className={css.section}>
              <Button
                label="Slett konto"
                skin="error"
                onClick={() => setConfirmDelete(true)}
              />
              <p>
                Når du har valgt å slette kontoen din kommer alt du har lagt inn
                på POND til å gå tapt for alltid, du vil ikke lengre kunne logge
                inn på tjenesten og du kan ikke angre dette i ettertid.
              </p>
            </div>
          </div>
        </div>
        <div>
          <Button
            label="Tilbake"
            skin="lightGreen"
            onClick={() => history.goBack()}
          />
          <Button label="Fullfør" skin="dark" onClick={saveChanges} />
        </div>
        {savedChanges && (
          <Alert
            severity="success"
            onClose={() => setSavedChanges(false)}
            className={css.success}
          >
            Dine endringer har blitt lagret
          </Alert>
        )}
        {showError && (
          <Alert
            severity="error"
            onClose={() => setShowError(false)}
            className={css.success}
          >
            Noe gikk galt. Send mail til kontakt@pond.no så sletter vi kontoen
            for deg
          </Alert>
        )}
        {showEditError && (
          <Alert
            severity="error"
            onClose={() => setShowEditError(false)}
            className={css.success}
          >
            Obligatoriske felt kan ikke være tomme. Vennligst fyll ut alle
            obligatoriske felter.
          </Alert>
        )}
        <Footer />
      </div>
      <ConfirmationAlert
        title="Sletter konto"
        content="Er du sikker på at du vil slette kontoen din?"
        open={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        handleCancel={() => setConfirmDelete(false)}
        handleConfirm={() => {
          setShowError(true);
          setConfirmDelete(false);
        }}
      />
      <ConfirmationAlert
        title="Spøkelsesmodus"
        content="Er du sikker på at du vil slå på spøkelsesmodus?"
        open={confirmGhost}
        handleClose={() => setConfirmGhost(false)}
        handleCancel={() => setConfirmGhost(false)}
      />
    </div>
  );
};

export default AccountSettings;
