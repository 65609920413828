import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useGetUserByEmailQuery } from "../api/graphql.gen";
import css from "../styles/Login.module.scss";
import LoadingScreen from "../components/LoadingScreen";

const Login: React.FC = () => {
  useEffect(() => {
    window.analytics.page("Login");
  }, []);

  const history = useHistory();

  const { user, isAuthenticated, isLoading: authLoading } = useAuth0();

  const { data: userData, isLoading: loadingUser } = useGetUserByEmailQuery({
    email: user?.email ?? "",
  });

  useEffect(() => {
    if (isAuthenticated && !loadingUser) {
      if (userData?.getUserByEmail.id) {
        history.replace("/");
      } else {
        history.replace("/registrer");
      }
    }
  }, [authLoading, loadingUser]);

  return loadingUser || authLoading ? (
    <div className={css.Login}>
      <LoadingScreen />
    </div>
  ) : (
    <div className={css.Login}></div>
  );
};

export default Login;
