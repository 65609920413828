import { useAuth0 } from "@auth0/auth0-react";
import {
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Alert,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import {
  useCreateAdMutation,
  useGetAdPuppiesQuery,
  useGetMyAdsQuery,
  useGetMyDogsQuery,
  useGetUserByEmailQuery,
} from "../../api/graphql.gen";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import Input from "../../components/NewInput";
import { useIsMobile } from "../../hooks/useIsMobile";
import { DogBreeds } from "../../model/DogBreeds";
import css from "../../styles/NewDog.module.scss";
import uploadFileToBlob from "../../utils/azure-storage-blob";

interface RouteParams {
  type: string;
  id: string;
}

enum puppyProperty {
  name = "name",
  registration = "registration",
  color = "color",
  gender = "gender",
  image = "image",
  availability = "availability",
}

const NewPuppy: React.FC = () => {
  const { type } = useParams<RouteParams>();
  const { isMobile } = useIsMobile();
  const [image, setImage] = useState("/images/placeholder-dog.png");
  const [adName, setAdName] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [price, setPrice] = useState("");
  const [readyForDelivery, setReadyForDelivery] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [breed, setBreed] = useState("");
  const [father, setFather] = useState("");
  const [momId, setMomId] = useState("");
  const [dadId, setDadId] = useState("");
  const [mother, setMother] = useState("");
  const [other, setOther] = useState(false);
  const [otherText, setOtherText] = useState("");
  const [numberOfPuppies, setNumberOfPuppies] = useState("1");
  const [names, setNames] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [genders, setGenders] = useState<string[]>([]);
  const [registrationNumbers, setRegistrationNumbers] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [idTag, setIdTag] = useState(false);
  const [healthCheck, setHealthCheck] = useState(false);
  const [healthCertificate] = useState(false);
  const [advising, setAdvising] = useState(false);
  const [expLitter, setExpLitter] = useState("");
  const [puppyPack, setPuppyPack] = useState(false);
  const [pedigree, setPedigree] = useState(false);
  const [vaccinated, setVaccinated] = useState(false);
  const [wormTreatment, setWormTreatment] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState(0);

  const { mutate: createAd } = useCreateAdMutation({
    onSuccess: () => {
      history.replace("/min-pond/mine-valper");
    },
  });

  useEffect(() => {
    window.analytics.page("Ny valpeannonse");
  }, []);

  const { id } = useParams<RouteParams>();
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? -1;
  const { data: myDogs } = useGetMyDogsQuery();
  const { data: myAds } = useGetMyAdsQuery();
  const { data: adPuppies } = useGetAdPuppiesQuery({ adId: parseInt(id) });
  const ad = myAds?.getMyAds.find((item) => item.id === parseInt(id));

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isNaN(parseInt(numberOfPuppies))) {
      if (id === undefined) {
        setNames([...Array(parseInt(numberOfPuppies))].map((_item) => ""));
        setColors([...Array(parseInt(numberOfPuppies))].map((_item) => ""));
        setGenders([...Array(parseInt(numberOfPuppies))].map((_item) => ""));
        setStatuses([...Array(parseInt(numberOfPuppies))].map((_item) => ""));
        setImages(
          [...Array(parseInt(numberOfPuppies))].map(
            (_item) => "/images/placeholder-dog.png"
          )
        );
        setRegistrationNumbers(
          [...Array(parseInt(numberOfPuppies))].map((_item) => "")
        );
      }
    }
  }, [numberOfPuppies]);

  useEffect(() => {
    if (id) {
      const prevFather = ad?.parents?.find((item) => item.gender === "male");
      const prevMother = ad?.parents?.find((item) => item.gender === "female");
      if (ad) {
        setImage(ad.adImage ?? "/images/placeholder-dog.png");
        setWormTreatment(!!ad.wormTreatment);
        setVaccinated(!!ad.vaccinated);
        setPedigree(!!ad.pedigree);
        setPuppyPack(!!ad.puppyPack);
        setExpLitter(ad.expLitter);
        setAdvising(!!ad.advising);
        setBreed(ad.breed);
        setBirthDate(format(new Date(ad.birthDate), "yyyy-MM-dd"));
        setIdTag(ad.idTag);
        setOther(ad.other);
        setOtherText(ad.otherText);
        setFather(prevFather?.name ?? "");
        setMother(prevMother?.name ?? "");
        setAdName(ad.title);
        setNumberOfPuppies(
          ad?.numberOfPuppies?.toString() ??
            adPuppies?.getAdPuppies.length.toString() ??
            "1"
        );
        setPrice(ad?.price?.toString() ?? "");
        setReadyForDelivery(
          format(new Date(ad.readyForDelivery), "yyyy-MM-dd")
        );
      }
    }
  }, [myAds, id]);

  useEffect(() => {
    const puppies = adPuppies?.getAdPuppies;
    const puppyNames: string[] = [];
    const puppyReg: string[] = [];
    const puppyGenders: string[] = [];
    const puppyStatuses: string[] = [];
    const puppyColors: string[] = [];
    const puppyImages: string[] = [];
    if (puppies) {
      puppies.map((puppy) => {
        puppyNames.push(puppy.name);
        puppyReg.push(puppy.registrationNumber);
        puppyGenders.push(puppy.gender);
        puppyStatuses.push(puppy.availability);
        puppyImages.push(puppy.image);
        puppyColors.push(puppy.color);
      });
      setNames(puppyNames);
      setRegistrationNumbers(puppyReg);
      setGenders(puppyGenders);
      setStatuses(puppyStatuses);
      setImages(puppyImages);
      setColors(puppyColors);
    }
  }, [adPuppies, id, numberOfPuppies]);

  const uploadAd = () => {
    if (adName === "") {
      setErrorMessage("Navn på kull kan ikke være tomt");
    } else if (breed === "") {
      setErrorMessage("Du må velge rase");
    } else if (birthDate === "") {
      type === "fodt"
        ? setErrorMessage("Su må fylle inn når kullet er født")
        : setErrorMessage("Du må fylle inn når kullet er forventet");
    } else if (mother === "" && type !== "planlagt") {
      setErrorMessage("Du må velge mor for kullet");
    } else if (father === "" && type !== "planlagt") {
      setErrorMessage("Du må velge far for kullet");
    } else if (price === "" && type === "fodt") {
      setErrorMessage("Du må sette en pris");
    } else if (readyForDelivery === "" && type === "fodt") {
      setErrorMessage("Du må velge dato for levering");
    } else {
      const fatherId = myDogs?.getMyDogs.find(
        (item) => item.name === father
      )?.id;
      const motherId = myDogs?.getMyDogs.find(
        (item) => item.name === mother
      )?.id;
      userId !== -1 &&
        createAd({
          adId: parseInt(id) ?? undefined,
          title: adName,
          fatherId,
          motherId,
          breed,
          femaleImages: [],
          maleImages: [],
          males: [],
          females: [],
          birthDate,
          readyForDelivery,
          breedCombination: "",
          expBuyer: "",
          expLitter,
          NKKGuidelines: true,
          offerBuyer: "",
          other,
          otherText,
          insurance: false,
          idTag,
          price: parseFloat(price),
          healthCheck,
          healthCertificate,
          advising,
          numberOfPuppies: type === "fodt" ? parseInt(numberOfPuppies) : 0,
          pedigree,
          vaccinated,
          puppyNames: names,
          puppyColors: colors,
          puppyGenders: genders,
          puppyReg: registrationNumbers,
          puppyImages: images,
          puppyStatus: statuses,
          wormTreatment,
          puppyPack,
          adImage: image === "/images/placeholder-dog.png" ? null : image,
          adType:
            type === "fodt"
              ? "AVAILABLE"
              : type === "planlagt"
              ? "PLANNED"
              : "COMING",
          fatherNKK: dadId,
          motherNKK: momId,
        });
    }
  };

  const updatePuppyData = (
    property: puppyProperty,
    index: number,
    value: string
  ) => {
    const arr: string[] = [];
    switch (property) {
      case puppyProperty.color:
        colors.map((color, i) => {
          if (i === index) {
            arr.push(value);
          } else {
            arr.push(color);
          }
        });
        setColors(arr);
        break;
      case puppyProperty.gender:
        genders.map((gender, i) => {
          if (i === index) {
            arr.push(value);
          } else {
            arr.push(gender);
          }
        });
        setGenders(arr);
        break;
      case puppyProperty.registration:
        registrationNumbers.map((regNumb, i) => {
          if (i === index) {
            arr.push(value);
          } else {
            arr.push(regNumb);
          }
        });
        setRegistrationNumbers(arr);
        break;
      case puppyProperty.name:
        names.map((name, i) => {
          if (i === index) {
            arr.push(value);
          } else {
            arr.push(name);
          }
        });
        setNames(arr);
        break;
      case puppyProperty.availability:
        statuses.map((status, i) => {
          if (i === index) {
            arr.push(value);
          } else {
            arr.push(status);
          }
        });
        setStatuses(arr);
        break;
      case puppyProperty.image:
        images.map((item, i) => {
          if (i === index - 1) {
            arr.push(value);
          } else {
            arr.push(item);
          }
        });
        setImages(arr);
        break;
    }
  };

  const allBreeds = DogBreeds.sort();

  const history = useHistory();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<File | null>(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (event: any) => {
    // capture file into state
    const file = event.target.files[0];
    const uuidFilename = new File([file], v4() + file.name);
    setFileSelected(uuidFilename);
  };

  useEffect(() => {
    if (fileSelected) {
      onFileUpload(selectedImage);
    }
  }, [fileSelected]);

  const onFileUpload = async (index: number) => {
    setUploading(true);

    await uploadFileToBlob(fileSelected);
    if (index === 0) {
      setImage(
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    } else {
      updatePuppyData(
        puppyProperty.image,
        index,
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    }

    setFileSelected(null);
    setUploading(false);
  };

  return (
    <div className={css.NewDog}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <DashboardBanner
        image="/images/icons/dashboard/dog-face-circle.svg"
        percentage={60}
        title="Mine valper"
        info="Her kan du legge til valpekull. Du kan legge til fødte, kommende og planlagte, og administrere tidligere kull."
      />
      <div className={css.mainContainer}>
        <div className={css.leftContainer}>
          <img
            src={image ?? "/images/placeholder-dog.png"}
            alt="Annonsebilde"
          />
          <Button
            label="Last opp bilde"
            disabled={uploading}
            onClick={() => {
              setSelectedImage(0);
              hiddenFileInput.current && hiddenFileInput.current.click();
            }}
          />
          <input
            type="file"
            hidden
            ref={hiddenFileInput}
            onChange={onFileChange}
          />
          <Button
            label="Slett bilde"
            skin="noColor"
            onClick={() => setImage("/images/placeholder-dog.png")}
          />
        </div>
        <div className={css.rightContainer}>
          {type === "fodt" && <h2>Tilgjengelig valpekull</h2>}
          {type === "planlagt" && <h2>Planlagt valpekull</h2>}
          {type === "kommende" && <h2>Kommende valpekull</h2>}
          {type !== "planlagt" && (
            <div className={css.dualContainer}>
              <FormControl>
                <InputLabel>Mor</InputLabel>
                <Select
                  name={`mother`}
                  id={`mother`}
                  onChange={(e) => setMother(e.target.value as string)}
                  value={mother}
                  className={css.dualInput}
                >
                  {myDogs?.getMyDogs
                    .filter((dog) => dog.gender === "female")
                    .map((item) => (
                      <MenuItem
                        sx={{ zIndex: 100000 }}
                        value={item.name}
                        key={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Far</InputLabel>
                <Select
                  name={`father`}
                  id={`father`}
                  onChange={(e) => setFather(e.target.value as string)}
                  className={css.dualInput}
                  label="Far"
                  value={father}
                >
                  {myDogs?.getMyDogs
                    .filter((dog) => dog.gender == "male")
                    .map((item) => (
                      <MenuItem
                        sx={{ zIndex: 100000 }}
                        value={item.name}
                        key={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          {type === "fodt" && (
            <Button
              label="Gå til avlshunder"
              onClick={() => history.push("/min-pond/avlshunder")}
            />
          )}
          <div className={css.singleContainer}>
            <Input
              id="adName"
              type="text"
              onChange={(value) => setAdName(value)}
              value={adName}
              placeholder="Navn på kull"
              className={css.singleInput}
            />
          </div>
          {type === "fodt" && (
            <div className={css.dualContainer}>
              <Input
                id="numberOfPuppies"
                type="number"
                onChange={(value) => setNumberOfPuppies(value)}
                value={numberOfPuppies}
                placeholder="Antall valper"
                className={css.dualInput}
              />
              <Input
                id="price"
                type="text"
                onChange={(value) => setPrice(value)}
                value={price}
                placeholder="Pris"
                className={css.dualInput}
              />
            </div>
          )}
          <div className={css.dualContainer}>
            {type === "fodt" ? (
              <div className={css.dateContainer}>
                {"Født"}
                <Input
                  id="born"
                  type="date"
                  onChange={(value) => setBirthDate(value)}
                  value={birthDate}
                  placeholder={""}
                  className={css.dualInput}
                />
              </div>
            ) : (
              <div className={css.dateContainer}>
                {"Forventet"}
                <Input
                  id="born"
                  type="month"
                  onChange={(value) => setBirthDate(value)}
                  value={birthDate}
                  placeholder={""}
                  className={css.dualInput}
                />
              </div>
            )}
            {type === "fodt" && (
              <div className={css.dateContainer}>
                {"Dato for levering"}
                <Input
                  id="readyForDelivery"
                  type="date"
                  onChange={(value) => setReadyForDelivery(value)}
                  value={readyForDelivery}
                  placeholder=""
                  className={css.dualInput}
                />
              </div>
            )}
          </div>
          <div className={css.dualContainer}>
            <FormControl>
              <Autocomplete
                id={`breed$`}
                className={css.dualInput}
                value={breed}
                options={allBreeds}
                onChange={(_e, value) => setBreed(value ?? "")}
                renderInput={(params) => <TextField {...params} label="Rase" />}
              />
            </FormControl>
          </div>
          {type === "kommende" && (
            <div className={css.dualContainer}>
              <Input
                id="dadId"
                type="text"
                onChange={(value) => setDadId(value)}
                value={dadId}
                placeholder="Fars registreringsnummer"
                className={css.dualInput}
              />
              <Input
                id="momId"
                type="text"
                onChange={(value) => setMomId(value)}
                value={momId}
                placeholder="Mors registreringsnummer"
                className={css.dualInput}
              />
            </div>
          )}
          {type === "fodt" && (
            <div className={css.includedContainer}>
              <p>Inkludert med valpen</p>
              <FormGroup className={css.formGrid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pedigree}
                      onChange={() => setPedigree(!pedigree)}
                    />
                  }
                  label="Stamtavle"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={healthCheck}
                      onChange={() => setHealthCheck(!healthCheck)}
                    />
                  }
                  label="Helsebok"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={puppyPack}
                      onChange={() => setPuppyPack(!puppyPack)}
                    />
                  }
                  label="Valpepakke"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={idTag}
                      onChange={() => setIdTag(!idTag)}
                    />
                  }
                  label="ID-merking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wormTreatment}
                      onChange={() => setWormTreatment(!wormTreatment)}
                    />
                  }
                  label="Ormekur"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={vaccinated}
                      onChange={() => setVaccinated(!vaccinated)}
                    />
                  }
                  label="Vaksine"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advising}
                      onChange={() => setAdvising(!advising)}
                    />
                  }
                  label="Rådgiving"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={other}
                      onChange={() => setOther(!other)}
                    />
                  }
                  label="Annet?"
                />
              </FormGroup>
              {other && (
                <div className={css.singleContainer}>
                  <Input
                    id="otherDetails"
                    type="text"
                    onChange={(value) => setOtherText(value)}
                    value={otherText}
                    className={css.singleInput}
                    placeholder="Hvis annet, hva er inkludert?"
                  />
                </div>
              )}
            </div>
          )}
          {type === "fodt" && (
            <div className={css.singleContainer}>
              <Input
                id="otherDetails"
                type="text"
                onChange={(value) => setExpLitter(value)}
                value={expLitter}
                className={css.singleInput}
                placeholder="Om kullet"
                multiline
              />
            </div>
          )}
        </div>
      </div>
      {numberOfPuppies !== "" &&
        type === "fodt" &&
        [...Array(parseInt(numberOfPuppies))].map((_item, index) => (
          <div className={css.mainContainer} key={index}>
            <div className={css.leftContainer}>
              <img
                src={
                  images[index] === "" || images[index] === undefined
                    ? "/images/placeholder-dog.png"
                    : images[index]
                }
                alt="Annonsebilde"
              />
              <Button
                label="Last opp bilde"
                disabled={uploading}
                onClick={() => {
                  setSelectedImage(index + 1);
                  hiddenFileInput.current && hiddenFileInput.current.click();
                }}
              />
              <Button
                label="Slett bilde"
                skin="noColor"
                onClick={() => {
                  const arr: string[] = [];
                  images.map((item, i) => {
                    if (i === index) {
                      arr.push("");
                    } else {
                      arr.push(item);
                    }
                  });
                  setImages(arr);
                }}
              />
            </div>
            <div className={css.rightContainer}>
              <h2>Valp {index + 1}</h2>
              <div className={css.singleContainer}>
                <Input
                  id="adName"
                  type="text"
                  onChange={(value) =>
                    updatePuppyData(puppyProperty.name, index, value)
                  }
                  value={names[index]}
                  placeholder="Midlertidig navn"
                  className={css.singleInput}
                />
              </div>
              <div className={css.dualContainer}>
                <FormControl>
                  <InputLabel>Kjønn</InputLabel>
                  <Select
                    name={`genders-${index}`}
                    id={`genders-${index}`}
                    onChange={(e) =>
                      updatePuppyData(
                        puppyProperty.gender,
                        index,
                        e.target.value as string
                      )
                    }
                    className={css.dualInput}
                    value={genders[index]}
                  >
                    <MenuItem sx={{ zIndex: 100000 }} value={"male"}>
                      Hannhund
                    </MenuItem>
                    <MenuItem sx={{ zIndex: 100000 }} value={"female"}>
                      Tispe
                    </MenuItem>
                  </Select>
                </FormControl>
                <Input
                  id="registrationsNummer"
                  type="text"
                  onChange={(value) =>
                    updatePuppyData(puppyProperty.registration, index, value)
                  }
                  value={registrationNumbers[index]}
                  placeholder="Registreringsnummer"
                  className={css.dualInput}
                />
              </div>
              <div className={css.dualContainer}>
                <Input
                  id={`colors-${index}`}
                  type="text"
                  onChange={(value) =>
                    updatePuppyData(puppyProperty.color, index, value)
                  }
                  value={colors[index]}
                  placeholder="Farge"
                  className={css.dualInput}
                />
                <FormControl>
                  <InputLabel>Tilgjengelighet</InputLabel>
                  <Select
                    name={`statuses-${index}`}
                    id={`statuses-${index}`}
                    value={statuses[index]}
                    onChange={(e) =>
                      updatePuppyData(
                        puppyProperty.availability,
                        index,
                        e.target.value as string
                      )
                    }
                    className={css.dualInput}
                  >
                    <MenuItem sx={{ zIndex: 100000 }} value={"AVAILABLE"}>
                      Tilgjengelig
                    </MenuItem>
                    <MenuItem sx={{ zIndex: 100000 }} value={"RESERVED"}>
                      Reservert
                    </MenuItem>
                    <MenuItem sx={{ zIndex: 100000 }} value={"SOLD"}>
                      Solgt
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        ))}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div>
        <Button
          label="Tilbake"
          skin="lightGreen"
          onClick={() => history.goBack()}
        />
        <Button label="Fullfør" skin="dark" onClick={uploadAd} />
      </div>
    </div>
  );
};

export default NewPuppy;
