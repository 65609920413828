import React, { useEffect, useRef, useState } from "react";
import css from "../../styles/EditProfile.module.scss";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import { useIsMobile } from "../../hooks/useIsMobile";
import {
  Alert,
  Autocomplete,
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import Button from "../../components/Button";
import {
  useGetUserByEmailQuery,
  useGetUserQuery,
  useUpdateKennelMutation,
} from "../../api/graphql.gen";
import Input from "../../components/NewInput";
import { DogBreeds } from "../../model/DogBreeds";
import Footer from "./Footer";
import uploadFileToBlob from "../../utils/azure-storage-blob";
import { v4 } from "uuid";
import { clubNames } from "../../model/Clubs";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import AgriaAgents from "../../model/AgriaAgents";

const EditProfile: React.FC = () => {
  const { isMobile } = useIsMobile();
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? 7;
  const { data: userData } = useGetUserQuery({ id: userId });
  const user = userData?.getUser;
  const allBreeds = DogBreeds.sort();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const [kennel, setKennel] = useState("");
  const [NKKMember, setNKKMember] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [joinAgria, setJoinAgria] = useState(false);
  const [hasClub, setHasClub] = useState(false);
  const [NKKMembership, setNKKMembership] = useState("");
  const [breeds, setBreeds] = useState<string[]>([""]);
  const [about, setAbout] = useState<string>("");
  const [clubs, setClubs] = useState<string[]>([""]);
  const [profilePicture, setProfilePicture] = useState<string>(
    "/images/default-avatar.png"
  );
  const [position, setPosition] = useState<string>("");
  const [ownerActivity, setOwnerActivity] = useState<number>();
  const [ownerFamily, setOwnerFamily] = useState<number>();
  const [ownerLiving, setOwnerLiving] = useState<number>();
  const [ownerExperience, setOwnerExperience] = useState<number>();
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);
  const [myAgent, setMyAgent] = useState<string>();
  const [isAgriaMember, setIsAgriaMember] = useState<boolean>();

  const { mutate: updateProfile } = useUpdateKennelMutation({
    onSuccess: () => setSavedChanges(true),
  });

  const saveChanges = () => {
    userId !== -1 &&
      user?.kennel?.id &&
      updateProfile({
        id: user?.kennel?.id,
        name: kennel,
        about,
        nkkId: NKKMember ? NKKMembership : "",
        NKKCheck: NKKMember,
        breeds,
        bannerImage: bannerImage,
        clubs: hasClub ? clubs : "",
        url: "",
        experience: about,
        motivation: about,
        specialization: about,
        clubPosition: position,
        ownerActivity,
        ownerFamily,
        ownerExperience,
        ownerLiving,
        firstYear: parseInt(yearsOfExperience),
        profilePicture:
          profilePicture === "/images/default-avatar.png"
            ? null
            : profilePicture,
        userId: userId,
        agriaAgentId: myAgent
          ? AgriaAgents.find((agent) => agent.name === myAgent)?.id
          : undefined,
        agriaMember: isAgriaMember,
        joinAgria,
      });
  };

  const updateBreeds = (value: string, index: number) => {
    const li: string[] = [];
    breeds.map((item, i) => (i === index ? li.push(value) : li.push(item)));
    setBreeds(li);
  };

  useEffect(() => {
    if (user) {
      const agentName = AgriaAgents.find(
        (agent) => agent.id === user.kennel?.agriaAgentId
      )?.name;
      setKennel(user.kennel?.name ?? "");
      setNKKMembership(user.kennel?.nkkId ?? "");
      setNKKMember(user.kennel?.NKKCheck ?? false);
      setBreeds(user.kennel?.breeds ?? [""]);
      setAbout(user.kennel?.motivation ?? "");
      setClubs(user.kennel?.clubs?.length ? user.kennel?.clubs : [""]);
      setHasClub(!!user.kennel?.clubs?.length);
      setYearsOfExperience(user.kennel?.firstYear?.toString() ?? "");
      setBannerImage(user.kennel?.bannerImage ?? "");
      setOwnerActivity(user.kennel?.ownerActivity ?? undefined);
      setOwnerExperience(user.kennel?.ownerExperience ?? undefined);
      setOwnerFamily(user.kennel?.ownerFamily ?? undefined);
      setOwnerLiving(user.kennel?.ownerLiving ?? undefined);
      setPosition(user.kennel?.clubPosition ?? "");
      setProfilePicture(user?.profilePicture ?? "/images/default-avatar.png");
      setMyAgent(agentName ?? undefined);
      setIsAgriaMember(user.agriaMember);
    }
  }, [user]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<File | null>(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (event: any) => {
    // capture file into state
    const file = event.target.files[0];
    const uuidFilename = new File([file], v4() + file.name);
    setFileSelected(uuidFilename);
  };

  useEffect(() => {
    if (fileSelected) {
      onFileUpload();
    }
  }, [fileSelected]);

  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    await uploadFileToBlob(fileSelected);
    selectedImage === 0
      ? setProfilePicture(
          `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
        )
      : setBannerImage(
          `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
        );

    // reset state/form
    setFileSelected(null);
    setUploading(false);
  };

  useEffect(() => {
    window.analytics.page("Rediger profil");
  }, []);

  return (
    <div className={css.EditProfile}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Rediger profil"
          next="Kontoinnstillinger >"
        />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/edit-circle.svg"
          percentage={60}
          title="Rediger profil"
          info="POND råder til å følge NKK og raseklubbene sine retningslinjer for avl av hund. Vi anbefaler å følge disse. Falske opplysninger vil kunne føre til utestengelse. "
        />
        <div className={css.mainContainer}>
          <div className={css.leftContainer}>
            {uploading ? (
              <LoadingScreen />
            ) : (
              <Avatar
                src={profilePicture}
                alt="Profilbilde"
                className={css.avatar}
              />
            )}
            <Button
              label="Bytt profilbilde"
              onClick={() => {
                setSelectedImage(0);
                hiddenFileInput.current && hiddenFileInput.current.click();
              }}
            />
            <input
              type="file"
              hidden
              ref={hiddenFileInput}
              onChange={onFileChange}
            />
            <Button
              label="Slett bilde"
              skin="noColor"
              onClick={() => setProfilePicture("/images/default-avatar.png")}
            />
          </div>
          <div className={css.infoContainer}>
            <div className={css.inputContainer}>
              <Input
                id="kennelName"
                placeholder="Navn på kennel"
                value={kennel}
                required
                onChange={(value) => setKennel(value)}
                type="text"
                className={css.input}
              />
            </div>
            <FormGroup className={css.check}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={NKKMember}
                    onClick={() => {
                      setNKKMember(!NKKMember);
                    }}
                  />
                }
                label="Medlem av NKK"
              />
            </FormGroup>
            {NKKMember && (
              <div className={css.inputContainer}>
                <Input
                  id="NKKMembership"
                  placeholder="NKK Medlemsnummer"
                  value={NKKMembership}
                  onChange={(value) => setNKKMembership(value)}
                  type="text"
                  className={css.input}
                />
              </div>
            )}
            <div className={css.breedContainer}>
              {breeds.map((_item, index) => (
                <FormControl
                  key={`breed-${index}`}
                  className={css.breedInputContainer}
                >
                  <InputLabel>Hunderaser</InputLabel>
                  <Select
                    name={`breed${index}`}
                    id={`breed${index}`}
                    className={css.inputSelect}
                    value={breeds[index]}
                    onChange={(e) =>
                      updateBreeds(e.target.value as string, index)
                    }
                    required
                  >
                    {allBreeds.map((breed, i) => (
                      <MenuItem sx={{ zIndex: 100000 }} value={breed} key={i}>
                        {breed}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    label="Slett"
                    skin="noColor"
                    className={css.deleteButton}
                    onClick={() => {
                      const arr: string[] = [];
                      breeds.map((breed, i) => {
                        if (index !== i) {
                          arr.push(breed);
                        }
                      });
                      setBreeds(arr);
                    }}
                  />
                </FormControl>
              ))}
              <div
                className={css.addMore}
                onClick={() => setBreeds([...breeds, ""])}
              >
                + Legg til rase
              </div>
            </div>
            <div className={css.inputContainer}>
              <Input
                id="experienceYears"
                placeholder="Fortell om kennelen din"
                value={about}
                onChange={(value) => setAbout(value)}
                type="text"
                multiline
                className={css.input}
              />
            </div>
            <div className={css.inputContainer}>
              <Input
                id="experienceYears"
                placeholder="Første kull (år)"
                value={yearsOfExperience}
                onChange={(value) => setYearsOfExperience(value)}
                type="text"
                className={css.input}
                error={parseInt(yearsOfExperience) > new Date().getFullYear()}
                errorMessage={
                  parseInt(yearsOfExperience) > new Date().getFullYear()
                    ? "Året kan ikke være frem i tid"
                    : ""
                }
              />
            </div>
            <FormGroup className={css.check}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasClub}
                    onChange={() => setHasClub(!hasClub)}
                  />
                }
                label="Jeg er med i kennel klubb"
              />
            </FormGroup>
            {hasClub && (
              <div>
                {clubs.map((club, index) => (
                  <div className={css.inputContainer} key={club + "-" + index}>
                    <div style={{ display: "flex" }}>
                      <Autocomplete
                        value={club}
                        options={clubNames}
                        onChange={(e, value) => {
                          const arr: string[] = [];
                          clubs.map((item, i) => {
                            if (i === index) {
                              arr.push(value as string);
                            } else {
                              arr.push(item);
                            }
                          });
                          setClubs(arr);
                        }}
                        className={css.input}
                        renderInput={(params) => (
                          <TextField {...params} label="Navn på klubb" />
                        )}
                      />
                      <Button
                        skin="noColor"
                        label="Slett"
                        onClick={() => {
                          const remainingClubs = clubs.filter(
                            (item) => item !== clubs[index]
                          );
                          setClubs(remainingClubs);
                        }}
                      />
                    </div>
                  </div>
                ))}
                <Button
                  skin="noColor"
                  label="+ Legg til flere"
                  onClick={() => setClubs([...clubs, ""])}
                />
              </div>
            )}
            <div className={css.inputContainer}>
              <div className={css.title}>Bannerbilde</div>
              <div className={css.bannerImageGrid}>
                {bannerImage !== "" ? (
                  <div className={css.existingBanner}>
                    <img
                      src={bannerImage}
                      onClick={() => {
                        setSelectedImage(1);
                        hiddenFileInput.current &&
                          hiddenFileInput.current.click();
                      }}
                    />
                    <div style={{ marginTop: "1rem" }}>
                      <Input
                        id="banner"
                        type="text"
                        onChange={(value) => setBannerText(value)}
                        value={bannerText}
                        placeholder="Bildetext (75 tegn)"
                      />
                    </div>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => setBannerImage("")}
                    >
                      Slett bannerbilde
                    </p>
                  </div>
                ) : (
                  <div
                    className={css.addBannerButton}
                    onClick={() => {
                      setSelectedImage(1);
                      hiddenFileInput.current &&
                        hiddenFileInput.current.click();
                    }}
                  >
                    <img src="/images/icons/image.svg" />
                    <div>Legg til bilde</div>
                  </div>
                )}
              </div>
            </div>
            {NKKMembership && NKKMembership !== "" && (
              <div className={css.agriaSection}>
                <div className={css.heading}>
                  <img
                    className={css.logo}
                    src="/images/agria-logo.png"
                    alt="Agria breeders club logo"
                  />
                  <div>Er du medlem i Agria Breeders Club?</div>
                </div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={!!isAgriaMember}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      onChange={() => setIsAgriaMember(true)}
                      label="Ja"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      onChange={() => {
                        setIsAgriaMember(false);
                        setMyAgent(undefined);
                      }}
                      label="Nei"
                    />
                  </RadioGroup>
                </FormControl>
                {isAgriaMember && (
                  <div>
                    <h4>Hvem er din rådgiver?</h4>
                    <TextField
                      name="myAgent"
                      select
                      id="myAgent"
                      value={myAgent}
                      fullWidth
                      onChange={(e) => setMyAgent(e.target.value)}
                      placeholder="Rådgiver"
                    >
                      <MenuItem value={undefined}>Velg rådgiver</MenuItem>
                      {AgriaAgents.map((agent, i) => (
                        <MenuItem value={agent.name} key={i}>
                          {agent.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <p>
                      Husker du ikke hvem din rådgiver er? Send mail til{" "}
                      <a href="mailto:info@agriabreedersclub.no">
                        info@agriabreedersclub.no
                      </a>
                    </p>
                  </div>
                )}
                {isAgriaMember === false && isAgriaMember !== undefined && (
                  <div>
                    <h4>Ønsker du å bli medlem?</h4>
                    <p>Som medlem får du fordeler som:</p>
                    <ul>
                      <li>Valpepakker til kjøperne dine</li>
                      <li>
                        Poeng du kan bruke til å kjøpe flotte produkter i Agria
                        Breeders Shop
                      </li>
                      <li>Nyhetsbrev om hund & helse</li>
                    </ul>
                    <FormControlLabel
                      control={
                        <Checkbox onChange={() => setJoinAgria(!joinAgria)} />
                      }
                      label="Det er gratis å være medlem, og du trenger ikke å være forsikret i Agria. Kryss av om du ønsker å bli kontaktet av Agria breeders club"
                    />
                    <p>
                      Ved å krysse av samtykker du for at din kontaktinformasjon
                      blir sendt til Agria Breeders Club
                    </p>
                  </div>
                )}
              </div>
            )}
            {/*<div className={css.inputContainer}>
              <Input
                id="clubPosition"
                placeholder="Stilling i klubb"
                value={position}
                onChange={(value) => setPosition(value)}
                type="text"
                className={css.input}
              />
              <p className={css.warningText}>
                *Tittel må verifiseres, vi behandler søknader fortløpende
              </p>
                  </div>*/}
          </div>
        </div>
        <div>
          <Button
            label="Tilbake"
            skin="lightGreen"
            onClick={() => history.goBack()}
          />
          <Button label="Fullfør" skin="dark" onClick={saveChanges} />
        </div>
        {savedChanges && (
          <Alert
            severity="success"
            onClose={() => setSavedChanges(false)}
            className={css.success}
          >
            Dine endringer har blitt lagret
          </Alert>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default EditProfile;
