import { Add } from "@mui/icons-material";
import React, { ReactElement } from "react";
import css from "../styles/Button.module.scss";
import textStyle from "../styles/TextStyles.module.scss";

export interface Props {
  label: string;
  rightIcon?: boolean;
  leftIcon?: boolean;
  icon?: ReactElement;
  variant?: string;
  fontSize?: number;
  disabled?: boolean;
  skin?: "dark" | "light" | "transparent" | "noColor" | "error" | "lightGreen";
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Button: React.FC<Props> = function ({
  label,
  rightIcon,
  leftIcon,
  icon,
  skin,
  className,
  fontSize,
  ...rest
}) {
  const getButtonStyle = (color: string) => {
    switch (color) {
      case "dark":
        return css.dark;
      case "light":
        return css.light;
      case "transparent":
        return css.transparent;
      case "noColor":
        return css.noColor;
      case "error":
        return css.error;
      case "lightGreen":
        return css.lightGreen;
      default:
        break;
    }
  };
  return (
    <button className={`${css.Button} ${className}`} {...rest}>
      <span className={`${css.container} ${getButtonStyle(skin || "dark")}`}>
        {leftIcon && <Add className={css.leftIcon} />}
        {icon}
        <span
          className={textStyle.buttonLabel}
          style={fontSize ? { fontSize } : undefined}
        >
          {label}
        </span>
        {rightIcon && <Add className={css.rightIcon} />}
      </span>
    </button>
  );
};

export default Button;
