import React from "react";
import css from "../styles/NoResult.module.scss";

const NoResult: React.FC = () => {
  return (
    <div className={css.noResult}>
      <img src="/images/ouch.svg" alt="To hunder med bandasje" />
      <h3>Huffda, ingen resultater</h3>
    </div>
  );
};

export default NoResult;
