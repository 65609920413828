import React from "react";
import css from "../../styles/ApplicationCard.module.scss";
import { Avatar } from "@mui/material";
import { format } from "date-fns";
import Button from "../Button";
import { useIsMobile } from "../../hooks/useIsMobile";

export interface CardProps {
  imgSrc: string;
  kennel: string;
  breed: string;
  applicant: string;
  applicationDate: Date;
  status: string;
  breeder: boolean;
  onSeeApplication?: () => void;
  onSendMessage?: () => void;
  onSeeAd?: () => void;
  onDelete?: () => void;
  onApprove?: () => void;
}

const ApplicationCard: React.FC<CardProps> = ({
  imgSrc,
  kennel,
  applicationDate,
  status,
  breeder,
  applicant,
  onSeeAd,
  onSeeApplication,
}: //breed,
//onSendMessage,
//onDelete,
//onApprove,
CardProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={`${css.ApplicationCard} ${
        status === "ACCEPTED"
          ? css.approved
          : status === "DENIED"
          ? css.denied
          : undefined
      }`}
    >
      <div className={css.infoContainer}>
        <div className={css.personalia}>
          <Avatar
            src={imgSrc.length > 1 ? imgSrc : "/images/default-avatar.png"}
            alt="Profilbilde"
            className={css.avatar}
          />
          <div>
            <div className={css.name}>{breeder ? applicant : kennel}</div>
            <div className={css.date}>
              {(breeder ? "Motatt " : "Sendt inn ") +
                format(new Date(applicationDate), "dd.MM.yy")}
            </div>
          </div>
        </div>
        {isMobile ? (
          <div className={css.buttonContainer}>
            <img
              src="/images/icons/dashboard/dhat-button.svg"
              alt="Chat knapp"
              className={css.chatButton}
            />
          </div>
        ) : (
          <div className={css.buttonContainer}>
            <img
              src="/images/icons/dashboard/dhat-button.svg"
              alt="Chat knapp"
              className={css.chatButton}
            />
            {!breeder ? (
              <Button label="Se annonse" skin="light" onClick={onSeeAd} />
            ) : undefined}
            <Button label="Se søknad" skin="light" onClick={onSeeApplication} />
          </div>
        )}
      </div>
      {isMobile ? (
        <div className={css.mobileButtonContainer}>
          {!breeder ? (
            <Button label="Se annonse" skin="light" onClick={onSeeAd} />
          ) : undefined}
          <Button label="Se søknad" skin="light" onClick={onSeeApplication} />
        </div>
      ) : undefined}
    </div>
  );
};

export default ApplicationCard;
