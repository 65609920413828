import React from "react";

export const DogFace: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6393 8.16602C8.38991 8.16602 6.89442 12.2087 9.36156 14.3234L11.2206 15.9169C11.5571 16.2053 11.9347 16.4197 12.3323 16.56V16.6943C12.3323 18.9587 10.4966 20.7944 8.23224 20.7944C5.96783 20.7944 4.13216 18.9587 4.13216 16.6943V15.0977C4.13216 14.4533 3.60983 13.931 2.96549 13.931C2.32116 13.931 1.79883 14.4533 1.79883 15.0977V16.6943C1.79883 20.2474 4.67916 23.1277 8.23224 23.1277C10.4101 23.1277 12.3351 22.0456 13.4991 20.3897C14.6631 22.0456 16.5881 23.1277 18.766 23.1277C22.319 23.1277 25.1994 20.2474 25.1994 16.6943V15.0977C25.1994 14.4533 24.677 13.931 24.0327 13.931C23.3884 13.931 22.866 14.4533 22.866 15.0977V16.6943C22.866 18.9587 21.0304 20.7944 18.766 20.7944C16.5016 20.7944 14.6659 18.9587 14.6659 16.6943V16.5595C15.063 16.4192 15.4401 16.205 15.7762 15.9169L17.6352 14.3234C20.1024 12.2087 18.6069 8.16602 15.3575 8.16602H11.6393ZM10.8801 12.5518C10.0577 11.8469 10.5562 10.4993 11.6393 10.4993H15.3575C16.4406 10.4993 16.9391 11.8469 16.1167 12.5518L14.2577 14.1453C13.8207 14.5198 13.176 14.5198 12.7391 14.1453L10.8801 12.5518Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16602 4.66667C4.81035 4.66667 5.33268 4.14433 5.33268 3.5C5.33268 2.85567 4.81035 2.33333 4.16602 2.33333C3.52168 2.33333 2.99935 2.85567 2.99935 3.5C2.99935 4.14433 3.52168 4.66667 4.16602 4.66667ZM4.16602 7C6.09901 7 7.66602 5.433 7.66602 3.5C7.66602 1.567 6.09901 0 4.16602 0C2.23302 0 0.666016 1.567 0.666016 3.5C0.666016 5.433 2.23302 7 4.16602 7Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.832 4.66667C23.4764 4.66667 23.9987 4.14433 23.9987 3.5C23.9987 2.85567 23.4764 2.33333 22.832 2.33333C22.1877 2.33333 21.6654 2.85567 21.6654 3.5C21.6654 4.14433 22.1877 4.66667 22.832 4.66667ZM22.832 7C24.765 7 26.332 5.433 26.332 3.5C26.332 1.567 24.765 0 22.832 0C20.899 0 19.332 1.567 19.332 3.5C19.332 5.433 20.899 7 22.832 7Z"
      fill="#065C53"
    />
  </svg>
);
