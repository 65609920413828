import { useAuth0 } from "@auth0/auth0-react";
import { Tabs, Tab } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetMyDogsQuery } from "../../api/graphql.gen";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import MyDogsCard from "../../components/Dashboard/MyDogsCard";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/MyDogs.module.scss";

const MyDogs: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [tab, setTab] = useState("1");
  const { isAuthenticated } = useAuth0();
  const { data } = useGetMyDogsQuery();
  const history = useHistory();
  const dogs = data?.getMyDogs.filter((item) => item.isOwner);
  const otherDogs = data?.getMyDogs.filter((item) => !item.isOwner);

  useEffect(() => {
    window.analytics.page("Avlshunder");
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const renderTabs = () => (
    <div>
      {tab === "1" ? (
        <div>
          {dogs?.length ? (
            <div className={css.dogsContainer}>
              {dogs.map((dog) => (
                <MyDogsCard
                  key={dog.id}
                  imgSrc={dog.images?.length ? dog.images[0] : ""}
                  imgAlt="Bilde av hund"
                  title={dog.name}
                  nkkNumber={dog.NKKId ?? "N/A"}
                  breedName={dog.breed}
                  birthDate={format(new Date(dog.born), "dd.MM.yyyy")}
                  onClick={() => history.push(`avlshunder/rediger/${dog.id}`)}
                />
              ))}
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          ) : (
            <div className={css.noDogs}>
              <p>Du har ingen registrerte hunder</p>
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {otherDogs?.length ? (
            <div className={css.dogsContainer}>
              {otherDogs.map((dog) => (
                <MyDogsCard
                  key={dog.id}
                  imgSrc={dog.images?.length ? dog.images[0] : ""}
                  imgAlt="Bilde av hund"
                  title={dog.name}
                  nkkNumber={dog.NKKId ?? "N/A"}
                  breedName={dog.breed}
                  birthDate={format(new Date(dog.born), "dd.MM.yyyy")}
                  onClick={() => history.push(`avlshunder/rediger/${dog.id}`)}
                />
              ))}
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          ) : (
            <div className={css.noDogs}>
              <p>Du har ingen registrerte hunder</p>
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className={css.MyDogs}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/paw-circle.svg"
          percentage={60}
          title="Avlshunder"
          info="Her kan du legge til dine egne hunder og hunder fra andre oppdrettere som du bruker i avl."
        />
        <Tabs
          value={tab}
          onChange={(_event, value) => setTab(value)}
          variant="fullWidth"
        >
          <Tab value="1" label="Mine hunder" />
          <Tab value="2" label="Andres hunder" />
        </Tabs>
        {renderTabs()}
      </div>
    </div>
  );
};

export default MyDogs;
