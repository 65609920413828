export const translateHD = (value: number) => {
  switch (value) {
    case -1:
      return "Ikke testet";
    case 0:
      return "Ikke relevant";
    case 1:
      return "A (Fri)";
    case 2:
      return "B (Fri)";
    case 3:
      return "C (Svak grad)";
    case 4:
      return "D (Middels grad)";
    case 5:
      return "E (Sterk grad)";
    default:
      return "-";
  }
};

export const translateAD = (value: number) => {
  switch (value) {
    case -2:
      return "Ikke testet";
    case -1:
      return "Ikke relevant";
    case 0:
      return "0 (Fri)";
    case 1:
      return "1 (Svak grad)";
    case 2:
      return "2 (Middels grad)";
    case 3:
      return "3 (Sterk grad)";
    default:
      return "-";
  }
};

export const translatePatella = (value: number) => {
  switch (value) {
    case -2:
      return "Ikke testet";
    case -1:
      return "Ikke relevant";
    case 0:
      return "0 (Fri)";
    case 1:
      return "1 (Svak grad)";
    case 2:
      return "2 (Middels grad)";
    case 3:
      return "3 (Sterk grad)";
    default:
      return "-";
  }
};
