import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useCreateChatConnectionMutation,
  useGetUserByEmailQuery,
  useGetUserQuery,
  useSendMessageMutation,
} from "../api/graphql.gen";
import Button from "../components/Button";
import TextArea from "../components/TextArea";
import css from "../styles/Login.module.scss";

interface RouteParams {
  id: string;
}

const ContactBreeder: React.FC = () => {
  const { user } = useAuth0();
  const { data: userData } = useGetUserByEmailQuery({
    email: user?.email ?? "",
  });
  const userId = userData?.getUserByEmail.id;
  const { id } = useParams<RouteParams>();
  const [request, setRequest] = useState("");
  const [livingSituation, setLivingSituation] = useState("");
  const [step, setStep] = useState(0);

  const history = useHistory();

  useEffect(() => {
    window.analytics.page("Contact breeder");
  }, []);

  const { data: breeder } = useGetUserQuery({ id: parseInt(id) });

  const { mutate: createConnection } = useCreateChatConnectionMutation({
    onSuccess: (data) => {
      if (userId) {
        if (step === 1) {
          sendMessage({
            chatId: data.createChatConnection.id,
            content: request,
          });
        }
        if (step === 2) {
          livingSituation !== "" &&
            sendMessage({
              chatId: data.createChatConnection.id,
              content: "Min bosituasjon er: " + livingSituation,
            });
          sendMessage({
            chatId: data.createChatConnection.id,
            content: request,
          });
        }
        history.push("/chat");
      }
    },
  });

  const { mutate: sendMessage } = useSendMessageMutation({
    onSuccess: () => {
      history.push("chat");
    },
  });

  return (
    <div className={css.Login}>
      {step === 0 && (
        <div>
          <h4 className={css.title}>
            Ta kontakt med, {breeder?.getUser.firstName}!
          </h4>
          <p>Vil du stille et spørsmål eller sende en forespørsel om hund?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button label="Still et spørsmål" onClick={() => setStep(2)} />
            <Button
              label="Send forespørsel"
              onClick={() =>
                history.push(`/send-soknad/${breeder?.getUser.id}`)
              }
            />
          </div>
        </div>
      )}
      {step === 1 && (
        <div>
          <select
            name="livingSituation"
            id="livingSituation"
            className={css.select}
            onChange={(e) => setLivingSituation(e.target.value)}
          >
            <option value="">Hvordan er din bosituasjon</option>
            <option value="Bor i leilighet">Leilighet</option>
            <option value="Bor i hus med hage">Hus med hage</option>
            <option value="Bor i hus uten hage">Hus uten hage</option>
          </select>
          <TextArea
            value={request}
            onChange={(text) => setRequest(text)}
            className={css.input}
            placeholder="Legg til en tekst om deg selv"
            rows={8}
          />
          <Button
            label="Tilbake"
            skin="light"
            onClick={() => history.goBack()}
          />
          <Button
            label="Send"
            onClick={() =>
              userId
                ? createConnection({
                    receiverId: parseInt(id),
                  })
                : undefined
            }
          />
        </div>
      )}
      {step === 2 && (
        <div>
          <TextArea
            value={request}
            onChange={(text) => setRequest(text)}
            className={css.input}
            placeholder="Hva lurer du på?"
            rows={8}
          />
          <Button
            label="Tilbake"
            skin="light"
            onClick={() => history.goBack()}
          />
          <Button
            label="Send"
            onClick={() =>
              userId
                ? createConnection({
                    receiverId: parseInt(id),
                  })
                : undefined
            }
          />
        </div>
      )}
      <img
        src="/images/login_image.svg"
        alt="Person with laptop in front of dog"
      />
    </div>
  );
};

export default ContactBreeder;
