const formatName = (name: string) => {
  return name
    .replace(/\,/g, "")
    .replace(/\//g, "_")
    .replace(/æ/g, "ae")
    .replace(/ø/g, "o")
    .replace(/å/g, "aa")
    .replace(/é/g, "e")
    .replace(/ä/g, "a")
    .replace(/ü/g, "u")
    .replace(/ /g, "_")
    .replace(/\-/g, "_")
    .toLocaleLowerCase();
};

const formatBannerName = (name: string) => {
  const capitalizedName = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
  return capitalizedName.replace(/ /g, "_");
};
export const getBlobSrc = (name: string): string => {
  return `https://pondimages.blob.core.windows.net/raser/${formatName(
    name
  )}_portrett.jpg`;
};

export const getBannerSrc = (name: string): string => {
  if (name === "Schäferhund") {
    return `https://pondimages.blob.core.windows.net/breeds/Schäferhund_Banner.jpg`;
  }
  return `https://pondimages.blob.core.windows.net/breeds/${formatBannerName(
    name
  )}_Banner.jpg`;
};
