import React, { ReactElement } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/DashboardCard.module.scss";

export interface CardProps {
  icon: ReactElement;
  title: string;
  description: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const DashboardCard: React.FC<CardProps> = ({
  icon,
  title,
  description,
  onClick,
  disabled,
  className,
}: CardProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={`${disabled ? css.disabledCard : css.activeCard} ${className}`}
      onClick={disabled ? undefined : onClick}
    >
      <div
        className={disabled ? css.disabledTitleContainer : css.titleContainer}
      >
        <div className={css.icon}>{icon}</div>
        <div className={disabled ? css.disabledTitle : css.title}>{title}</div>
      </div>
      {!isMobile && (
        <div className={disabled ? css.disabledDescription : css.description}>
          {description}
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
