export const team = [
  {
    name: "Masoud Younesi",
    title: "Daglig Leder",
    image: "/images/team/masoud-team.png",
    email: "masoud@pond.no",
  },
  {
    name: "Johanne Tindstad",
    title: "Markedsansvarlig",
    image: "/images/team/johanne-team.png",
    email: "johanne.tindstad@pond.no",
  },
  {
    name: "Christian Husan",
    title: "Designansvarlig",
    image: "/images/team/christian-team.png",
    email: "christian@pond.no",
  },
  {
    name: "Masoom Maham",
    title: "CTO",
    image: "/images/team/masoom-team.png",
    email: "masoom@pond.no",
  },
  {
    name: "Iles Acly Ayati",
    title: "Økonomiansvarlig",
    image: "/images/team/iles-team.png",
    email: "iles@pond.no",
  },
  {
    name: "Rebecca Solberg-Eikeri",
    title: "Illustratør",
    image: "/images/team/rebecca-team.png",
    email: "",
  },
];
