const ClubData = [
  {
    breed: "Australian Shepherd",
    link: "https://www.askn.no/",
    img: "https://www.askn.no/wp-content/uploads/2022/10/cropped-cropped-ASKN-NY-LOGO-200x200.png",
    club: "Australian Shepherd klubb",
  },
  {
    breed: "Hvit Gjeterhund",
    link: "https://hvitgjeterhund.com/",
    img: "https://hvitgjeterhundklubb.files.wordpress.com/2015/01/hvit_gjeterhund_logo.jpg",
    club: "Hvit gjeterhund klubb",
  },
  {
    breed: "Bolognese",
    link: "https://bolcot.com/",
    img: "https://bolcot.com/wp-content/uploads/2017/07/xlogo.png.pagespeed.ic.uiQ9i7vuXC.webp",
    club: "Klubben for Bolognese og Coton de Tulear",
  },
  {
    breed: "Coton de tulear",
    link: "https://bolcot.com/",
    img: "https://bolcot.com/wp-content/uploads/2017/07/xlogo.png.pagespeed.ic.uiQ9i7vuXC.webp",
    club: "Klubben for Bolognese og Coton de Tulear",
  },
  {
    breed: "Akita",
    link: "https://www.akita.no/",
    img: "https://www.akita.no/Klubben/files/image-12-33E.gif",
    club: "Norsk Akita Klubb",
  },
  {
    breed: "Basset hound",
    link: "norskbassetklubb.no",
    img: "https://scontent.fosl3-1.fna.fbcdn.net/v/t39.30808…54d8_7VfIIDVveDFIkDJzrX6FPN-yka3gJNTw&oe=650951FD",
    club: "Norsk Bassetklubb",
  },
  {
    breed: "Basset artesien normand",
    link: "norskbassetklubb.no",
    img: "https://scontent.fosl3-1.fna.fbcdn.net/v/t39.30808…54d8_7VfIIDVveDFIkDJzrX6FPN-yka3gJNTw&oe=650951FD",
    club: "Norsk Bassetklubb",
  },
  {
    breed: "Basset fauve de bretagne",
    link: "norskbassetklubb.no",
    img: "https://scontent.fosl3-1.fna.fbcdn.net/v/t39.30808…54d8_7VfIIDVveDFIkDJzrX6FPN-yka3gJNTw&oe=650951FD",
    club: "Norsk Bassetklubb",
  },
  {
    breed: "Petit basset griffon vendeen",
    link: "norskbassetklubb.no",
    img: "https://scontent.fosl3-1.fna.fbcdn.net/v/t39.30808…54d8_7VfIIDVveDFIkDJzrX6FPN-yka3gJNTw&oe=650951FD",
    club: "Norsk Bassetklubb",
  },
  {
    breed: "Beacueron",
    link: "https://beauceronklubb.no/",
    img: undefined,
    club: "Norsk Beauceron Klubb",
  },
  {
    breed: "Berner sennenhund",
    link: "https://berner-sennen.no/",
    img: "https://berner-sennen.no/wp-content/uploads/2017/04/nbsk-logo-jpg.jpg",
    club: "Norsk Berner Sennenhundklubb",
  },
  {
    breed: "Boxer",
    link: "http://www.norskboxerklubb.no/",
    img: "http://www.norskboxerklubb.no/file/884424.jpg",
    club: "Norsk Boxerklubb",
  },
  {
    breed: "Norsk buhund",
    link: "https://buhund.no/",
    img: "https://buhund.no/wp-content/uploads/bb-plugin/cache/NBK-logo-bla-circle-c732930466ba8e6d23667aeccdea96ac-5e0362e4a4831.png",
    club: "Norsk Buhundklubb",
  },
  {
    breed: "Engelsk bulldog",
    link: "https://www.norskbulldogklubb.net/",
    img: "https://www.norskbulldogklubb.net/wp-content/uploads/2022/12/ny-nbk-logo-md.png",
    club: "Norsk Bulldog klubb",
  },
  {
    breed: "Franks bulldog",
    link: "https://www.norskbulldogklubb.net/",
    img: "https://www.norskbulldogklubb.net/wp-content/uploads/2022/12/ny-nbk-logo-md.png",
    club: "Norsk Bulldog klubb",
  },
  {
    breed: "Chow Chow",
    link: "https://www.ncck.no/",
    img: "https://www.ncck.no/images/ncck/header.PNG",
    club: "Norsk Chow Chow Klubb",
  },
  {
    breed: "Finsk lapphund ",
    link: "https://www.norsklapphundklubb.no/",
    img: "https://www.norsklapphundklubb.no/wp-content/uploads/2019/09/cropped-Logo-NLK_RGB.png",
    club: "Norsk Lapphundklubb",
  },
  {
    breed: "Svensk lapphund",
    link: "https://www.norsklapphundklubb.no/",
    img: "https://www.norsklapphundklubb.no/wp-content/uploads/2019/09/cropped-Logo-NLK_RGB.png",
    club: "Norsk Lapphundklubb",
  },
  {
    breed: "Lapsk vallhund",
    link: "https://www.norsklapphundklubb.no/",
    img: "https://www.norsklapphundklubb.no/wp-content/uploads/2019/09/cropped-Logo-NLK_RGB.png",
    club: "Norsk Lapphundklubb",
  },
  {
    breed: "Malteser",
    link: "https://norskmalteser.org/",
    img: "https://norskmalteser.org/wp-content/uploads/2014/04/top-heading.jpg",
    club: "Norsk Malteser Klubb",
  },
  {
    breed: "Miniature american shepherd",
    link: "https://www.nmask.no/nyttiginfo",
    img: "https://static.wixstatic.com/media/7c8440_91d7ee5d39d14a57a85dbc009125c623~mv2.png/v1/fill/w_250,h_280,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO1%20Bilde_edited_edited.png",
    club: "NORSK MINIATURE AMERICAN SHEPHERD KLUBB",
  },
  {
    breed: "Mops",
    link: "https://norskmopsklubb.no/",
    img: undefined,
    club: "Norsk Mops Klubb",
  },
  {
    breed: "Newfoundlandshund",
    link: "http://www.newf.no/php2009/",
    img: "https://www.newf.no/php2009/templates/rt_versatility4_j15/images/blank.gif",
    club: "Norsk Newfoundlandshundklubb",
  },
  {
    breed: "Toy puddel",
    link: "https://puddelklubb.no/",
    club: "Norsk Puddelklubb",
    img: "https://scontent.fosl3-2.fna.fbcdn.net/v/t39.30808-6/327179939_710041687197318_3982518790236449284_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=RSLj6DqefOAAX8i_oae&_nc_ht=scontent.fosl3-2.fna&oh=00_AfAeCKXBc1-r45liJyNaWElTGVIgWPxHLzdee-2EgMph2w&oe=650947F8",
  },
  {
    breed: "Mellompuddel",
    link: "https://puddelklubb.no/",
    club: "Norsk Puddelklubb",
    img: "https://scontent.fosl3-2.fna.fbcdn.net/v/t39.30808-6/327179939_710041687197318_3982518790236449284_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=RSLj6DqefOAAX8i_oae&_nc_ht=scontent.fosl3-2.fna&oh=00_AfAeCKXBc1-r45liJyNaWElTGVIgWPxHLzdee-2EgMph2w&oe=650947F8",
  },
  {
    breed: "Stor puddel",
    link: "https://puddelklubb.no/",
    club: "Norsk Puddelklubb",
    img: "https://scontent.fosl3-2.fna.fbcdn.net/v/t39.30808-6/327179939_710041687197318_3982518790236449284_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=RSLj6DqefOAAX8i_oae&_nc_ht=scontent.fosl3-2.fna&oh=00_AfAeCKXBc1-r45liJyNaWElTGVIgWPxHLzdee-2EgMph2w&oe=650947F8",
  },
  {
    breed: "Dvergpuddel",
    link: "https://puddelklubb.no/",
    club: "Norsk Puddelklubb",
    img: "https://scontent.fosl3-2.fna.fbcdn.net/v/t39.30808-6/327179939_710041687197318_3982518790236449284_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=RSLj6DqefOAAX8i_oae&_nc_ht=scontent.fosl3-2.fna&oh=00_AfAeCKXBc1-r45liJyNaWElTGVIgWPxHLzdee-2EgMph2w&oe=650947F8",
  },
  {
    breed: "Curly coated retriever",
    link: "https://retrieverklubben.no/",
    img: "https://retrieverklubben.no/trondheim/wp-content/uploads/sites/22/2016/08/cropped-Retriever-Fav-Icon.png",
    club: "Norsk Retrieverklubb",
  },
  {
    breed: "Flat coated retriever",
    link: "https://retrieverklubben.no/",
    img: "https://retrieverklubben.no/trondheim/wp-content/uploads/sites/22/2016/08/cropped-Retriever-Fav-Icon.png",
    club: "Norsk Retrieverklubb",
  },
  {
    breed: "Golden retriever",
    link: "https://retrieverklubben.no/",
    img: "https://retrieverklubben.no/trondheim/wp-content/uploads/sites/22/2016/08/cropped-Retriever-Fav-Icon.png",
    club: "Norsk Retrieverklubb",
  },
  {
    breed: "Chesapeake retriever",
    link: "https://retrieverklubben.no/",
    img: "https://retrieverklubben.no/trondheim/wp-content/uploads/sites/22/2016/08/cropped-Retriever-Fav-Icon.png",
    club: "Norsk Retrieverklubb",
  },
  {
    breed: "Labrador retriever",
    link: "https://retrieverklubben.no/",
    img: "https://retrieverklubben.no/trondheim/wp-content/uploads/sites/22/2016/08/cropped-Retriever-Fav-Icon.png",
    club: "Norsk Retrieverklubb",
  },
  {
    breed: "Nova scotia duck tolling retriever",
    link: "https://retrieverklubben.no/",
    img: "https://retrieverklubben.no/trondheim/wp-content/uploads/sites/22/2016/08/cropped-Retriever-Fav-Icon.png",
    club: "Norsk Retrieverklubb",
  },
  {
    breed: "Stabijhoun",
    link: "https://www.stabijhoun.no/",
    img: "https://www.stabijhoun.no/wp-content/uploads/2017/01/nosk-logo-arrangementer.png",
    club: "Norsk Stabijhoun Klubb",
  },
  {
    breed: "Portugisisk vannhund",
    link: "http://www.portugisisk-vannhund.no/",
    img: undefined,
    club: "Portugisisk Vannhund",
  },
  {
    breed: "Dansk-svensk gårdshund",
    link: "https://dansksvenskgardshund.no/",
    img: undefined,
    club: "Raseklubb for Dansk Svensk Gårdshund",
  },
  {
    breed: "Siberian Husky",
    link: "https://www.siberian-husky.net/w/",
    img: "https://www.siberian-husky.net/w/wp-content/uploads/2022/09/cropped-cropped-NSHK-logo-1.png",
    club: "Norsk Siberian Husky Klubb",
  },
  {
    breed: "Schäferhund langhår",
    link: "https://www.nschk.no/",
    img: "https://admin.mekke.no/thumbnail.php?w=150&img=/data/images/2879/nschk_LOGO_100_dpi.jpg",
    club: "Norsk Schäferhund Klub",
  },
  {
    breed: "Schäferhund normalhår",
    link: "https://www.nschk.no/",
    img: "https://admin.mekke.no/thumbnail.php?w=150&img=/data/images/2879/nschk_LOGO_100_dpi.jpg",
    club: "Norsk Schäferhund Klub",
  },
  {
    breed: "Alaskan Malamute",
    link: "http://www.namk.no/",
    img: "http://www.namk.no/uploads/4/4/6/4/4464761/published/namk_1.png",
    club: "Norsk Alaskan Malamute Klubb",
  },
  {
    breed: "Jack russell terrier",
    link: "https://norskterrierklub.no/raser/jack-russell-terrier",
    img: "https://scontent.fosl3-2.fna.fbcdn.net/v/t39.30808-6/307309830_462829705876333_3237942540786322691_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=JFjVhFVTwWkAX_DfraW&_nc_ht=scontent.fosl3-2.fna&oh=00_AfBzXxW9DWGwOZHCcQqJXqLnDjmQ6RMdBMRFUhPLDCXyxA&oe=65BB4904",
    club: "Norsk Terrier Klub",
  },
];

export default ClubData;
