import styled from "@emotion/styled";
import { Box, Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import React, { useState } from "react";
import { DogParent } from "../../api/graphql.gen";
import css from "../../styles/ProfileModalContent.module.scss";
import Switch from "react-switch";
import {
  translateAD,
  translateHD,
  translatePatella,
} from "../../utils/healthTranslations";

interface ModalProps {
  dogData: DogParent | DogParent[];
}

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const DogModalContent: React.FC<ModalProps> = ({ dogData }: ModalProps) => {
  const [tab, setTab] = useState("1");
  const [dog, setDog] = useState<DogParent>(
    Array.isArray(dogData) ? dogData[0] : dogData
  );

  return (
    <div className={css.ModalContent}>
      <div className={css.tabContainer}>
        <Puller />
        <Tabs value={tab} variant="fullWidth" className={css.tabs}>
          <Tab value="1" label="Om hunden" onClick={() => setTab("1")} />
          <Tab value="2" label="Helse" onClick={() => setTab("2")} />
          <Tab value="3" label="Championater" onClick={() => setTab("3")} />
        </Tabs>
      </div>
      {tab === "1" && (
        <div className={css.tabContent}>
          <div className={css.dogName}>{dog?.name}</div>
          <img
            src={
              dog?.images?.length
                ? dog?.images[0]
                : "/images/placeholder-dog.png"
            }
            className={css.dogImage}
            alt="Bilde av hunden"
          />
          <div className={css.detailGrid}>
            <div>Rase</div>
            <div>{dog?.breed}</div>
            <div>Reg nummer</div>
            <div>{dog?.NKKId}</div>
            <div>Farge</div>
            <div>{dog?.color ?? "-"}</div>
            <div>Født</div>
            <div>{dog?.born && format(new Date(dog?.born), "dd.MM.yyyy")}</div>
            <div>Vekt</div>
            <div>{dog?.weight ?? "-"}</div>
            <div>Eid av</div>
            <div>{dog?.isOwner ? "" : dog?.ownersName}</div>
          </div>
          <div className={css.descriptionContainer}>
            <div className={css.title}>Om huden</div>
            <div className={css.description}>{dog?.description}</div>
          </div>
        </div>
      )}
      {tab === "2" && (
        <div className={css.tabContent}>
          <div className={css.dogName}>{dog?.name}</div>
          <img
            src={
              dog?.images?.length
                ? dog?.images[0]
                : "/images/placeholder-dog.png"
            }
            className={css.dogImage}
            alt="Bilde av hunden"
          />
          <div className={css.descriptionContainer}>
            <div className={css.title}>Vaksiner</div>
            <div className={css.healthGrid}>
              <div className={css.bold}>Ormekur</div>
              <div className={css.regular}>
                {dog?.wormTreatment ? "Tatt" : "-"}
              </div>
              <div className={css.bold}>DHP</div>
              <div className={css.regular}>{dog?.dhp ? "Tatt" : "-"}</div>
              <div className={css.bold}>PiBbPi</div>
              <div className={css.regular}>{dog?.pibbpi ? "Tatt" : "-"}</div>
              <div className={css.bold}>Rabies</div>
              <div className={css.regular}>{dog?.rabies ? "Tatt" : "-"}</div>
              <div className={css.bold}>Veterinærattest</div>
              <div className={css.regular}>
                {dog?.vetCertificate ? "Tatt" : "-"}
              </div>
            </div>
          </div>
          <div className={css.descriptionContainer}>
            <div className={css.title}>Helsetester</div>
            <div className={css.healthGrid}>
              <div className={css.bold}>HD</div>
              <div className={css.regular}>{`Høyre: ${translateHD(
                dog?.HDRight ?? -10
              )}, Venstre: ${translateHD(dog?.HDLeft ?? -10)}`}</div>
              <div className={css.bold}>AD</div>
              <div className={css.regular}>{`Høyre: ${translateAD(
                dog?.ADRight ?? -10
              )}, Venstre: ${translateAD(dog?.ADLeft ?? -10)}`}</div>
              <div className={css.bold}>Patella</div>
              <div className={css.regular}>{`Høyre: ${translatePatella(
                dog?.PatellaRight ?? -10
              )}, Venstre: ${translatePatella(dog?.PatellaLeft ?? -10)}`}</div>
            </div>
          </div>
          <div className={css.descriptionContainer}>
            <div className={css.title}>Dokumentasjon</div>
            <div className={css.healthGrid}>
              <div className={css.bold}>Veterinærattest</div>
              <div className={css.regular}></div>
              <div className={css.bold}>Øyelysning</div>
              <div className={css.regular}></div>
              <div className={css.bold}>DNA-test</div>
              <div className={css.regular}></div>
              <div className={css.bold}>Mentaltest</div>
              <div className={css.regular}></div>
            </div>
          </div>
        </div>
      )}
      {tab === "3" && (
        <div className={css.tabContent}>
          <div className={css.dogName}>{dog?.name}</div>
          <img
            src={
              dog?.images?.length
                ? dog?.images[0]
                : "/images/placeholder-dog.png"
            }
            className={css.dogImage}
            alt="Bilde av hunden"
          />
          <div className={css.champions}>
            {dog?.championTitle?.map((title) => (
              <div key={title} className={css.championContainer}>
                <img src="/images/icons/award.svg" alt="Prisikon" />
                <p>{title}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {Array.isArray(dogData) && (
        <div className={css.switch}>
          <Switch
            onChange={() =>
              dog?.id === dogData[0]?.id
                ? setDog(dogData[1])
                : setDog(dogData[0])
            }
            checked={dogData[0]?.id === dog?.id}
            onColor="#065C53"
            offColor="#065C53"
            checkedIcon={
              <div className={css.parent}>
                <p> {dog?.gender === "male" ? "Far" : "Mor"}</p>
              </div>
            }
            uncheckedIcon={
              <div className={css.parent}>
                <p> {dog?.gender === "male" ? "Far" : "Mor"}</p>
              </div>
            }
            width={75}
            height={40}
          />
        </div>
      )}
    </div>
  );
};

export default DogModalContent;
