import React from "react";
import css from "../../styles/sanity-styles/VideoComponent.module.scss";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Section } from "../../utils/sanity-common";

interface VdeioComponentProps {
  section: Section;
}

const VideoComponent: React.FC<VdeioComponentProps> = ({
  section,
}: VdeioComponentProps) => {
  const { isMobile } = useIsMobile();
  return (
    <div
      className={css.videoComponent}
      style={{ background: `${section.backgroundColor}` }}
    >
      <div className={css.videoComponentContent}>
        <iframe
          src={section.url}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div>
        <p
          style={{
            color: section.textColor,
            fontSize: isMobile ? "20px" : "50px",
          }}
        >
          {section?.videoLabel}
        </p>
      </div>
    </div>
  );
};

export default VideoComponent;
