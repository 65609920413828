import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import css from "../styles/VerifyUser.module.scss";
import LoadingScreen from "../components/LoadingScreen";

interface RouteParams {
  userId: string;
  uuid: string;
  type: string;
}

const VerifyUser: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { userId, uuid, type } = useParams<RouteParams>();
  useEffect(() => {
    if (userId && uuid && type) {
      fetch(
        `https://pond-api.azurewebsites.net/api/webhook/confirm/${type}/${userId}/${uuid}`
      ).then((response) => {
        if (response.status == 200) {
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      });
    }
  }, [userId, uuid, type]);

  return (
    <div className={css.VerifyUser}>
      {loading && <LoadingScreen />}
      {!loading && error && (
        <Alert severity="error">
          Noe gikk galt! Prøv igjen eller send oss en mail på kontakt@pond.no
        </Alert>
      )}
      {!loading && !error && (
        <Alert severity="success">
          Brukeren har blitt verifisert! Du kan nå lukke dette vinduet
        </Alert>
      )}
    </div>
  );
};

export default VerifyUser;
