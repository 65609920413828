import React from "react";

export const Prize: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 2.62435C12.5064 2.62435 8.45833 6.67244 8.45833 11.666C8.45833 16.6596 12.5064 20.7077 17.5 20.7077C22.4936 20.7077 26.5417 16.6596 26.5417 11.666C26.5417 6.67244 22.4936 2.62435 17.5 2.62435ZM6.125 11.666C6.125 5.38378 11.2178 0.291016 17.5 0.291016C23.7822 0.291016 28.875 5.38378 28.875 11.666C28.875 17.9483 23.7822 23.041 17.5 23.041C11.2178 23.041 6.125 17.9483 6.125 11.666Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.873 19.0845C23.5118 18.9997 24.0982 19.4488 24.183 20.0876L25.9476 33.3876C26.0065 33.8316 25.8059 34.2702 25.4315 34.5162C25.0571 34.7621 24.5749 34.7719 24.1908 34.5414L17.4994 30.5266L10.808 34.5414C10.4238 34.7719 9.9416 34.7621 9.56717 34.5161C9.19273 34.2701 8.99222 33.8315 9.0512 33.3874L10.8158 20.102C10.9006 19.4633 11.4872 19.0143 12.1259 19.0991C12.7646 19.1839 13.2136 19.7705 13.1288 20.4092L11.6829 31.2954L16.8991 28.1656C17.2686 27.9439 17.7302 27.9439 18.0996 28.1656L23.3162 31.2956L21.8699 20.3945C21.7852 19.7557 22.2343 19.1692 22.873 19.0845Z"
      fill="#065C53"
    />
  </svg>
);
