import React from "react";
import Button from "../components/Button";
import css from "../styles/Login.module.scss";
import { useHistory } from "react-router";

const BreederRegistered: React.FC = () => {
  const history = useHistory();

  return (
    <div className={css.Login}>
      <div>
        <h4 className={css.title}>Velkommen til POND!</h4>
        <Button
          skin="dark"
          label="Legg ut kull"
          onClick={() => history.push("/opprett-annonse")}
        />
        <Button
          skin="light"
          label="Se profil"
          onClick={() => history.push(`/profil`)}
        />
      </div>
    </div>
  );
};

export default BreederRegistered;
