import css from "../styles/BreedOverview.module.scss";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { Close } from "@mui/icons-material";
import Button from "./Button";

interface SearchFilterProps {
  onClose?: () => void;
  sizeList: (size: string[]) => void;
  coopList: (coop: string[]) => void;
  activityList: (activity: string[]) => void;
  groomingList: (grooming: string[]) => void;
  breedTypeList?: (breedType: string[]) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  onClose,
  sizeList,
  coopList,
  activityList,
  groomingList,
}: //breedTypeList,
SearchFilterProps) => {
  // const [breedType, setBreedType] = useState<
  //   [boolean, boolean, boolean, boolean, boolean]
  // >([false, false, false, false, false]);

  const [activityLevel, setActivityLevel] = useState<
    [boolean, boolean, boolean]
  >([false, false, false]);

  const [size, setSize] = useState<[boolean, boolean, boolean]>([
    false,
    false,
    false,
  ]);

  const [coop, setCoop] = useState<[boolean, boolean, boolean]>([
    false,
    false,
    false,
  ]);

  const [grooming, setGrooming] = useState<[boolean, boolean, boolean]>([
    false,
    false,
    false,
  ]);

  const { isMobile } = useIsMobile(1024);

  useEffect(() => {
    const list: string[] = [];
    if (size[0]) {
      list.push("small");
    }
    if (size[1]) {
      list.push("medium");
    }
    if (size[2]) {
      list.push("large");
    }
    sizeList(list);
  }, [size]);

  useEffect(() => {
    const list: string[] = [];
    if (coop[0]) {
      list.push("low");
    }
    if (coop[1]) {
      list.push("medium");
    }
    if (coop[2]) {
      list.push("high");
    }
    coopList(list);
  }, [coop]);

  useEffect(() => {
    const list: string[] = [];
    if (activityLevel[0]) {
      list.push("low");
    }
    if (activityLevel[1]) {
      list.push("medium");
    }
    if (activityLevel[2]) {
      list.push("high");
    }
    activityList(list);
  }, [activityLevel]);

  useEffect(() => {
    const list: string[] = [];
    if (grooming[0]) {
      list.push("little");
    }
    if (grooming[1]) {
      list.push("medium");
    }
    if (grooming[2]) {
      list.push("much");
    }
    groomingList(list);
  }, [grooming]);

  // useEffect(() => {
  //   const list: string[] = [];
  //   if (breedType[0]) {
  //     list.push("Selskapshund");
  //   }
  //   if (breedType[1]) {
  //     list.push("Jakthund");
  //   }
  //   if (breedType[2]) {
  //     list.push("Brukshund");
  //   }
  //   if (breedType[3]) {
  //     list.push("Agility");
  //   }
  //   if (breedType[4]) {
  //     list.push("Lydighet");
  //   }
  //   breedTypeList(list);
  // }, [breedType]);

  return (
    <div className={css.searchFilters}>
      <div className={css.closeFilter}>
        {isMobile && (
          <Close
            fontSize="large"
            onClick={onClose}
            className={css.closeButton}
          />
        )}
      </div>
      {/* <div className={css.searchFiltersSection}>
        <div className={css.searchFiltersTitle}>Egenskaper</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={breedType[0]}
                onChange={(e) =>
                  setBreedType([
                    e.target.checked,
                    breedType[1],
                    breedType[2],
                    breedType[3],
                    breedType[4],
                  ])
                }
              />
            }
            label="Selskapshund"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={breedType[1]}
                onChange={(e) =>
                  setBreedType([
                    breedType[0],
                    e.target.checked,
                    breedType[2],
                    breedType[3],
                    breedType[4],
                  ])
                }
              />
            }
            label="Jakthund"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={breedType[2]}
                onChange={(e) =>
                  setBreedType([
                    breedType[0],
                    breedType[1],
                    e.target.checked,
                    breedType[3],
                    breedType[4],
                  ])
                }
              />
            }
            label="Brukshund"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={breedType[3]}
                onChange={(e) =>
                  setBreedType([
                    breedType[0],
                    breedType[1],
                    breedType[2],
                    e.target.checked,
                    breedType[4],
                  ])
                }
              />
            }
            label="Agility"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={breedType[4]}
                onChange={(e) =>
                  setBreedType([
                    breedType[0],
                    breedType[1],
                    breedType[2],
                    breedType[3],
                    e.target.checked,
                  ])
                }
              />
            }
            label="Lydighet"
          />
        </FormGroup>
      </div> */}
      <div className={css.searchFiltersSection}>
        <div className={css.searchFiltersTitle}>Størrelse</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={size[0]}
                onChange={(e) => setSize([e.target.checked, size[1], size[2]])}
              />
            }
            label="Liten"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={size[1]}
                onChange={(e) => setSize([size[0], e.target.checked, size[2]])}
              />
            }
            label="Medium"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={size[2]}
                onChange={(e) => setSize([size[0], size[1], e.target.checked])}
              />
            }
            label="Stor"
          />
        </FormGroup>
      </div>
      <div className={css.searchFiltersSection}>
        <div className={css.searchFiltersTitle}>Aktivitetsnivå</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={activityLevel[0]}
                onChange={(e) =>
                  setActivityLevel([
                    e.target.checked,
                    activityLevel[1],
                    activityLevel[2],
                  ])
                }
              />
            }
            label="Lav"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={activityLevel[1]}
                onChange={(e) =>
                  setActivityLevel([
                    activityLevel[0],
                    e.target.checked,
                    activityLevel[2],
                  ])
                }
              />
            }
            label="Middels"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={activityLevel[2]}
                onChange={(e) =>
                  setActivityLevel([
                    activityLevel[0],
                    activityLevel[1],
                    e.target.checked,
                  ])
                }
              />
            }
            label="Høyt"
          />
        </FormGroup>
      </div>
      <div className={css.searchFiltersSection}>
        <div className={css.searchFiltersTitle}>Samarbeid</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={coop[0]}
                onChange={(e) => setCoop([e.target.checked, coop[1], coop[2]])}
              />
            }
            label="Lav"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={coop[1]}
                onChange={(e) => setCoop([coop[0], e.target.checked, coop[2]])}
              />
            }
            label="Middels"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={coop[2]}
                onChange={(e) => setCoop([coop[0], coop[1], e.target.checked])}
              />
            }
            label="Mye"
          />
        </FormGroup>
      </div>
      <div className={css.searchFiltersSection}>
        <div className={css.searchFiltersTitle}>Pelspleie</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={grooming[0]}
                onChange={(e) =>
                  setGrooming([e.target.checked, grooming[1], grooming[2]])
                }
              />
            }
            label="Lav"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={grooming[1]}
                onChange={(e) =>
                  setGrooming([grooming[0], e.target.checked, grooming[2]])
                }
              />
            }
            label="Middels"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={grooming[2]}
                onChange={(e) =>
                  setGrooming([grooming[0], grooming[1], e.target.checked])
                }
              />
            }
            label="Mye"
          />
        </FormGroup>
      </div>
      {isMobile && (
        <div className={css.searchButtonContainer}>
          <Button
            label="Søk"
            className={css.searchButton}
            skin="light"
            onClick={onClose}
          />
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
