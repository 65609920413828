import React, { useEffect, useState } from "react";
import { DogAd, useGetAllAdsQuery } from "../api/graphql.gen";
import AdCard from "../components/AdCard";
import { useIsMobile } from "../hooks/useIsMobile";
import css from "../styles/AllAds.module.scss";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import LoadingScreen from "../components/LoadingScreen";
import Fade from "react-reveal/Fade";

const AllAds: React.FC = () => {
  const { data: ads, isLoading } = useGetAllAdsQuery();
  const { isMobile } = useIsMobile();

  const [sortedAds, setSortedAds] = useState<DogAd[]>();
  const [availability, setAvailability] = useState<string>("ALL");
  const [breed, setBreed] = useState<string>("ALL");
  const [allBreeds, setAllBreeds] = useState<string[]>([]);

  useEffect(() => {
    let allAds = ads?.dogAds;
    if (breed !== "ALL") {
      const breedOnlyAds = allAds?.filter((ad) => ad.breed === breed);
      if (breedOnlyAds) {
        allAds = [...breedOnlyAds];
      }
    }
    if (allAds) {
      const available = allAds.filter(
        (ad) => ad.adType === "AVAILABLE"
      ) as DogAd[];
      const planned = allAds.filter((ad) => ad.adType === "PLANNED") as DogAd[];
      const coming = allAds.filter((ad) => ad.adType === "COMING") as DogAd[];
      if (availability === "ALL" || availability === "") {
        setSortedAds([...available, ...coming, ...planned]);
      } else if (availability === "PLANNED") {
        setSortedAds([...planned]);
      } else if (availability === "COMING") {
        setSortedAds([...coming]);
      } else if (availability === "AVAILABLE") {
        setSortedAds([...available]);
      }
    }
  }, [ads, breed, availability]);

  useEffect(() => {
    const allAds = ads?.dogAds;
    const breeds: string[] = [];
    if (allAds) {
      allAds.map((ad) => {
        if (!breeds.includes(ad.breed) && ad.adType !== "DELETED") {
          breeds.push(ad.breed);
        }
      });
      setAllBreeds([...breeds]);
    }
  }, [ads]);

  return (
    <div className={css.AllAds}>
      <div className={css.intro}>
        <h1>Valper til salgs</h1>
        <p>
          Filtrer i planlagte, kommende og nyfødte valper til salgs. Utforsk
          valpeannonser, les om deres foreldre og helse, og kontakt oppdretterne
          for å finne en hund som passer for deg og din livsstil.{" "}
        </p>
      </div>
      <div className={css.filterContainer}>
        <FormControl
          size="small"
          sx={isMobile ? { width: "40%" } : { width: "20%" }}
        >
          <InputLabel id="availability-select-label">
            Tilgjengelighet
          </InputLabel>
          <Select
            labelId="availability-select-label"
            id="availability-select"
            value={availability}
            autoWidth
            label="Tilgjengelighet"
            onChange={(e) => setAvailability(e.target.value)}
          >
            <MenuItem value={"ALL"}>Alle</MenuItem>
            <MenuItem value={"AVAILABLE"}>Tilgjengelig</MenuItem>
            <MenuItem value={"COMING"}>Kommende</MenuItem>
            <MenuItem value={"PLANNED"}>Planlagt</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          size="small"
          sx={isMobile ? { width: "40%" } : { width: "20%" }}
        >
          <InputLabel id="breed-select-label">Rase</InputLabel>
          <Select
            labelId="breed-select-label"
            id="breed-select"
            value={breed}
            label="Rase"
            autoWidth
            onChange={(e) => setBreed(e.target.value)}
          >
            <MenuItem value={"ALL"}>Alle</MenuItem>
            {allBreeds.sort().map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={isMobile ? undefined : css.gridContainer}>
          {sortedAds?.map((ad) => (
            <div className={css.cardContainer} key={ad.id}>
              <Fade bottom>
                <AdCard
                  imgSrc={
                    ad.malePhotos[0] ??
                    ad.adImage ??
                    "/images/placeholder-dog.png"
                  }
                  isMobile={isMobile}
                  className={css.card}
                  status={ad.adType}
                  imgAlt="Annonsebilde"
                  breedName={ad.breed}
                  breederName={ad.user.kennel?.name ?? ""}
                  price={ad.price ?? 0}
                  totalDogsAvailable={
                    ad.puppies?.filter(
                      (puppy) => puppy.availability === "AVAILABLE"
                    ).length ?? 0
                  }
                  city={ad.user?.address?.city ?? ""}
                  to={`/annonse/${ad.id}`}
                />
              </Fade>
            </div>
          ))}
        </div>
      )}
      {sortedAds?.length === 0 && (
        <div className={css.notFound}>
          <Fade bottom>
            <img src="/images/ouch.svg" alt="Ingen resultat" />
            <h2>Fant ingen annonser</h2>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default AllAds;
