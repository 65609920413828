import React, { useEffect, useState } from "react";
import css from "../../styles/Info.module.scss";
import { getPosts, Section } from "../../utils/sanity-common";
import { PortableText } from "@portabletext/react";

const TermsOfUse: React.FC = function () {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    window.analytics.page("Brukervilkår for POND");
    getPostsType();
  }, []);

  async function getPostsType() {
    setSections(await getPosts("brukervilkar-for-pond"));
  }

  return (
    <div className={css.infoPage}>
      {sections?.map((section, index) => (
        <PortableText key={index} value={section?.blockArray} />
      ))}
    </div>
  );
};

export default TermsOfUse;
