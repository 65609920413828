import { Instagram, Facebook, LinkedIn } from "@mui/icons-material";
import React from "react";
import css from "../../styles/Dashboard.module.scss";

const Footer: React.FC = () => {
  return (
    <div className={css.footer}>
      <div className={css.socials}>
        <div
          onClick={() => window.open("https://www.linkedin.com/company/pondno")}
        >
          <LinkedIn />
          &nbsp;LinkedIn
        </div>
        <div
          onClick={() =>
            window.open("https://www.facebook.com/Pond-108541064932673")
          }
        >
          <Facebook />
          &nbsp;Facebook
        </div>
        <div
          onClick={() => window.open("https://www.instagram.com/pond.norge/")}
        >
          <Instagram />
          &nbsp;Instagram
        </div>
      </div>
      <div className={css.legal}>
        <div>
          Innholdet er beskyttet etter åndsverkloven. Regelmessig, systematisk
          eller kontinuerlig innhenting, lagring, indeksering, distribusjon og
          all annen form for sammenstilling av data tillates ikke uten
          eksplisitt, skriftlig tillatelse fra POND.no.
        </div>
        <p>©2023 POND AS</p>
      </div>
    </div>
  );
};

export default Footer;
