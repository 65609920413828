export const translateSize = (size: string): string => {
  switch (size) {
    case "small":
      return "Liten";
    case "medium":
      return "Medium";
    case "large":
      return "Stor";
    default:
      return "Ikke tilgjengelig";
  }
};

export const translateGrooming = (grooming: string): string => {
  switch (grooming) {
    case "little":
      return "Lite";
    case "medium":
      return "Medium";
    case "much":
      return "Mye";
    default:
      return "Ikke tilgjengelig";
  }
};

export const translateActivityLevel = (grooming: string): string => {
  switch (grooming) {
    case "low":
      return "Lite";
    case "medium":
      return "Middels";
    case "high":
      return "Mye";
    default:
      return "Ikke tilgjengelig";
  }
};
