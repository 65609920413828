/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import css from "./../styles/Articles.module.scss";
import ArticleCard from "../components/ArticleCard";
import { useIsMobile } from "../hooks/useIsMobile";
import { client } from "../index";
import imageUrlBuilder from "@sanity/image-url";
import { isAfter, isBefore } from "date-fns";

const Articles: React.FC = () => {
  const { isMobile } = useIsMobile(1024);
  const [articles, setArticles] = useState<any>();
  const [sortedArticles, setSortedArticles] = useState<any>();

  useEffect(() => {
    client
      .fetch(`*[_type == "articles"] | order(date desc)`)
      .then((sanityArticles) => setArticles(sanityArticles));
  }, []);

  useEffect(() => {
    if (articles) {
      const publishedArticles = articles.filter((article: any) =>
        isBefore(new Date(article.date), new Date())
      );
      const upcomingArticles = articles
        .filter((article: any) => isAfter(new Date(article.date), new Date()))
        .reverse();
      setSortedArticles([...publishedArticles, ...upcomingArticles]);
    }
  }, [articles]);

  const builder = imageUrlBuilder(client);
  function urlFor(source: any) {
    return builder.image(source.asset);
  }

  return (
    sortedArticles && (
      <div className={css.articles}>
        <div className={css.intro}>
          <h3>Nyheter og artikler</h3>
          <p>
            Velkommen til vår artikkelside – din kilde for verdifulle tips og
            råd om oppdrettere, hundehold og valpekjøp.
          </p>
        </div>
        <div className={css.content}>
          <div className={css.latest}>
            {/* <div className={css.title}>Nyeste</div>
            <ArticleCard
              title={articles[0].title}
              url={articles[0].slug.current}
              articleType={articles[0].articleType}
              img={urlFor(articles[0].coverImage).url()}
              breed={articles[0].breed}
              date={articles[0].date}
              mobile={isMobile}
            />
            <ArticleCard
              title={articles[1].title}
              url={articles[1].slug.current}
              articleType={articles[1].articleType}
              img={urlFor(articles[1].coverImage).url()}
              breed={articles[1].breed}
              date={articles[1].date}
              mobile={isMobile}
            /> */}
          </div>
          <div className={css.breeders}>
            <div className={css.title}>Nyeste artikler</div>
            <ArticleCard
              title={sortedArticles[0].title}
              url={sortedArticles[0].slug.current}
              articleType={sortedArticles[0].articleType}
              img={urlFor(sortedArticles[0].coverImage).url()}
              breed={sortedArticles[0].breed}
              date={new Date(sortedArticles[0].date)}
              mobile={isMobile}
            />
            <div className={isMobile ? undefined : css.cardGrid}>
              {sortedArticles?.map((article: any, index: number) => {
                return index === 0 ? undefined : (
                  <ArticleCard
                    key={article.title}
                    title={article.title}
                    url={article.slug.current}
                    articleType={article.articleType}
                    img={urlFor(article.coverImage).url()}
                    breed={article.breed}
                    date={new Date(article.date)}
                    mobile={true}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Articles;
