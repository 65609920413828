import {AxiosResponse} from 'axios';
import {axios} from './authenticated-axios';

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables,
) {
  return async (): Promise<TData> => {
    const res = await axios.post<
    {query: string; variables: TVariables | undefined},
    AxiosResponse<any>
  >('https://pond-api.azurewebsites.net/graphql', {query, variables});

    const json = await res.data;

    //@ts-ignore
    if (json.errors) {
    //@ts-ignore
      const {message} = json.errors[0];
      throw new Error(message);
    }

    //@ts-ignore
    return json.data;
  };
}
