import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import css from "../styles/Header.module.scss";
import { Divider, Drawer } from "@mui/material";
import { useIsMobile } from "../hooks/useIsMobile";
import { Spin as Hamburger } from "hamburger-react";
import { Instagram, Facebook, LinkedIn } from "@mui/icons-material";
import { authApi } from "../api/authenticated-axios";

const Header: React.FC = function () {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { isAuthenticated, logout, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (isAuthenticated) {
      const setToken = async () => {
        const token = await getAccessTokenSilently();
        authApi.setAccessToken(token);
      };
      setToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setOpen(false);
  }, [history.location]);

  return (
    <div className={css.Header}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {!isMobile && <Hamburger toggled={open} toggle={setOpen} size={25} />}
        <a href="/">
          <img
            src="/images/Pond_green.svg"
            alt="POND logo"
            className={css.logo}
          />
        </a>
      </div>
      <div className={css.buttonsContainer}>
        {isAuthenticated && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            className={css.svgButton}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.19193 3.69187C8.46718 2.41662 10.1968 1.7002 12.0003 1.7002C13.8037 1.7002 15.5333 2.41662 16.8086 3.69187C18.0838 4.96712 18.8003 6.69672 18.8003 8.5002C18.8003 11.8823 19.5239 13.9979 20.2026 15.2421C20.543 15.8662 20.8765 16.2792 21.1123 16.5281C21.2304 16.6528 21.3245 16.7369 21.3835 16.786C21.413 16.8106 21.4337 16.8265 21.4443 16.8343L21.4517 16.8398C21.7396 17.0365 21.8672 17.3976 21.7659 17.732C21.6638 18.0694 21.3528 18.3002 21.0003 18.3002H3.00026C2.64772 18.3002 2.33674 18.0694 2.23458 17.732C2.13333 17.3976 2.26088 17.0365 2.54877 16.8398L2.55623 16.8343C2.56679 16.8265 2.58752 16.8106 2.61702 16.786C2.676 16.7369 2.77014 16.6528 2.88825 16.5281C3.12404 16.2792 3.4575 15.8662 3.79794 15.2421C4.4766 13.9979 5.20026 11.8823 5.20026 8.5002C5.20026 6.69672 5.91668 4.96712 7.19193 3.69187ZM4.7844 16.7002H19.2161C19.0785 16.4947 18.9378 16.2647 18.7979 16.0083C17.9766 14.5025 17.2003 12.1181 17.2003 8.5002C17.2003 7.12107 16.6524 5.79843 15.6772 4.82324C14.702 3.84805 13.3794 3.3002 12.0003 3.3002C10.6211 3.3002 9.29849 3.84805 8.3233 4.82324C7.34811 5.79843 6.80026 7.12107 6.80026 8.5002C6.80026 12.1181 6.02391 14.5025 5.20257 16.0083C5.06271 16.2647 4.92203 16.4947 4.7844 16.7002Z"
              fill="#065C53"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.86844 20.8083C10.2506 20.5866 10.7402 20.7167 10.9619 21.0989C11.0673 21.2808 11.2188 21.4317 11.4009 21.5366C11.5831 21.6416 11.7896 21.6968 11.9999 21.6968C12.2101 21.6968 12.4166 21.6416 12.5988 21.5366C12.781 21.4317 12.9324 21.2808 13.0379 21.0989C13.2596 20.7167 13.7491 20.5866 14.1313 20.8083C14.5135 21.03 14.6436 21.5196 14.4219 21.9017C14.1757 22.3261 13.8224 22.6783 13.3974 22.9231C12.9723 23.1679 12.4904 23.2968 11.9999 23.2968C11.5093 23.2968 11.0274 23.1679 10.6023 22.9231C10.1773 22.6783 9.82399 22.3261 9.57786 21.9017C9.35616 21.5196 9.48626 21.03 9.86844 20.8083Z"
              fill="#065C53"
            />
          </svg>
        )}
        {isAuthenticated && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            className={css.svgButton}
            onClick={() => history.push("/chat")}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.64034 22.7767C5.04461 22.528 4.65668 21.9457 4.65668 21.3002V19.0117C2.96184 18.9738 1.59961 17.588 1.59961 15.884V6.90025C1.59961 5.13295 3.03229 3.70026 4.7996 3.70025L19.1996 3.7002C20.9669 3.70018 22.3996 5.13288 22.3996 6.9002V15.8125C22.3996 17.5798 20.9669 19.0125 19.1996 19.0125L10.8448 19.0125L7.38183 22.4377C6.92287 22.8917 6.23606 23.0254 5.64034 22.7767ZM6.25668 18.9411C6.25668 18.0969 5.57233 17.4125 4.72815 17.4125C3.88396 17.4125 3.19961 16.7282 3.19961 15.884V6.90025C3.19961 6.0166 3.91595 5.30026 4.7996 5.30025L19.1996 5.3002C20.0833 5.30019 20.7996 6.01654 20.7996 6.9002V15.8125C20.7996 16.6961 20.0833 17.4125 19.1996 17.4125L10.8448 17.4125C10.4235 17.4125 10.0192 17.5787 9.71966 17.875L6.25668 21.3002V18.9411Z"
              fill="#065C53"
            />
          </svg>
        )}
        {!isMobile && (
          <div
            className={css.loginButton}
            onClick={() =>
              isAuthenticated ? history.push("/min-pond") : loginWithRedirect()
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9998 2.35109C6.84259 2.35109 1.85118 7.34225 1.85118 13.4992C1.85118 19.6561 6.84259 24.6472 12.9998 24.6472C19.157 24.6472 24.1484 19.6561 24.1484 13.4992C24.1484 7.34225 19.157 2.35109 12.9998 2.35109ZM0.148438 13.4992C0.148438 6.4019 5.90219 0.648438 12.9998 0.648438C20.0974 0.648438 25.8512 6.4019 25.8512 13.4992C25.8512 20.5964 20.0974 26.3499 12.9998 26.3499C5.90219 26.3499 0.148438 20.5964 0.148438 13.4992Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.79022 21.0463C6.82775 23.2623 9.75123 24.6486 12.9952 24.6486C16.239 24.6486 19.1628 23.2624 21.2047 21.0457C19.1668 18.829 16.2431 17.439 12.9952 17.439C9.74737 17.439 6.8277 18.829 4.79022 21.0463ZM3.00455 20.5109C5.35742 17.6029 8.95611 15.7363 12.9952 15.7363C17.0339 15.7363 20.6378 17.6026 22.991 20.5109L23.4247 21.047L22.9903 21.5826C20.632 24.4905 17.0283 26.3512 12.9952 26.3512C8.96225 26.3512 5.35808 24.4906 3.00455 21.5818L2.57129 21.0463L3.00455 20.5109Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.995 5.97316C11.153 5.97316 9.65977 7.46634 9.65977 9.30827C9.65977 11.1502 11.153 12.6434 12.995 12.6434C14.8371 12.6434 16.3303 11.1502 16.3303 9.30827C16.3303 7.46634 14.8371 5.97316 12.995 5.97316ZM7.95703 9.30827C7.95703 6.52599 10.2126 4.27051 12.995 4.27051C15.7775 4.27051 18.0331 6.52599 18.0331 9.30827C18.0331 12.0905 15.7775 14.346 12.995 14.346C10.2126 14.346 7.95703 12.0905 7.95703 9.30827Z"
                fill="#065C53"
              />
            </svg>
            {isAuthenticated ? "Min POND" : "Logg inn"}
          </div>
        )}
        {isMobile && <Hamburger toggled={open} toggle={setOpen} size={25} />}
      </div>
      <Drawer
        anchor={isMobile ? "right" : "left"}
        open={open}
        onClose={() => setOpen(false)}
        className={css.drawer}
      >
        {(isMobile || isAuthenticated) && (
          <div
            className={css.drawerItem}
            onClick={() =>
              isAuthenticated ? history.push("/min-pond") : loginWithRedirect()
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9998 2.35109C6.84259 2.35109 1.85118 7.34225 1.85118 13.4992C1.85118 19.6561 6.84259 24.6472 12.9998 24.6472C19.157 24.6472 24.1484 19.6561 24.1484 13.4992C24.1484 7.34225 19.157 2.35109 12.9998 2.35109ZM0.148438 13.4992C0.148438 6.4019 5.90219 0.648438 12.9998 0.648438C20.0974 0.648438 25.8512 6.4019 25.8512 13.4992C25.8512 20.5964 20.0974 26.3499 12.9998 26.3499C5.90219 26.3499 0.148438 20.5964 0.148438 13.4992Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.78973 21.0463C6.82726 23.2623 9.75074 24.6486 12.9947 24.6486C16.2385 24.6486 19.1623 23.2624 21.2042 21.0457C19.1664 18.829 16.2426 17.439 12.9947 17.439C9.74688 17.439 6.82721 18.829 4.78973 21.0463ZM3.00406 20.5109C5.35693 17.6029 8.95562 15.7363 12.9947 15.7363C17.0334 15.7363 20.6373 17.6026 22.9905 20.5109L23.4242 21.047L22.9899 21.5826C20.6315 24.4905 17.0278 26.3512 12.9947 26.3512C8.96176 26.3512 5.35759 24.4906 3.00406 21.5818L2.5708 21.0463L3.00406 20.5109Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9955 5.97316C11.1535 5.97316 9.66026 7.46634 9.66026 9.30827C9.66026 11.1502 11.1535 12.6434 12.9955 12.6434C14.8376 12.6434 16.3308 11.1502 16.3308 9.30827C16.3308 7.46634 14.8376 5.97316 12.9955 5.97316ZM7.95752 9.30827C7.95752 6.52599 10.2131 4.27051 12.9955 4.27051C15.778 4.27051 18.0335 6.52599 18.0335 9.30827C18.0335 12.0905 15.7779 14.346 12.9955 14.346C10.2131 14.346 7.95752 12.0905 7.95752 9.30827Z"
                fill="#065C53"
              />
            </svg>{" "}
            {isAuthenticated ? "Min POND" : "Logg inn"}
          </div>
        )}
        {isAuthenticated && (
          <div
            className={css.drawerItem}
            onClick={() => history.push("/profil")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9998 2.35109C6.84259 2.35109 1.85118 7.34225 1.85118 13.4992C1.85118 19.6561 6.84259 24.6472 12.9998 24.6472C19.157 24.6472 24.1484 19.6561 24.1484 13.4992C24.1484 7.34225 19.157 2.35109 12.9998 2.35109ZM0.148438 13.4992C0.148438 6.4019 5.90219 0.648438 12.9998 0.648438C20.0974 0.648438 25.8512 6.4019 25.8512 13.4992C25.8512 20.5964 20.0974 26.3499 12.9998 26.3499C5.90219 26.3499 0.148438 20.5964 0.148438 13.4992Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.78973 21.0463C6.82726 23.2623 9.75074 24.6486 12.9947 24.6486C16.2385 24.6486 19.1623 23.2624 21.2042 21.0457C19.1664 18.829 16.2426 17.439 12.9947 17.439C9.74688 17.439 6.82721 18.829 4.78973 21.0463ZM3.00406 20.5109C5.35693 17.6029 8.95562 15.7363 12.9947 15.7363C17.0334 15.7363 20.6373 17.6026 22.9905 20.5109L23.4242 21.047L22.9899 21.5826C20.6315 24.4905 17.0278 26.3512 12.9947 26.3512C8.96176 26.3512 5.35759 24.4906 3.00406 21.5818L2.5708 21.0463L3.00406 20.5109Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9955 5.97316C11.1535 5.97316 9.66026 7.46634 9.66026 9.30827C9.66026 11.1502 11.1535 12.6434 12.9955 12.6434C14.8376 12.6434 16.3308 11.1502 16.3308 9.30827C16.3308 7.46634 14.8376 5.97316 12.9955 5.97316ZM7.95752 9.30827C7.95752 6.52599 10.2131 4.27051 12.9955 4.27051C15.778 4.27051 18.0335 6.52599 18.0335 9.30827C18.0335 12.0905 15.7779 14.346 12.9955 14.346C10.2131 14.346 7.95752 12.0905 7.95752 9.30827Z"
                fill="#065C53"
              />
            </svg>{" "}
            Min profil
          </div>
        )}
        {(isMobile || isAuthenticated) && (
          <Divider sx={{ borderColor: "var(--green-outline, #abc6c2)" }} />
        )}
        <div
          className={css.drawerItem}
          onClick={() => history.push("/valper-til-salgs")}
        >
          Valper til salgs
        </div>
        <div
          className={css.drawerItem}
          onClick={() => history.push("/oppdrettere")}
        >
          Oppdrettere
        </div>
        <div
          className={css.drawerItem}
          onClick={() => history.push("/hunderaser")}
        >
          Utforsk raser
        </div>
        <div
          className={css.drawerItem}
          onClick={() => history.push("/artikler")}
        >
          Artikler
        </div>
        <div
          className={css.drawerItem}
          onClick={() => window.open("https://tag.pond.no/")}
        >
          PondTag
        </div>
        <Divider sx={{ borderColor: "var(--green-outline, #abc6c2)" }} />
        <div className={css.drawerItem} onClick={() => history.push("/om-oss")}>
          Om oss
        </div>
        <div
          className={css.drawerItem}
          onClick={() => history.push("/betingelser-og-vilkar")}
        >
          Brukervilkår
        </div>
        <div
          className={css.drawerItem}
          onClick={() => history.push("/personvernerklæring")}
        >
          Personvernerklæring
        </div>
        <Divider sx={{ borderColor: "var(--green-outline, #abc6c2)" }} />
        <div
          className={css.drawerItem}
          onClick={() => history.push("/kontakt-oss")}
        >
          Kontakt oss
        </div>
        <div className={css.drawerItem} onClick={() => history.push("/faq")}>
          FAQ
        </div>
        <Divider sx={{ borderColor: "var(--green-outline, #abc6c2)" }} />
        <div
          className={css.drawerItem}
          onClick={() => window.open("https://www.instagram.com/pond.norge/")}
        >
          <Instagram fontSize="medium" /> Instagram
        </div>
        <div
          className={css.drawerItem}
          onClick={() =>
            window.open("https://www.facebook.com/Pond-108541064932673")
          }
        >
          <Facebook fontSize="medium" /> Facebook
        </div>
        <div
          className={css.drawerItem}
          onClick={() => window.open("https://www.linkedin.com/company/pondno")}
        >
          <LinkedIn fontSize="medium" /> Linkedin
        </div>
        {isAuthenticated && (
          <Divider sx={{ borderColor: "var(--green-outline, #abc6c2)" }} />
        )}
        {isAuthenticated && (
          <div className={css.drawerItem} onClick={() => logout()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.125 2.375C2.92609 2.375 2.73532 2.45402 2.59467 2.59467C2.45402 2.73532 2.375 2.92609 2.375 3.125V11.875C2.375 12.0739 2.45402 12.2647 2.59467 12.4053C2.73532 12.546 2.92609 12.625 3.125 12.625H5.625C5.90114 12.625 6.125 12.8489 6.125 13.125C6.125 13.4011 5.90114 13.625 5.625 13.625H3.125C2.66087 13.625 2.21575 13.4406 1.88756 13.1124C1.55937 12.7842 1.375 12.3391 1.375 11.875V3.125C1.375 2.66087 1.55937 2.21575 1.88756 1.88756C2.21575 1.55937 2.66087 1.375 3.125 1.375H5.625C5.90114 1.375 6.125 1.59886 6.125 1.875C6.125 2.15114 5.90114 2.375 5.625 2.375H3.125Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.64645 4.02145C9.84171 3.82618 10.1583 3.82618 10.3536 4.02145L13.4786 7.14645C13.6738 7.34171 13.6738 7.65829 13.4786 7.85355L10.3536 10.9786C10.1583 11.1738 9.84171 11.1738 9.64645 10.9786C9.45118 10.7833 9.45118 10.4667 9.64645 10.2714L12.4179 7.5L9.64645 4.72855C9.45118 4.53329 9.45118 4.21671 9.64645 4.02145Z"
                fill="#065C53"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.125 7.5C5.125 7.22386 5.34886 7 5.625 7H13.125C13.4011 7 13.625 7.22386 13.625 7.5C13.625 7.77614 13.4011 8 13.125 8H5.625C5.34886 8 5.125 7.77614 5.125 7.5Z"
                fill="#065C53"
              />
            </svg>
            Logg ut
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Header;
