import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectPage: React.FC<{ url: string }> = ({ url }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, []);

  return <div></div>;
};

export default RedirectPage;
