import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Collapse, SwipeableDrawer } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  useGetUserQuery,
  DogParent,
  useGetUserByEmailQuery,
} from "../api/graphql.gen";
import Button from "../components/Button";
import DogModalContent from "../components/Profile/DogModalContent";
import ProfileOurDogs from "../components/Profile/ProfileOurDogs";
import useIsVisible from "../hooks/useIsVisible";
import css from "../styles/Profile.module.scss";
import CreateUserDialog from "../components/CreateUserDialog";
import AgriaAgents from "../model/AgriaAgents";
import ClubData from "../model/ClubData";

interface RouteParams {
  userId: string;
}

const EmptyMessage = ({ label }: { label: string }) => (
  <div className={css.emptyMessage}>
    <div>{label}</div>
  </div>
);

const ProfileMobile: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<RouteParams>();
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userData } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const currentUser = userData?.getUserByEmail.id ?? -1;
  const id = userId ? parseInt(userId) : currentUser ? currentUser : -1;
  const { data: user } = useGetUserQuery({ id });
  const [isBreeder, setIsBreeder] = useState(false);

  useEffect(() => {
    setIsBreeder(!!user?.getUser.breeder);
  }, [user]);

  useEffect(() => {
    if (id) {
      window.analytics.page(`Profil - id: ${id}`);
    }
  }, []);

  const availableAd = user?.getUser.ads?.filter(
    (item) => item.adType === "AVAILABLE"
  );

  const myAds = user?.getUser.ads;

  const kennelInfo = useRef<HTMLDivElement>(null);

  const kennelIsVisible = useIsVisible(kennelInfo, "-140px");

  const [aboutVisible, setAboutVisible] = useState(true);
  const [adVisible, setAdVisible] = useState(false);
  const [agriaVisible, setAgriaVisible] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    "/images/default-avatar.png"
  );
  const [plannedVisible, setPlannedVisible] = useState(false);
  const [dogsVisible, setDogsVisible] = useState(false);
  const [puppyModal, setPuppyModal] = useState(false);
  const [dogModal, setDogModal] = useState(false);
  const [selectedDog, setSelectedDog] = useState<DogParent | DogParent[]>();
  const [showCreateUser, setShowCreateUser] = useState(false);
  const agentId = user?.getUser.kennel?.agriaAgentId;
  const myAgent = AgriaAgents.find((agent) => agent.id === agentId);

  useEffect(() => {
    setProfilePicture(
      user?.getUser.profilePicture ?? "/images/default-avatar.png"
    );
  }, [user]);

  useEffect(() => {
    if (!userId && !currentUser) {
      history.push("/");
    }
    if (user?.getUser.breeder === false) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (selectedDog !== undefined) {
      setDogModal(true);
    }
  }, [selectedDog, setSelectedDog]);

  return (
    <div className={css.BreedProfile}>
      <div className={css.bannerContainer}>
        {user?.getUser.kennel?.bannerImage ? (
          <div className={css.middleBanner}>
            <img src={user?.getUser.kennel?.bannerImage} />
          </div>
        ) : (
          <div className={css.noBanner} />
        )}
      </div>
      <div className={css.kennelContainer} ref={kennelInfo}>
        <div className={css.kennelHorizontalContainer}>
          <Avatar
            src={profilePicture}
            alt="Profilbilde"
            className={css.profilePic}
          />
          <div className={css.kennelInfo}>
            <div className={css.kennelName}>{user?.getUser.kennel?.name}</div>
            <div className={css.location}>
              {`${user?.getUser.address?.city}, Norge`}
            </div>
            <div className={css.breeds}>
              {user?.getUser.kennel?.breeds.map((breed) => (
                <div
                  key={breed}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/hunderaser/${breed.replaceAll(" ", "-")}`)
                  }
                >
                  {breed}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={css.contact}>
          {isBreeder && user?.getUser.email !== authUser?.email && (
            <Button
              label="Kontakt meg"
              skin="light"
              onClick={() =>
                isAuthenticated
                  ? history.push(`/kontakt/${userId}`)
                  : setShowCreateUser(true)
              }
            />
          )}
          {isBreeder &&
            user?.getUser.email !== authUser?.email &&
            !userData?.getUserByEmail.breeder && (
              <Button
                label="Send søknad"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/send-soknad/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            )}
        </div>
      </div>
      {!kennelIsVisible && (
        <div className={css.smallKennelContainer}>
          <div className={css.infoContainer}>
            <Avatar
              src={user?.getUser.profilePicture ?? "/images/default-avatar.png"}
              alt="Profilbilde"
              className={css.profilePic}
            />
            {isBreeder && user?.getUser.email !== authUser?.email && (
              <div
                className={css.chatContainer}
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/kontakt/${userId}`)
                    : setShowCreateUser(true)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.87706 23.2054C5.25651 22.9463 4.85242 22.3398 4.85242 21.6673V19.2835C3.08696 19.2439 1.66797 17.8004 1.66797 16.0254V6.66738C1.66797 4.82643 3.16035 3.33405 5.00129 3.33405L20.0013 3.33398C21.8422 3.33397 23.3346 4.82637 23.3346 6.66732V15.9509C23.3346 17.7919 21.8423 19.2843 20.0013 19.2843L11.2984 19.2843L7.69112 22.8523C7.21303 23.3251 6.49761 23.4644 5.87706 23.2054ZM6.51909 19.2099C6.51909 18.3305 5.80622 17.6177 4.92686 17.6177C4.0475 17.6177 3.33464 16.9048 3.33464 16.0254V6.66738C3.33464 5.74691 4.08082 5.00072 5.0013 5.00071L20.0013 5.00065C20.9218 5.00064 21.668 5.74684 21.668 6.66732V15.9509C21.668 16.8714 20.9218 17.6176 20.0013 17.6176L11.2984 17.6177C10.8595 17.6177 10.4384 17.7908 10.1264 18.0994L6.51909 21.6673V19.2099Z"
                    fill="#ABC6C2"
                  />
                </svg>
              </div>
            )}
            {isBreeder &&
              user?.getUser.email !== authUser?.email &&
              !userData?.getUserByEmail.breeder && (
                <Button
                  label="Send søknad"
                  onClick={() =>
                    isAuthenticated
                      ? history.push(`/send-soknad/${userId}`)
                      : setShowCreateUser(true)
                  }
                />
              )}
          </div>
        </div>
      )}
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setAboutVisible(!aboutVisible)}
        >
          <div>Om kennel</div>
          <img
            src={
              aboutVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={aboutVisible}
          timeout="auto"
          className={aboutVisible ? css.collapse : ""}
        >
          <div className={css.contentContainer}>
            <div className={css.textContainer}>
              <p>{`${user?.getUser.firstName} ${user?.getUser.lastName}`}</p>
              <p>{`${user?.getUser.kennel?.motivation}`}</p>
            </div>
            {(user?.getUser.kennel?.clubs?.length ||
              user?.getUser.agriaMember) && (
              <div className={css.textContainer}>
                <h6>Medlem av</h6>
                {user?.getUser.kennel?.clubs?.map((club) => (
                  <div
                    className={css.clubContainer}
                    key={club}
                    onClick={() => {
                      const hasClub = ClubData.find(
                        (item) => item.club.toLowerCase() === club.toLowerCase()
                      );
                      hasClub?.link ? window.open(hasClub?.link) : undefined;
                    }}
                  >
                    <Avatar
                      src={
                        ClubData.find(
                          (item) =>
                            item.club.toLowerCase() === club.toLowerCase()
                        )?.img ?? "/images/icons/club.svg"
                      }
                      className={css.avatar}
                      alt="klubb ikon"
                    />
                    <div>{club}</div>
                  </div>
                ))}
                {user.getUser.agriaMember && (
                  <div className={css.clubContainer}>
                    <Avatar
                      src="/images/agria-logo.png"
                      className={css.avatar}
                      alt="klubb ikon"
                    />
                    <div>Agria breeders club</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setAdVisible(!adVisible)}
        >
          <div>Tilgjengelige valper</div>
          <img
            src={
              adVisible ? "/images/icons/close.svg" : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={adVisible}
          timeout="auto"
          className={adVisible ? css.collapse : ""}
        >
          <div className={css.contentContainer}>
            {availableAd?.length ? (
              <div className={css.adGrid}>
                {availableAd?.map((ad) => (
                  <div
                    key={ad.id}
                    className={css.adCard}
                    onClick={() => history.push(`/annonse/${ad.id}`)}
                  >
                    <img
                      src={ad.adImage ?? "/images/placeholder-dog.png"}
                      alt="Annonsebilde"
                    />
                    <div className={css.title}>{ad.title}</div>
                    <div className={css.breed}>{ad.breed}</div>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyMessage label="Denne oppdretteren har ingen tilgjengelige kull" />
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setPlannedVisible(!plannedVisible)}
        >
          <div>Planlagte og kommende kull</div>
          <img
            src={
              plannedVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={plannedVisible}
          timeout="auto"
          className={plannedVisible ? css.collapse : ""}
        >
          <div className={css.plannedContainer}>
            {myAds?.filter(
              (item) => item.adType !== "AVAILABLE" && item.adType !== "DELETED"
            ).length ? (
              <div className={css.upcomingContainer}>
                {myAds
                  ?.filter((item) => item.adType === "PLANNED")
                  .map((ad) => (
                    <div key={ad.id}>
                      <div className={css.horizontalContainer}>
                        <div className={css.title}>Planlagte valper</div>
                        <div className={css.expected}>Forventet</div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.subTitle}>{ad?.breed}</div>
                        <div className={css.subTitle}>
                          {format(new Date(ad?.birthDate), "MMM yy")}
                        </div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.parents}>Mor</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "female"
                          )[0]?.NKKId ?? "N/A"}
                        </div>
                      </div>
                      <div
                        className={`${css.horizontalContainer} ${css.divider}`}
                      >
                        <div className={css.parents}>Far</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "male"
                          )[0]?.NKKId ?? "N/A"}
                        </div>
                      </div>
                    </div>
                  ))}
                {myAds
                  ?.filter((item) => item.adType === "COMING")
                  .map((ad) => (
                    <div key={ad.id}>
                      <div className={css.horizontalContainer}>
                        <div className={css.title}>Kommende valper</div>
                        <div className={css.expected}>Forventet</div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.subTitle}>{ad?.breed}</div>
                        <div className={css.subTitle}>
                          {format(new Date(ad?.birthDate), "MMM yy")}
                        </div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.parents}>Mor</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "female"
                          )[0]?.NKKId ?? "N/A"}
                        </div>
                      </div>
                      <div
                        className={`${css.horizontalContainer} ${css.divider}`}
                      >
                        <div className={css.parents}>Far</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "male"
                          )[0]?.NKKId ?? "N/A"}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <EmptyMessage label="Denne oppdretteren har ingen planlagte kull" />
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setDogsVisible(!dogsVisible)}
        >
          <div>Mine hunder</div>
          <img
            src={
              dogsVisible ? "/images/icons/close.svg" : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={dogsVisible}
          timeout="auto"
          className={dogsVisible ? css.collapse : ""}
        >
          <div className={css.contentContainer}>
            {user?.getUser.myDogs?.length ? (
              <ProfileOurDogs
                dogs={user.getUser.myDogs}
                onClick={(dog) => {
                  setSelectedDog(dog);
                  setDogModal(true);
                }}
              />
            ) : (
              <div>
                <h6>Mine hunder</h6>
                <EmptyMessage label="Denne oppdretteren har ikke lastet opp noen hunder" />
              </div>
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setAgriaVisible(!agriaVisible)}
        >
          <div>Mine agria forsikringsagent</div>
          <img
            src={
              agriaVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={agriaVisible}
          timeout="auto"
          className={agriaVisible ? css.collapse : ""}
        >
          {agentId && myAgent ? (
            <div className={css.contentContainer}>
              <div className={css.agentCard}>
                <Avatar
                  className={css.avatar}
                  src={myAgent.image}
                  alt="Bilde av agent"
                />
                <div>
                  <div className={css.name}>{myAgent.name}</div>
                  <div className={css.location}>
                    {myAgent.location + ", Norge"}
                  </div>
                  <Button
                    label="Besøk profil"
                    onClick={() => {
                      window.analytics.track("Besøk agria agents profil");
                      window.open(myAgent.web);
                    }}
                  />
                </div>
              </div>
              <img
                src="/images/agria.png"
                alt="Agra logo"
                className={css.agriaLogo}
              />
              <ul>
                <li>Personlig hjelp hvis du ønsker å kjøpe en forsikring</li>
                <li>Tilgjengelige også på kvelder og i helger</li>
                <li>Finnes i nærheten av deg</li>
              </ul>
            </div>
          ) : (
            <p>Denne oppdretteren har ikke en forsikringsagent</p>
          )}
        </Collapse>
      </div>
      {selectedDog && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={dogModal}
          onClose={() => {
            setDogModal(false);
            setSelectedDog(undefined);
          }}
          onOpen={() => setDogModal(true)}
          swipeAreaWidth={100}
        >
          {user?.getUser.myDogs?.length && selectedDog && (
            <DogModalContent dogData={selectedDog} />
          )}
        </SwipeableDrawer>
      )}
      <CreateUserDialog
        open={showCreateUser}
        onClose={() => setShowCreateUser(false)}
        description="Du må opprette bruker eller logge inn for å kontakte oppdretter"
        eventName="Registrer - Kontakt oppdretter"
      />
    </div>
  );
};

export default ProfileMobile;
