import styled from "@emotion/styled";
import { Box, Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import React, { useState } from "react";
import { DogAd, Puppy } from "../../api/graphql.gen";
import css from "../../styles/ProfileModalContent.module.scss";
import IncludedCard from "./IncludedCard";

interface ModalProps {
  adData: DogAd;
  puppies?: Puppy[];
}

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const PuppyModalContent: React.FC<ModalProps> = ({
  adData,
  puppies,
}: ModalProps) => {
  const [tab, setTab] = useState("1");

  return (
    <div className={css.ModalContent}>
      <div className={css.tabContainer}>
        <Puller />
        <Tabs value={tab} variant="fullWidth" className={css.tabs}>
          <Tab value="1" label="Om kullet" onClick={() => setTab("1")} />
          <Tab value="2" label="Valpeoversikt" onClick={() => setTab("2")} />
          <Tab value="3" label="Inkludert" onClick={() => setTab("3")} />
        </Tabs>
      </div>
      {tab === "1" && (
        <div className={css.tabContent}>
          <div className={css.puppyContainer}>
            {puppies?.map((puppy) => (
              <div key={puppy?.name} className={css.puppyCard}>
                <img
                  src={puppy.image ?? "/images/placeholder-dog.png"}
                  alt="Bilde av valp"
                  className={css.puppyImage}
                  style={{ height: 200, marginBottom: 10 }}
                />
                <div className={css.name}>{puppy?.name}</div>
                <div className={css.gender}>
                  {puppy.gender === "male"
                    ? "Hannhund"
                    : puppy.gender === "female"
                    ? "Tispe"
                    : ""}
                </div>
              </div>
            ))}
          </div>
          <div className={css.detailGrid}>
            <div>Født</div>
            <div>{format(new Date(adData?.birthDate), "dd.MM.yyyy")}</div>
            <div>Leveringsdato</div>
            <div>
              {format(new Date(adData?.readyForDelivery), "dd.MM.yyyy")}
            </div>
            <div>Pris</div>
            <div>{adData?.price}</div>
            <div>Antall</div>
            <div>{adData?.numberOfPuppies}</div>
            <div>Rase</div>
            <div>{adData?.breed}</div>
            <div>Mor</div>
            <div>
              {adData.parents?.filter((parent) => parent.gender === "female")[0]
                ?.name ?? ""}
            </div>
            <div>Far</div>
            <div>
              {adData.parents?.filter((parent) => parent.gender === "male")[0]
                ?.name ?? ""}
            </div>
          </div>
          <p>{adData?.breedCombination}</p>
        </div>
      )}
      {tab === "2" && (
        <div className={css.tabContent}>
          <div className={css.titleContainer}>
            <div className={css.title}>Tilgjengelig</div>
            <div className={css.available} />
          </div>
          {puppies
            ?.filter((item) => item.availability === "AVAILABLE")
            .map((puppy) => (
              <div key={puppy?.name} className={css.availableCard}>
                <img
                  src={puppy.image ?? "/images/placeholder-dog.png"}
                  alt="Bilde av valp"
                  className={css.roundImage}
                />
                <div className={css.infoContainer}>
                  <div>
                    <div className={css.name}>{puppy?.name}</div>
                    <div className={css.registration}>
                      {puppy?.registrationNumber}
                    </div>
                  </div>
                  <div className={css.properties}>
                    <div className={css.gender}>
                      {puppy.gender === "male"
                        ? "Hannhund"
                        : puppy.gender === "female"
                        ? "Tispe"
                        : ""}
                    </div>
                    <div className={css.color}>{puppy?.color}</div>
                  </div>
                </div>
              </div>
            ))}
          <div className={css.titleContainer}>
            <div className={css.title}>Reservert</div>
            <div className={css.reserved} />
          </div>
          {puppies
            ?.filter((item) => item.availability === "RESERVED")
            .map((puppy) => (
              <div key={puppy?.name} className={css.availableCard}>
                <img
                  src={puppy.image ?? "/images/placeholder-dog.png"}
                  alt="Bilde av valp"
                  className={css.roundImage}
                />
                <div className={css.infoContainer}>
                  <div>
                    <div className={css.name}>{puppy?.name}</div>
                    <div className={css.registration}>
                      {puppy?.registrationNumber}
                    </div>
                  </div>
                  <div className={css.properties}>
                    <div className={css.gender}>
                      {puppy.gender === "male"
                        ? "Hannhund"
                        : puppy.gender === "female"
                        ? "Tispe"
                        : ""}
                    </div>
                    <div className={css.color}>{puppy?.color}</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      {tab === "3" && (
        <div className={css.tabContent}>
          <div className={css.includedGrid}>
            {adData.pedigree && (
              <IncludedCard
                title="Stamtavle"
                imgSrc="/images/icons/profile/tree.svg"
              />
            )}
            {adData.advising && (
              <IncludedCard
                title="Rådgivning"
                imgSrc="/images/icons/profile/chat.svg"
              />
            )}
            {adData.id && (
              <IncludedCard
                title="ID-Merking"
                imgSrc="/images/icons/profile/id.svg"
              />
            )}
            {adData.vaccinated && (
              <IncludedCard
                title="Vaksine"
                imgSrc="/images/icons/profile/vax.svg"
              />
            )}
            {adData.insurance && (
              <IncludedCard
                title="Forsikring"
                imgSrc="/images/icons/profile/health.svg"
              />
            )}
            {adData.wormTreatment && (
              <IncludedCard
                title="Ormekur"
                imgSrc="/images/icons/profile/worm.svg"
              />
            )}
            {adData.healthCertificate && (
              <IncludedCard
                title="Helsebok"
                imgSrc="/images/icons/profile/health.svg"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PuppyModalContent;
