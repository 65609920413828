import { useAuth0 } from "@auth0/auth0-react";
import { Tabs, Tab } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useDeactivateAdMutation,
  useGetMyAdsQuery,
  useUpdateAdStatusMutation,
} from "../../api/graphql.gen";
import Button from "../../components/Button";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import MyPuppiesCard from "../../components/Dashboard/MyPuppiesCard";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/MyPuppies.module.scss";
import Footer from "./Footer";

const MyPuppies: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [tab, setTab] = useState("1");
  const [selectedAd, setSelectedAd] = useState(0);
  const { isAuthenticated } = useAuth0();
  const { data, refetch } = useGetMyAdsQuery();
  const history = useHistory();
  const { mutate: deactivate } = useDeactivateAdMutation({
    onSuccess: () => {
      refetch();
      setShowDelete(false);
    },
  });

  const { mutate: updateStatus } = useUpdateAdStatusMutation({
    onSuccess: (result) => {
      history.push(
        `/min-pond/mine-valper/rediger/${result.updateAdStatus.id}/${
          result.updateAdStatus.adType === "PLANNED" ? "kommende" : "fodt"
        }`
      );
    },
  });

  useEffect(() => {
    window.analytics.page("Mine valper");
  }, []);

  useEffect(() => {
    window.analytics.page("Mine valper");
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const [showDelete, setShowDelete] = useState(false);

  const deleteAd = (id: number) => {
    deactivate({ adId: id });
  };

  const activeAds = data?.getMyAds.filter((item) => item.adType !== "DELETED");

  const deletedAds = data?.getMyAds.filter((item) => item.adType === "DELETED");

  const renderTabs = () => (
    <div>
      {tab === "1" ? (
        <div>
          {data?.getMyAds.length ? (
            <div className={css.puppiesContainer}>
              {activeAds?.map((ad) => (
                <MyPuppiesCard
                  key={ad.title}
                  title={ad.title}
                  birthDate={format(new Date(ad.birthDate), "dd-MM-yyyy")}
                  imgSrc={ad.adImage ?? "/images/placeholder-dog.png"}
                  imgAlt="Annonse bilde"
                  soldButton
                  status={ad.adType}
                  changeType={() =>
                    updateStatus({ status: ad.adType, adId: ad.id })
                  }
                  onClick={() =>
                    history.push(
                      `/min-pond/mine-valper/rediger/${ad.id}/${
                        ad.adType === "AVAILABLE"
                          ? "fodt"
                          : ad.adType === "PLANNED"
                          ? "planlagt"
                          : "kommer"
                      }`
                    )
                  }
                  onDelete={() => {
                    setSelectedAd(ad.id);
                    setShowDelete(true);
                  }}
                />
              ))}
              <Button
                label="Legg til"
                skin="lightGreen"
                onClick={() => history.push("/nytt-valpekull")}
              />
            </div>
          ) : (
            <div className={css.noPuppies}>
              <p>Du har ingen registrerte valpekull </p>
              <Button
                label="Legg til"
                skin="lightGreen"
                onClick={() => history.push("/nytt-valpekull")}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {data?.getMyAds.length ? (
            <div className={css.puppiesContainer}>
              {deletedAds?.map((ad) => (
                <MyPuppiesCard
                  key={ad.title}
                  title={ad.title}
                  birthDate={format(new Date(ad.birthDate), "dd-MM-yyyy")}
                  imgSrc={ad.adImage ?? "/images/placeholder-dog.png"}
                  imgAlt="Annonse bilde"
                  onDelete={() => {
                    setSelectedAd(ad.id);
                    setShowDelete(true);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className={css.noPuppies}>
              <p>Du har ingen tidligere valpekull </p>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className={css.MyPuppies}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Mine valper" next="Avlshunder >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/dog-face-circle.svg"
          percentage={60}
          title="Mine valper"
          info="Her kan du legge til valpekull. Du kan legge til fødte, kommende og planlagte, og administrere tidligere kull."
        />
        <Tabs
          value={tab}
          onChange={(_event, value) => setTab(value)}
          variant="fullWidth"
        >
          <Tab value="1" label="Aktive" />
          <Tab value="2" label="Slettet" />
        </Tabs>
        {renderTabs()}
      </div>
      <Footer />
      <ConfirmationAlert
        title="Sletter annonse"
        content="Er du sikker på at du vil slette annonsen?"
        open={showDelete}
        handleCancel={() => setShowDelete(false)}
        handleClose={() => setShowDelete(false)}
        handleConfirm={() => deleteAd(selectedAd)}
      />
    </div>
  );
};

export default MyPuppies;
