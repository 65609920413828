import React from "react";
import css from "../../styles/MyDogsCard.module.scss";
import Button from "../Button";
import { useHistory } from "react-router";

export interface CardProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  birthDate: string;
  onClick?: () => void;
  onDelete?: () => void;
  changeType?: () => void;
  status?: string;
  soldButton?: boolean;
}

const MyPuppiesCard: React.FC<CardProps> = ({
  imgSrc,
  imgAlt,
  title,
  birthDate,
  onClick,
  soldButton,
  onDelete,
  changeType,
  status,
}: CardProps) => {
  const history = useHistory();
  return (
    <div className={css.card}>
      <div className={css.container}>
        <img
          src={imgSrc}
          alt={imgAlt}
          className={css.image}
          onError={(e) => {
            e.currentTarget.src = "/images/breeds/pond.png";
          }}
        />
        <div className={css.innerTopContainer}>
          <h5 className={css.title}>{title}</h5>
          <h6 className={css.nkkNumber}>Født {birthDate}</h6>
        </div>
        <div className={css.innerBottomContainer}>
          <div>
            {soldButton && status !== "AVAILABLE" && (
              <Button
                skin="dark"
                label={
                  status === "PLANNED" ? "Endre til kommende" : "Endre til født"
                }
                className={css.button}
                onClick={changeType}
              />
            )}
            {soldButton && (
              <Button
                skin="dark"
                label="Rediger"
                className={css.button}
                onClick={onClick}
              />
            )}
            {soldButton && (
              <Button
                skin="dark"
                label="Se annonse"
                className={css.button}
                onClick={() => history.push("/profil")}
              />
            )}
            {soldButton && (
              <Button
                skin="error"
                variant=""
                label="Marker som solgt"
                className={css.button}
                onClick={onDelete}
              />
            )}
          </div>
        </div>
      </div>
      <span className={css.border}></span>
    </div>
  );
};

export default MyPuppiesCard;
