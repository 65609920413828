import React from "react";
import { PortableText } from "@portabletext/react";
import Button from "../Button";
import css from "../../styles/sanity-styles/TextWithIllustration.module.scss";
import { Section, urlFor } from "../../utils/sanity-common";
import { useIsMobile } from "../../hooks/useIsMobile";

interface TextWithIllustrationProps {
  section: Section;
}

const TextWithIllustration: React.FC<TextWithIllustrationProps> = ({
  section,
}: TextWithIllustrationProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={css.textWithIllustration}
      style={{ background: `${section.backgroundColor}` }}
    >
      <div
        className={css.textWithIllustrationContent}
        style={{
          flexDirection:
            !isMobile && section.desktopImagePos === true
              ? "row-reverse"
              : isMobile && section.phoneImagePos === true
              ? "column-reverse"
              : isMobile &&
                (section.phoneImagePos === false || section.phoneImagePos != "")
              ? "column"
              : "row",
        }}
      >
        <div>
          <div className={css.textWithIllustrationContentText}>
            <PortableText value={section?.excerpt} />
          </div>
          <div className={css.textWithIllustrationButtons}>
            {section.callToAction?.[0] && (
              <Button
                onClick={() =>
                  (window.location.href = section.callToAction?.[0].link)
                }
                label={section.callToAction?.[0].buttonLabel}
                style={{ marginTop: "3rem" }}
                skin={section.callToAction?.[0].buttonColor}
              />
            )}
            {section.callToAction?.[1] && (
              <Button
                onClick={() =>
                  (window.location.href = section.callToAction?.[1].link)
                }
                label={section.callToAction?.[1].buttonLabel}
                skin={section.callToAction?.[1].buttonColor}
                style={{ marginTop: "1rem" }}
              />
            )}
          </div>
        </div>
        <div className={css.textWithIllustrationImage}>
          {isMobile && section.phoneImage ? (
            <img
              className={css.desktopImage}
              src={urlFor(section.phoneImage?.asset._ref).url()}
              alt={section.phoneImage?.alt}
            />
          ) : section.desktopImage ? (
            <img
              className={css.desktopImage}
              src={urlFor(section.desktopImage?.asset._ref).url()}
              alt={section.desktopImage?.alt}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TextWithIllustration;
