/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import css from "../styles/Article.module.scss";
import { client } from "../index";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import { Avatar } from "@mui/material";
import Button from "../components/Button";
import { useHistory, useParams } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";

interface RouteParams {
  slug: string;
}

const Article: React.FC = () => {
  const { slug } = useParams<RouteParams>();

  const [articles, setArtciles] = useState<any>();

  async function getPosts() {
    const sanityArticles = await client.fetch(`*[slug.current == "${slug}"]`);
    setArtciles(sanityArticles);
  }

  const builder = imageUrlBuilder(client);
  function urlFor(source: any) {
    return builder.image(source.asset);
  }

  const history = useHistory();

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    getPosts();
  }, []);

  if (articles) {
    return (
      <div className={css.article}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{articles[0].title ?? "POND"}</title>
          <meta property="og:title" content={articles[0].title}></meta>
          <meta property="og:description" content={articles[0].content1}></meta>
          <meta
            property="og:image"
            content={urlFor(articles[0]?.coverImage).url()}
          ></meta>
          <meta property="og:type" content="article"></meta>
          <meta
            property="og:url"
            content={`http://pond.no/artikler/${slug}`}
          ></meta>
        </Helmet>
        <div
          className={css.backButton}
          onClick={() => history.push("/artikler")}
        >
          <ArrowBackIos /> Tilbake til oversikt
        </div>
        <div className={css.blockContainer}>
          <PortableText value={articles[0].intro} />
        </div>
        <img
          src={urlFor(articles[0]?.coverImage).url()}
          alt="Banner bilde"
          className={css.bannerImage}
        />
        {isAuthenticated ? (
          <span>
            {articles[0].content1 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content1} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image1 && (
                <img
                  src={urlFor(articles[0]?.image1).url()}
                  alt="Bilde 1"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image2 && (
                <img
                  src={urlFor(articles[0]?.image2).url()}
                  alt="Bilde 2"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content2 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content2} />
              </div>
            )}
            {articles[0].content3 && (
              <div className={css.quote}>
                <PortableText value={articles[0].content3} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image3 && (
                <img
                  src={urlFor(articles[0]?.image3).url()}
                  alt="Bilde 3"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image4 && (
                <img
                  src={urlFor(articles[0]?.image4).url()}
                  alt="Bilde 4"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content4 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content4} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image5 && (
                <img
                  src={urlFor(articles[0]?.image5).url()}
                  alt="Bilde 5"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image6 && (
                <img
                  src={urlFor(articles[0]?.image6).url()}
                  alt="Bilde 6"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content5 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content5} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image7 && (
                <img
                  src={urlFor(articles[0]?.image7).url()}
                  alt="Bilde 7"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image8 && (
                <img
                  src={urlFor(articles[0]?.image8).url()}
                  alt="Bilde 8"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content6 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content6} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image9 && (
                <img
                  src={urlFor(articles[0]?.image9).url()}
                  alt="Bilde 9"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image10 && (
                <img
                  src={urlFor(articles[0]?.image10).url()}
                  alt="Bilde 10"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content7 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content7} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image11 && (
                <img
                  src={urlFor(articles[0]?.image11).url()}
                  alt="Bilde 11"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image12 && (
                <img
                  src={urlFor(articles[0]?.image12).url()}
                  alt="Bilde 12"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content8 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content8} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image13 && (
                <img
                  src={urlFor(articles[0]?.image13).url()}
                  alt="Bilde 13"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image14 && (
                <img
                  src={urlFor(articles[0]?.image14).url()}
                  alt="Bilde 14"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content9 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content9} />
              </div>
            )}
            <div className={css.imageContainer}>
              {articles[0]?.image15 && (
                <img
                  src={urlFor(articles[0]?.image15).url()}
                  alt="Bilde 15"
                  className={css.bannerImage}
                />
              )}
              {articles[0]?.image16 && (
                <img
                  src={urlFor(articles[0]?.image16).url()}
                  alt="Bilde 16"
                  className={css.bannerImage}
                />
              )}
            </div>
            {articles[0].content10 && (
              <div className={css.blockContainer}>
                <PortableText value={articles[0].content10} />
              </div>
            )}
            {articles[0]?.endPic && (
              <img
                src={urlFor(articles[0]?.endPic).url()}
                alt="Bilde 16"
                className={css.bannerImage}
              />
            )}
            <div className={css.profileSection}>
              <div className={css.content}>
                {articles[0]?.profilePic && (
                  <Avatar
                    src={urlFor(articles[0]?.profilePic).url()}
                    sx={{ height: 236, width: 236 }}
                  />
                )}
                <div className={css.profileInfo}>
                  <PortableText value={articles[0].profileInfo} />
                  <Button
                    label="Besøk profil"
                    className={css.button}
                    onClick={() => history.push(articles[0]?.breederUrl)}
                  />
                </div>
              </div>
            </div>
          </span>
        ) : (
          <div className={css.notLoggedIn}>
            <p>Logg inn eller opprett bruker for å lese resten av artikkelen</p>
            <Button
              label="Opprett bruker"
              onClick={() => loginWithRedirect()}
            />
          </div>
        )}
      </div>
    );
  }
  return <></>;
};

export default Article;
