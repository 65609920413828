import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DogAd, DogParent, Puppy, useGetAdQuery } from "../api/graphql.gen";
import css from "../styles/Ad.module.scss";
import IncludedCard from "../components/Profile/IncludedCard";
import {
  Avatar,
  Chip,
  Collapse,
  SwipeableDrawer,
  Tab,
  Tabs,
} from "@mui/material";
import ClubData from "../model/ClubData";
import LoadingScreen from "../components/LoadingScreen";
import Modal from "react-bootstrap/Modal";
import {
  translateHD,
  translateAD,
  translatePatella,
} from "../utils/healthTranslations";
import { useIsMobile } from "../hooks/useIsMobile";
import { format } from "date-fns";
import PuppyModalContent from "../components/Profile/PuppyModalContent";
import DogModalContent from "../components/Profile/DogModalContent";
import Button from "../components/Button";
import { useAuth0 } from "@auth0/auth0-react";
import CreateUserDialog from "../components/CreateUserDialog";

interface RouteParams {
  adId: string;
}
const Ad: React.FC = () => {
  const { adId } = useParams<RouteParams>();

  const { data, isLoading } = useGetAdQuery({ adId: Number(adId) });
  const ad = data?.getAd;
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const { isAuthenticated } = useAuth0();
  const [adVisible, setAdVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [parentVisible, setParentVisible] = useState(false);
  const [puppyVisible, setPuppyVisible] = useState(false);
  const [kennelVisible, setKennelVisible] = useState(false);
  const [includedVisible, setIncludedVisible] = useState(false);
  const [parentModal, setParentModal] = useState(false);
  const [tab, setTab] = useState("1");
  const [puppyModal, setPuppyModal] = useState(false);
  const [dogModal, setDogModal] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);

  const adRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const puppiesRef = useRef<HTMLDivElement>(null);
  const breederRef = useRef<HTMLDivElement>(null);
  const parentsRef = useRef<HTMLDivElement>(null);
  const includedRef = useRef<HTMLDivElement>(null);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  useEffect(() => {
    if (!isLoading && ad?.adType !== "DELETED" && ad?.adType !== "AVAILABLE") {
      history.push(`/oppdretter/${ad?.user.id}`);
    }
  }, [ad, isLoading]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isMobile) {
    return (
      <div className={css.mobile}>
        <div className={css.section} ref={adRef}>
          <div className={css.adCard}>
            {ad?.adType === "DELETED" && (
              <Chip
                label="Solgt/Inaktiv"
                color="error"
                variant="outlined"
                sx={{ marginBottom: 1 }}
              />
            )}
            <div>
              {ad?.adImage && <img src={ad.adImage} alt="Annonsebilde" />}
            </div>
            <div className={css.flex}>
              <div>
                <div>
                  <div className={css.title}>{ad?.title}</div>
                  <div className={css.breed}>{ad?.breed}</div>
                </div>
              </div>
              <div className={css.titleAndButton}>
                <div className={css.title}>{ad?.price} kr</div>
                <Button
                  label="Send søknad"
                  onClick={() =>
                    isAuthenticated
                      ? history.push(`/send-soknad/${ad?.user?.id}`)
                      : setShowCreateUser(true)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setAdVisible(!adVisible)}
          >
            <div>Info</div>
            <img
              src={
                adVisible ? "/images/icons/close.svg" : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={adVisible}
            timeout="auto"
            className={adVisible ? css.collapse : ""}
          >
            <div>
              <p>
                <b>Antall valper:</b> {ad?.puppies?.length}
              </p>
              <p>
                <b>Født:</b> {format(new Date(ad?.birthDate), "dd.MM.yyyy")}
              </p>
              <p>
                <b>Leveringsklar:</b>{" "}
                {format(new Date(ad?.readyForDelivery), "dd.MM.yyyy")}
              </p>
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setAboutVisible(!aboutVisible)}
          >
            <div>Om kullet</div>
            <img
              src={
                aboutVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={aboutVisible}
            timeout="auto"
            className={aboutVisible ? css.collapse : ""}
          >
            <div>
              <p>{ad?.expLitter}</p>
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setPuppyVisible(!puppyVisible)}
          >
            <div>Valper</div>
            <img
              src={
                puppyVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={puppyVisible}
            timeout="auto"
            className={puppyVisible ? css.collapse : ""}
          >
            <div className={css.puppyGrid}>
              {ad?.puppies?.map((puppy, index) => (
                <div key={puppy.id} onClick={() => setPuppyModal(true)}>
                  <img
                    src={
                      puppy.image.length
                        ? puppy.image
                        : "/images/placeholder-dog.png"
                    }
                    alt="Bilde av valp"
                  />
                  <div className={css.title}>
                    {puppy.name.length ? puppy.name : `Valp ${index + 1}`}
                  </div>
                  <div className={css.breed}>
                    {puppy.gender === "male" ? "Hannhund" : "Tispe"}
                  </div>
                  <div className={css.breed}>
                    {puppy.color.length ? puppy.color : "Farge ikke oppgitt"}
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setParentVisible(!parentVisible)}
          >
            <div>Foreldre</div>
            <img
              src={
                parentVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={parentVisible}
            timeout="auto"
            className={parentVisible ? css.collapse : ""}
          >
            <div className={css.puppyGrid}>
              {ad?.parents?.map((parent) => (
                <div key={parent.id} onClick={() => setDogModal(true)}>
                  <img
                    src={
                      parent.images?.length
                        ? parent.images[0]
                        : "/images/placeholder-dog.png"
                    }
                    alt="Bilde av forelder"
                  />
                  <div className={css.title}>{parent.name}</div>
                  <div className={css.breed}>
                    {parent.gender === "male" ? "Far" : "Mor"}
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setIncludedVisible(!includedVisible)}
          >
            <div>Inkludert med valpen</div>
            <img
              src={
                includedVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={includedVisible}
            timeout="auto"
            className={includedVisible ? css.collapse : ""}
          >
            <div className={css.includedGrid}>
              {ad?.pedigree && (
                <IncludedCard
                  title="Stamtavle"
                  imgSrc="/images/icons/profile/tree.svg"
                />
              )}
              {ad?.advising && (
                <IncludedCard
                  title="Rådgivning"
                  imgSrc="/images/icons/profile/chat.svg"
                />
              )}
              {ad?.idTag && (
                <IncludedCard
                  title="ID-Merking"
                  imgSrc="/images/icons/profile/id.svg"
                />
              )}
              {ad?.vaccinated && (
                <IncludedCard
                  title="Vaksine"
                  imgSrc="/images/icons/profile/vax.svg"
                />
              )}
              {ad?.insurance && (
                <IncludedCard
                  title="Forsikring"
                  imgSrc="/images/icons/profile/health.svg"
                />
              )}
              {ad?.wormTreatment && (
                <IncludedCard
                  title="Ormekur"
                  imgSrc="/images/icons/profile/worm.svg"
                />
              )}
              {ad?.healthCertificate && (
                <IncludedCard
                  title="Helsebok"
                  imgSrc="/images/icons/profile/health.svg"
                />
              )}
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setKennelVisible(!kennelVisible)}
          >
            <div>Bli kjent med kennel</div>
            <img
              src={
                kennelVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={kennelVisible}
            timeout="auto"
            className={kennelVisible ? css.collapse : ""}
          >
            <div>
              <div
                className={css.personInfo}
                onClick={() => history.push(`/oppdretter/${ad?.user.id}`)}
              >
                <Avatar
                  className={css.avatar}
                  src={ad?.user.profilePicture ?? "/images/default-avatar.png"}
                />
                <div>
                  <div className={css.name}>
                    {ad?.user.firstName + " " + ad?.user.lastName}
                  </div>
                  <div className={css.city}>
                    {ad?.user.address?.city}, Norge
                  </div>
                </div>
              </div>
              <p>{ad?.user.kennel?.experience}</p>
              <div className={css.textContainer}>
                <h5>Medlem av</h5>
                <div className={css.clubGrid}>
                  {ad?.user.kennel?.clubs?.map((club) => (
                    <div
                      className={css.clubContainer}
                      key={club}
                      onClick={() => {
                        const hasClub = ClubData.find(
                          (item) =>
                            item.club.toLowerCase() === club.toLowerCase()
                        );
                        hasClub?.link ? window.open(hasClub?.link) : undefined;
                      }}
                    >
                      <Avatar
                        className={css.avatar}
                        src={
                          ClubData.find(
                            (item) =>
                              item.club.toLowerCase() === club.toLowerCase()
                          )?.img ?? "/images/icons/club.svg"
                        }
                        alt="klubb ikon"
                      />
                      <div>{club}</div>
                    </div>
                  ))}
                  {ad?.user.agriaMember && (
                    <div className={css.clubContainer}>
                      <Avatar
                        src="/images/agria-logo.png"
                        className={css.avatar}
                        alt="klubb ikon"
                      />
                      <div>Agria breeders club</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <SwipeableDrawer
          anchor={"bottom"}
          open={puppyModal}
          onClose={() => setPuppyModal(false)}
          onOpen={() => setPuppyModal(true)}
          swipeAreaWidth={100}
          sx={{ height: "calc(100% - 150px)", top: 150 }}
        >
          {ad && (
            <PuppyModalContent
              adData={ad as DogAd}
              puppies={ad?.puppies as Puppy[]}
            />
          )}
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor={"bottom"}
          open={dogModal}
          onClose={() => {
            setDogModal(false);
          }}
          onOpen={() => setDogModal(true)}
          swipeAreaWidth={100}
        >
          {ad?.parents && (
            <DogModalContent dogData={ad?.parents as DogParent[]} />
          )}
        </SwipeableDrawer>
      </div>
    );
  }

  return (
    <div className={css.Ad}>
      <div className={css.sideBar}>
        <div className={css.menu}>
          <div onClick={() => executeScroll(adRef)}>Info</div>
          <div onClick={() => executeScroll(aboutRef)}>Om kullet</div>
          <div onClick={() => executeScroll(puppiesRef)}>Valpene</div>
          <div onClick={() => executeScroll(parentsRef)}>Foreldre</div>
          <div onClick={() => executeScroll(includedRef)}>Inkludert</div>
          <div onClick={() => executeScroll(breederRef)}>Kennel</div>
        </div>
      </div>
      <div className={css.content}>
        <div className={css.section} ref={adRef}>
          <div className={css.adCard}>
            {ad?.adType === "DELETED" && (
              <Chip
                label="Solgt/Inaktiv"
                color="error"
                variant="outlined"
                sx={{ marginBottom: 1 }}
              />
            )}
            <div>
              {ad?.adImage && <img src={ad.adImage} alt="Annonsebilde" />}
            </div>
            <div className={css.sectionTop}>
              <div>
                <div className={css.title}>
                  {ad?.title} - {ad?.price} kr
                </div>
                <div className={css.breed}>{ad?.breed}</div>
              </div>
              <div>
                <Button
                  label="Send søknad"
                  onClick={() =>
                    isAuthenticated
                      ? history.push(`/send-soknad/${ad?.user?.id}`)
                      : setShowCreateUser(true)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={css.section} ref={aboutRef}>
          <h6>Om kullet</h6>
          <p>{ad?.expLitter}</p>
        </div>
        <div className={css.section} ref={puppiesRef}>
          <h6>Valper</h6>
          <div className={css.puppyGrid}>
            {ad?.puppies?.map((puppy, index) => (
              <div key={puppy.id}>
                <img
                  src={
                    puppy.image.length
                      ? puppy.image
                      : "/images/placeholder-dog.png"
                  }
                  alt="Bilde av valp"
                />
                <div className={css.title}>
                  {puppy.name.length ? puppy.name : `Valp ${index + 1}`}
                </div>
                <div className={css.breed}>
                  {puppy.gender === "male" ? "Hannhund" : "Tispe"}
                </div>
                <div className={css.breed}>
                  {puppy.color.length ? puppy.color : "Farge ikke oppgitt"}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={css.section} ref={parentsRef}>
          <h6>Foreldre</h6>
          <div className={css.puppyGrid}>
            {ad?.parents?.map((parent) => (
              <div key={parent.id} onClick={() => setParentModal(true)}>
                <img
                  src={
                    parent.images?.length
                      ? parent.images[0]
                      : "/images/placeholder-dog.png"
                  }
                  alt="Bilde av forelder"
                />
                <div className={css.title}>{parent.name}</div>
                <div className={css.breed}>
                  {parent.gender === "male" ? "Far" : "Mor"}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={css.section} ref={includedRef}>
          <h6>Inkludert med valpen</h6>
          <div className={css.includedGrid}>
            {ad?.pedigree && (
              <IncludedCard
                title="Stamtavle"
                imgSrc="/images/icons/profile/tree.svg"
              />
            )}
            {ad?.advising && (
              <IncludedCard
                title="Rådgivning"
                imgSrc="/images/icons/profile/chat.svg"
              />
            )}
            {ad?.idTag && (
              <IncludedCard
                title="ID-Merking"
                imgSrc="/images/icons/profile/id.svg"
              />
            )}
            {ad?.vaccinated && (
              <IncludedCard
                title="Vaksine"
                imgSrc="/images/icons/profile/vax.svg"
              />
            )}
            {ad?.insurance && (
              <IncludedCard
                title="Forsikring"
                imgSrc="/images/icons/profile/health.svg"
              />
            )}
            {ad?.wormTreatment && (
              <IncludedCard
                title="Ormekur"
                imgSrc="/images/icons/profile/worm.svg"
              />
            )}
            {ad?.healthCertificate && (
              <IncludedCard
                title="Helsebok"
                imgSrc="/images/icons/profile/health.svg"
              />
            )}
          </div>
        </div>
        <div className={`${css.section} ${css.noBorder}`} ref={breederRef}>
          <h6>Bli kjent med kennel</h6>
          <div
            className={css.personInfo}
            onClick={() => history.push(`/oppdretter/${ad?.user.id}`)}
          >
            <Avatar
              className={css.avatar}
              src={ad?.user.profilePicture ?? "/images/default-avatar.png"}
            />
            <div>
              <div className={css.name}>
                {ad?.user.firstName + " " + ad?.user.lastName}
              </div>
              <div className={css.city}>{ad?.user.address?.city}, Norge</div>
            </div>
          </div>
          <p>{ad?.user.kennel?.experience}</p>
          <div className={css.textContainer}>
            <h5>Medlem av</h5>
            <div className={css.clubGrid}>
              {ad?.user.kennel?.clubs?.map((club) => (
                <div
                  className={css.clubContainer}
                  key={club}
                  onClick={() => {
                    const hasClub = ClubData.find(
                      (item) => item.club.toLowerCase() === club.toLowerCase()
                    );
                    hasClub?.link ? window.open(hasClub?.link) : undefined;
                  }}
                >
                  <Avatar
                    className={css.avatar}
                    src={
                      ClubData.find(
                        (item) => item.club.toLowerCase() === club.toLowerCase()
                      )?.img ?? "/images/icons/club.svg"
                    }
                    alt="klubb ikon"
                  />
                  <div>{club}</div>
                </div>
              ))}
              {ad?.user.agriaMember && (
                <div className={css.clubContainer}>
                  <Avatar
                    src="/images/agria-logo.png"
                    className={css.avatar}
                    alt="klubb ikon"
                  />
                  <div>Agria breeders club</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={parentModal}
        onHide={() => setParentModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Tabs value={tab} variant="fullWidth" className={css.tabs}>
              <Tab value="1" label="Om hunden" onClick={() => setTab("1")} />
              <Tab value="2" label="Helse" onClick={() => setTab("2")} />
              <Tab value="3" label="Championater" onClick={() => setTab("3")} />
            </Tabs>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={css.parentModalContainer}>
            <div className={css.background} />
            {ad?.parents?.map((parent) => (
              <div key={parent.id} className={css.parentContainer}>
                <div className={css.gender}>
                  {parent.gender === "male" ? "Far" : "Mor"}
                </div>
                <img
                  src={
                    parent.images?.length
                      ? parent.images[0]
                      : "/images/placeholder-dog.png"
                  }
                  alt="Bilde av forelder"
                />
                <div className={css.name}>{parent.name}</div>
                {tab === "1" && (
                  <div className={css.details}>
                    <div className={css.detailsGrid}>
                      <div>Rase</div>
                      <div>{parent.breed}</div>
                      <div>Reg nummer</div>
                      <div>{parent.NKKId}</div>
                      <div>Farge</div>
                      <div>{parent.color}</div>
                      <div>Vekt</div>
                      <div>{parent.weight}</div>
                      <div>Eid av</div>
                      <div>{parent.isOwner ? "" : parent.ownersName}</div>
                    </div>
                    <div className={css.aboutGender}>{`Om ${
                      parent.gender === "male" ? "far" : "mor"
                    }`}</div>
                    <p className={css.aboutContent}>{parent.description}</p>
                  </div>
                )}
                {tab == "2" && (
                  <div className={css.health}>
                    <div className={css.title}>Vaksiner</div>
                    <div className={css.detailsGrid}>
                      <div>Ormekur</div>
                      <div>{parent.wormTreatment ? "Tatt" : "-"}</div>
                      <div>DHP</div>
                      <div>{parent.dhp ? "Tatt" : "-"}</div>
                      <div>PiBbPi</div>
                      <div>{parent.pibbpi ? "Tatt" : "-"}</div>
                      <div>Rabies</div>
                      <div>{parent.rabies ? "Tatt" : "-"}</div>
                    </div>
                    <div className={css.title}>Helsetester</div>
                    <div className={css.detailsGrid}>
                      <div>HD</div>
                      <div>{`Høyre: ${translateHD(
                        parent.HDRight ?? -10
                      )}, Venstre: ${translateHD(parent.HDLeft ?? -10)}`}</div>
                      <div>AD</div>
                      <div>{`Høyre: ${translateAD(
                        parent.ADRight ?? -10
                      )}, Venstre: ${translateAD(parent.ADLeft ?? -10)}`}</div>
                      <div>Patella</div>
                      <div>{`Høyre: ${translatePatella(
                        parent.PatellaRight ?? -10
                      )}, Venstre: ${translatePatella(
                        parent.PatellaLeft ?? -10
                      )}`}</div>
                    </div>
                    <div className={css.title}>Dokumentasjon</div>
                    <div className={css.detailsGrid}>
                      <div>Veterinærattest</div>
                      <div></div>
                      <div>Øyelysning</div>
                      <div></div>
                      <div>DNA-test</div>
                      <div>-</div>
                      <div>MentalTest</div>
                      <div>-</div>
                    </div>
                    <p>
                      POND anbefaler kjøpere om å undersøke dokumentasjonen
                      over, dette kan gjøres via dogweb eller ved å spørre
                      oppdretteren.
                    </p>
                  </div>
                )}
                {tab == "3" && (
                  <div>
                    {parent.championTitle?.map((title) => (
                      <div key={title} className={css.championContainer}>
                        <img src="/images/icons/award.svg" alt="Prisikon" />
                        <p>{title}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <CreateUserDialog
        open={showCreateUser}
        description="Du må opprette bruker eller logge inn for å kontakte oppdretter"
        eventName="Registrer - Kontakt oppdretter"
        onClose={() => setShowCreateUser(false)}
      />
    </div>
  );
};

export default Ad;
