export const champions = [
  "Agility Hopp klasse 1",
  "Agility Hopp klasse 2",
  "Agility Hopp klasse 3",
  "Agility klasse 1",
  "Agility klasse 2",
  "Agility klasse 3",
  "Freestyle klasse 1",
  "Freestyle klasse 2",
  "Heelwork to music klasse 1",
  "Lydighet klasse 1",
  "Lydighet klasse 2",
  "Lydighet klasse 3",
  "Nordic Utstilling Champion",
  "Nordisk Agility hopp Champion",
  "Nordisk Jakt Champion (drev)",
  "Nordisk Jaktchampion",
  "Nordisk Utstilling Champion",
  "Nordisk Viltspor Champion",
  "Norsk Agility  Champion",
  "Norsk Agility  Hopp Champion",
  "Norsk Blodspor Champion",
  "Norsk Bruks  Champion",
  "Norsk Champion",
  "Norsk Heelwork to Music Champion",
  "Norsk Jakt  Champion",
  "Norsk Jakt Champion (Bandhund)",
  "Norsk Jakt Champion (Drev)",
  "Norsk Jakt Champion (Kombinert)",
  "Norsk Jakt Champion (Løshund)",
  "Norsk Jakt Champion (Rev)",
  "Norsk Jakt Champion (Schweisshund)",
  "Norsk Lure Coursing Champion",
  "Norsk Lydighets  Champion",
  "Norsk Rallylydighet Champion",
  "Norsk Trekkhund  Champion",
  "Norsk Utstillingschampion",
  "Norsk Vannprøve Champion",
  "Norsk Viltspor  Champion",
  "Norsk Viltspor (F) Champion",
  "Rallylydighet klasse 1",
  "Rallylydighet klasse 2",
  "Rallylydighet klasse 3",
  "Rundering C",
  "Smeller 1",
  "Sporhund A",
  "Sporhund C",
  "Sporhund D",
  "Utenlandsk",
];
