import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./styles/TextStyles.module.scss";
import BecomeOwner from "./pages/BecomeOwner";
import BecomeBreeder from "./pages/BecomeBreeder";
import Profile from "./pages/Profile";
import Ad from "./pages/Ad";
import BreedOverview from "./pages/BreedOverview";
import BreedProfile from "./pages/BreedProfile";
import AboutPond from "./pages/InfoPages/AboutPond";
import ScrollToTop from "./scrollToTop";
import BreederInfo from "./pages/BreederInfo";
import Certificates from "./pages/InfoPages/Certificates";
import TermsAndCond from "./pages/InfoPages/TermsAndCond";
import Cookies from "./pages/InfoPages/Cookies";
import ContactUs from "./pages/InfoPages/ContactUs";
import TermsOfUse from "./pages/InfoPages/TermsOfUse";
import FAQ from "./pages/InfoPages/FAQ";
import BecomeOwnerInfo from "./pages/InfoPages/BecomeOwnerInfo";
import BecomeBreederInfo from "./pages/InfoPages/BecomeBreederInfo";
import BreederEthics from "./pages/InfoPages/BreederEthics";
import OwnerEthics from "./pages/InfoPages/OwnerEthics";
import ContactBreeder from "./pages/ContactBreeder";
import NewAdMobile from "./pages/NewAdMobile";
import Dashboard from "./pages/Dashboard/Dashboard";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/material-theme";
import { useIsMobile } from "./hooks/useIsMobile";
import ProfileMobile from "./pages/ProfileMobile";
import AccountSettings from "./pages/Dashboard/AccountSettings";
import EditProfile from "./pages/Dashboard/EditProfile";
import MyPuppies from "./pages/Dashboard/MyPuppies";
import MyDogs from "./pages/Dashboard/MyDogs";
import NewPuppy from "./pages/Dashboard/NewPuppy";
import NewDog from "./pages/Dashboard/NewDog";
import NewAdSteps from "./pages/NewAdSteps";
import AllAds from "./pages/AllAds";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/bundle";
import VerifyUser from "./pages/VerifyUser";
import Favorites from "./pages/Dashboard/Favorites";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import InfoPages from "./pages/InfoPages/InfoPage";
import { Helmet } from "react-helmet-async";
import { client } from "./index";
import imageUrlBuilder from "@sanity/image-url";
import OwnerRegistered from "./pages/OwnerRegistered";
import MyApplications from "./pages/Dashboard/MyApplications";
import SendApplication from "./pages/Application/SendApplication";
import Application from "./pages/Application/Application";
import BreederOverview from "./pages/BreederOverview";
import BreederRegistered from "./pages/BreederRegistered";
import ApplicationConfirmation from "./pages/Application/ApplicationConfirmation";
import RedirectPage from "./pages/RedirectPage";
import ChatScreen from "./pages/Chat";
import Auth0Reroute from "./pages/Auth0Reroute";

const App: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [metaTags, setMetaTags] = useState<any>();
  const [loading, setLoading] = useState(true);

  const builder = imageUrlBuilder(client);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function urlFor(source: any) {
    return builder.image(source.asset);
  }

  useEffect(() => {
    client.fetch('*[_type == "metaTags"]').then((tags) => {
      setMetaTags(tags);
      setLoading(false);
    });
  }, []);
  const { isMobile } = useIsMobile();
  if (loading) {
    return <div />;
  }
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTags[0].title ?? "POND"}</title>
        <meta name="description" content={metaTags[0].description} />
        <meta property="og:title" content={metaTags[0].title}></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={metaTags[0].description}
        ></meta>
        {metaTags[0].coverImage && (
          <meta
            property="og:image"
            content={urlFor(metaTags[0].coverImage).toString()}
          ></meta>
        )}
      </Helmet>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <Switch>
          <Layout>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route exact path="/valper-til-salgs" component={AllAds} />
            <Route exact path="/registrer" component={Register} />
            <Route exact path="/registrer/kjøper" component={BecomeOwner} />
            <Route
              exact
              path="/bruker-opprettet/kjøper"
              component={OwnerRegistered}
            />
            <Route
              exact
              path="/bruker-opprettet/oppdretter"
              component={BreederRegistered}
            />
            <Route
              exact
              path="/registrer/oppdretter"
              component={BecomeBreeder}
            />
            <Route exact path="/opprett-bruker" component={Auth0Reroute} />
            <Route
              exact
              path="/soknad-sendt"
              component={ApplicationConfirmation}
            />
            <Route
              exact
              path="/oppdretter/:userId"
              component={isMobile ? ProfileMobile : Profile}
            />
            <Route
              exact
              path="/verifiser/:type/:userId/:uuid"
              component={VerifyUser}
            />
            <Route exact path="/artikler" component={Articles} />
            <Route exact path="/artikkel/:slug" component={Article} />
            <Route exact path="/guide/:slug" component={InfoPages} />
            <Route exact path="/profil" component={Profile} />
            <Route exact path="/min-pond" component={Dashboard} />
            <Route
              exact
              path="/min-pond/rediger-profil"
              component={EditProfile}
            />
            <Route
              exact
              path="/min-pond/kontoinnstillinger"
              component={AccountSettings}
            />
            <Route exact path="/min-pond/favoritter" component={Favorites} />
            <Route exact path="/min-pond/mine-valper" component={MyPuppies} />
            <Route
              exact
              path="/min-pond/mine-valper/ny/:type"
              component={NewPuppy}
            />
            <Route exact path="/nytt-valpekull" component={NewAdSteps} />
            <Route
              exact
              path="/min-pond/mine-valper/rediger/:id/:type"
              component={NewPuppy}
            />
            <Route exact path="/min-pond/avlshunder/ny" component={NewDog} />
            <Route
              exact
              path="/min-pond/avlshunder/rediger/:id"
              component={NewDog}
            />
            <Route exact path="/oppdrettere" component={BreederOverview} />
            <Route exact path="/min-pond/avlshunder" component={MyDogs} />
            <Route
              exact
              path="/min-pond/mine-soknader"
              component={MyApplications}
            />
            <Route exact path="/annonse-alternativ" component={NewAdMobile} />
            <Route
              exact
              path="/send-soknad/:userId"
              component={SendApplication}
            />
            <Route
              exact
              path="/soknad/:applicationId"
              component={Application}
            />
            <Route exact path="/hunderaser" component={BreedOverview} />
            <Route
              exact
              path="/hunderaser/:breedName"
              component={BreedProfile}
            />
            <Route exact path="/annonse/:adId" component={Ad} />
            <Route exact path="/om-oss" component={AboutPond} />
            <Route exact path="/info/oppdretter" component={BreederInfo} />
            <Route exact path="/kontakt/:id" component={ContactBreeder} />
            <Route
              exact
              path="/pondifiserte-oppdrettere"
              component={Certificates}
            />
            <Route exact path="/personvernerklæring" component={TermsAndCond} />
            <Route exact path="/privacy-policy" component={TermsAndCond} />
            <Route
              exact
              path="/personvern-innstillinger"
              component={TermsAndCond}
            />
            <Route exact path="/cookies" component={Cookies} />
            <Route exact path="/kontakt-oss" component={ContactUs} />
            <Route exact path="/betingelser-og-vilkar" component={TermsOfUse} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/chat" component={ChatScreen} />
            <Route exact path="/bli-ponder" component={BecomeOwnerInfo} />
            <Route
              exact
              path="/registrer-oppdretter"
              component={BecomeBreederInfo}
            />
            <Route
              exact
              path="/retningslinjer/oppdretter"
              component={BreederEthics}
            />
            <Route exact path="/retningslinjer/eier" component={OwnerEthics} />
            <Route
              exact
              path="/raser"
              component={() => <RedirectPage url="/hunderaser" />}
            />
            <Route
              exact
              path="/annonser"
              component={() => <RedirectPage url="/valper-til-salgs" />}
            />
          </Layout>
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default App;
