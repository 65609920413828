import imageUrlBuilder from "@sanity/image-url";
import { client } from "../index";
import TextWithButton from "../components/Sanity/TextWithButton";
import TextWithIllustration from "../components/Sanity/TextWithIllustration";
import VideoComponent from "../components/Sanity/VideoComponent";
import TextWithBackgroundIllustration from "../components/Sanity/TextWithBackgroundIllustration";

export type Section = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const urlFor = (source: string) => {
  const builder = imageUrlBuilder(client);
  return builder.image(source);
};

export const SanityComponent = (section: Section) => {
  switch (section?._type) {
    case "blockArray":
      // eslint-disable-next-line react/react-in-jsx-scope
      return <TextWithButton section={section} />;
    case "textWithIllustration":
      // eslint-disable-next-line react/react-in-jsx-scope
      return <TextWithIllustration section={section} />;
    case "video":
      // eslint-disable-next-line react/react-in-jsx-scope
      return <VideoComponent section={section} />;
    default:
      // eslint-disable-next-line react/react-in-jsx-scope
      return <TextWithBackgroundIllustration section={section} />;
  }
};

export async function getPosts(slug: string) {
  const page = await client.fetch(`*[slug.current == $slug]`, {
    slug,
  });
  return Array.from(
    { length: 20 },
    (_, i) => page[0]?.row?.section?.[i]
  ).filter((s) => s !== undefined);
}
