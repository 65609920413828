import { Avatar, Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  useGetUserQuery,
  useGetUserByEmailQuery,
  DogParent,
} from "../api/graphql.gen";
import Button from "../components/Button";
import ProfileOurDogs from "../components/Profile/ProfileOurDogs";
import useIsVisible from "../hooks/useIsVisible";
import css from "../styles/Profile.module.scss";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import {
  translateAD,
  translateHD,
  translatePatella,
} from "../utils/healthTranslations";
import CreateUserDialog from "../components/CreateUserDialog";
import AgriaAgents from "../model/AgriaAgents";
import ClubData from "../model/ClubData";

interface RouteParams {
  userId: string;
}

const EmptyMessage = ({ label }: { label: string }) => (
  <div className={css.emptyMessage}>
    <div>{label}</div>
  </div>
);

const Profile: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<RouteParams>();
  const { isAuthenticated, user: authUser } = useAuth0();
  const { data: userData } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const currentUser = userData?.getUserByEmail.id;
  const id = userId ? parseInt(userId) : currentUser ? currentUser : -1;
  const { data: user } = useGetUserQuery({ id });

  const myAgent = AgriaAgents.find(
    (agent) => agent.id === user?.getUser.kennel?.agriaAgentId
  );

  const myAds = user?.getUser.ads;

  const availableAd = myAds?.filter((item) => item.adType === "AVAILABLE");

  useEffect(() => {
    if (id) {
      window.analytics.page(`Profil - id: ${id}`);
    }
  }, []);

  useEffect(() => {
    if (id) {
      window.analytics.page(`Profil - id: ${id}`);
    }
  }, []);

  const ourDogsRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const puppiesRef = useRef<HTMLDivElement>(null);
  const plannedRef = useRef<HTMLDivElement>(null);
  const kennelInfo = useRef<HTMLDivElement>(null);
  const agriaRef = useRef<HTMLDivElement>(null);

  const kennelIsVisible = useIsVisible(kennelInfo, "-100px");

  const [dogModal, setDogModal] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [tab, setTab] = useState("1");
  const [selectedParent, setSelectedParent] = useState<DogParent>();

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const [isBreeder, setIsBreeder] = useState(false);

  useEffect(() => {
    setIsBreeder(!!user?.getUser.breeder);
  }, [user]);

  useEffect(() => {
    if (!userId && !currentUser) {
      history.push("/");
    }
  }, []);

  return (
    <div className={css.BreedProfile}>
      <div className={css.bannerContainer}>
        <div className={css.leftBanner} />
        {user?.getUser.kennel?.bannerImage ? (
          <div className={css.middleBanner}>
            <img src={user?.getUser.kennel?.bannerImage} />
          </div>
        ) : (
          <div className={css.noBanner} />
        )}
        <div className={css.rightBanner} />
      </div>
      <div className={css.kennelContainer} ref={kennelInfo}>
        <div className={css.kennelHorizontalContainer}>
          <Avatar
            src={user?.getUser.profilePicture ?? "/images/default-avatar.png"}
            alt="Profilbilde"
            ref={aboutUsRef}
            className={css.profilePic}
          />
          <div className={css.kennelInfo}>
            <div className={css.kennelName}>{user?.getUser.kennel?.name}</div>
            <div
              className={css.location}
            >{`${user?.getUser.address?.city}, Norge`}</div>
            <div className={css.breeds}>
              {user?.getUser.kennel?.breeds.map((breed) => (
                <div
                  key={breed}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/hunderaser/${breed.replaceAll(" ", "-")}`)
                  }
                >
                  {breed}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 20 }}>
          {isBreeder && user?.getUser.email !== authUser?.email && (
            <div className={css.contact}>
              {/*<Button label="Lagre profil" skin="light" disabled />*/}
              <Button
                label="Kontakt meg"
                skin="light"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/kontakt/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            </div>
          )}
          {isBreeder &&
            user?.getUser.email !== authUser?.email &&
            !userData?.getUserByEmail.breeder && (
              <div className={css.contact}>
                <Button
                  label="Send søknad"
                  onClick={() =>
                    isAuthenticated
                      ? history.push(`/send-soknad/${userId}`)
                      : setShowCreateUser(true)
                  }
                />
              </div>
            )}
        </div>
      </div>
      {!kennelIsVisible && (
        <div className={css.smallKennelContainer}>
          <div className={css.infoContainer}>
            <Avatar
              src={user?.getUser.profilePicture ?? "/images/default-avatar.png"}
              alt="Profilbilde"
              className={css.profilePic}
            />
            <div className={css.kennelName}>{user?.getUser.kennel?.name}</div>
          </div>
          <div className={css.buttonContainer}>
            {isBreeder && user?.getUser.email !== authUser?.email && (
              <Button
                label="Kontakt meg"
                skin="light"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/kontakt/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            )}
            {isBreeder &&
              user?.getUser.email !== authUser?.email &&
              !userData?.getUserByEmail.breeder && (
                <div className={css.contact}>
                  <Button
                    label="Send søknad"
                    onClick={() =>
                      isAuthenticated
                        ? history.push(`/send-soknad/${userId}`)
                        : setShowCreateUser(true)
                    }
                  />
                </div>
              )}
          </div>
        </div>
      )}
      <div className={css.container}>
        {isBreeder && (
          <div
            className={!kennelIsVisible ? css.fixedSidePanel : css.sidePanel}
          >
            <div className={css.fixedWrapper}>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(aboutUsRef)}
              >
                Om kennel
              </div>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(puppiesRef)}
              >
                Tilgjengelige valper
              </div>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(plannedRef)}
              >
                Fremtidige kull
              </div>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(ourDogsRef)}
              >
                Mine hunder
              </div>
              {isBreeder && myAgent && (
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(agriaRef)}
                >
                  Min Agria forsikringsagent
                </div>
              )}
            </div>
          </div>
        )}
        <div className={css.bottomContainer}>
          <div className={css.contentContainer}>
            <div className={css.aboutBreeder}>
              <div className={css.textContainer}>
                <h6>Om kennel</h6>
                <p>{`${user?.getUser.firstName} ${user?.getUser.lastName}`}</p>
                <p>{`${user?.getUser.kennel?.motivation}`}</p>
              </div>
              <div />
              {(user?.getUser.kennel?.clubs?.length ||
                user?.getUser.agriaMember) &&
                isBreeder && (
                  <div className={css.textContainer}>
                    <h6>Medlem av</h6>
                    <div className={css.clubGrid}>
                      {user?.getUser.kennel?.clubs?.map((club) => (
                        <div
                          className={css.clubContainer}
                          key={club}
                          onClick={() => {
                            const hasClub = ClubData.find(
                              (item) =>
                                item.club.toLowerCase() === club.toLowerCase()
                            );
                            hasClub?.link
                              ? window.open(hasClub?.link)
                              : undefined;
                          }}
                        >
                          <Avatar
                            className={css.avatar}
                            src={
                              ClubData.find(
                                (item) =>
                                  item.club.toLowerCase() === club.toLowerCase()
                              )?.img ?? "/images/icons/club.svg"
                            }
                            alt="klubb ikon"
                          />
                          <div>{club}</div>
                        </div>
                      ))}
                      {user.getUser.agriaMember && (
                        <div className={css.clubContainer}>
                          <Avatar
                            src="/images/agria-logo.png"
                            className={css.avatar}
                            alt="klubb ikon"
                          />
                          <div>Agria breeders club</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
            {isBreeder && availableAd && (
              <div ref={puppiesRef}>
                <h6>Tilgjengelige kull</h6>
                {availableAd.length ? (
                  <div className={css.adGrid}>
                    {availableAd.map((ad) => (
                      <div
                        key={ad.id}
                        className={css.adCard}
                        onClick={() => history.push(`/annonse/${ad.id}`)}
                      >
                        <img
                          src={ad.adImage ?? "/images/placeholder-dog.png"}
                          alt="Annonsebilde"
                        />
                        <div className={css.title}>{ad.title}</div>
                        <div className={css.breed}>{ad.breed}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <EmptyMessage label="Denne oppdretteren har ingen tilgjengelige kull" />
                )}
              </div>
            )}
            {isBreeder && myAds && (
              <div ref={plannedRef} className={css.plannedContainer}>
                <h6>Fremtidige kull</h6>
                {myAds?.filter(
                  (item) =>
                    item.adType !== "AVAILABLE" && item.adType !== "DELETED"
                ).length ? (
                  <div className={css.upcomingContainer}>
                    {myAds
                      .filter((item) => item.adType === "PLANNED")
                      .map((ad) => (
                        <div key={ad.id}>
                          <div className={css.horizontalContainer}>
                            <div className={css.title}>Planlagte valper</div>
                            <div className={css.expected}>Forventet</div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.subTitle}>{ad?.breed}</div>
                            <div className={css.subTitle}>
                              {format(new Date(ad?.birthDate), "MMM yy")}
                            </div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.parents}>Mor</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "female"
                              )[0]?.NKKId ?? "N/A"}
                            </div>
                          </div>
                          <div
                            className={`${css.horizontalContainer} ${css.divider}`}
                          >
                            <div className={css.parents}>Far</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "male"
                              )[0]?.NKKId ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      ))}
                    {myAds
                      .filter((item) => item.adType === "COMING")
                      .map((ad) => (
                        <div key={ad.id}>
                          <div className={css.horizontalContainer}>
                            <div className={css.title}>Kommende valper</div>
                            <div className={css.expected}>Forventet</div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.subTitle}>{ad?.breed}</div>
                            <div className={css.subTitle}>
                              {format(new Date(ad?.birthDate), "MMM yy")}
                            </div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.parents}>Mor</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "female"
                              )[0]?.NKKId ?? "N/A"}
                            </div>
                          </div>
                          <div
                            className={`${css.horizontalContainer} ${css.divider}`}
                          >
                            <div className={css.parents}>Far</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "male"
                              )[0]?.NKKId ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <EmptyMessage label="Denne oppdretteren har ingen planlagte kull" />
                )}
              </div>
            )}
            {isBreeder && (
              <span>
                {user?.getUser.myDogs.length ? (
                  <span ref={ourDogsRef}>
                    <ProfileOurDogs
                      dogs={user.getUser.myDogs}
                      ref={ourDogsRef}
                      onClick={(dog) => {
                        setSelectedParent(dog);
                        setDogModal(true);
                      }}
                    />
                  </span>
                ) : (
                  <div ref={ourDogsRef} className={css.plannedContainer}>
                    <h6>Mine hunder</h6>
                    <EmptyMessage label="Denne oppdretteren har ikke lastet opp noen hunder" />
                  </div>
                )}
              </span>
            )}
            {isBreeder && myAgent && (
              <div className={css.agriaContainer} ref={agriaRef}>
                <div className={css.agentCard}>
                  <Avatar
                    className={css.avatar}
                    src={myAgent.image}
                    alt="Bilde av agent"
                  />
                  <div>
                    <div className={css.name}>{myAgent.name}</div>
                    <div className={css.location}>
                      {myAgent.location + ", Norge"}
                    </div>
                    <Button
                      label="Besøk profil"
                      onClick={() => {
                        window.analytics.track("Besøk agria agents profil");
                        window.open(myAgent.web);
                      }}
                    />
                  </div>
                </div>
                <img
                  src="/images/agria.png"
                  alt="Agra logo"
                  className={css.agriaLogo}
                />
                <ul>
                  <li>Personlig hjelp hvis du ønsker å kjøpe en forsikring</li>
                  <li>Tilgjengelige også på kvelder og i helger</li>
                  <li>Finnes i nærheten av deg</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={dogModal}
        onHide={() => setDogModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Tabs value={tab} variant="fullWidth" className={css.tabs}>
              <Tab value="1" label="Om hunden" onClick={() => setTab("1")} />
              <Tab value="2" label="Helse" onClick={() => setTab("2")} />
              <Tab value="3" label="Championater" onClick={() => setTab("3")} />
            </Tabs>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={css.parentModalContainer}>
            {selectedParent && (
              <div key={selectedParent.id} className={css.parentContainer}>
                <div className={css.gender}>
                  {selectedParent.gender === "male" ? "Hannhund" : "Tispe"}
                </div>
                <img
                  src={
                    selectedParent.images?.length
                      ? selectedParent.images[0]
                      : "/images/placeholder-dog.png"
                  }
                  alt="Bilde av forelder"
                />
                <div className={css.name}>{selectedParent.name}</div>
                {tab === "1" && (
                  <div className={css.details}>
                    <div className={css.detailsGrid}>
                      <div>Rase</div>
                      <div>{selectedParent.breed}</div>
                      <div>Reg nummer</div>
                      <div>{selectedParent.NKKId}</div>
                      <div>Farge</div>
                      <div>{selectedParent.color}</div>
                      <div>Vekt</div>
                      <div>{selectedParent.weight}</div>
                      <div>Eid av</div>
                      <div>
                        {selectedParent.isOwner
                          ? ""
                          : selectedParent.ownersName}
                      </div>
                    </div>
                    <div className={css.aboutGender}>{`Om hunden`}</div>
                    <p className={css.aboutContent}>
                      {selectedParent.description}
                    </p>
                  </div>
                )}
                {tab == "2" && (
                  <div className={css.health}>
                    <div className={css.title}>Vaksiner</div>
                    <div className={css.detailsGrid}>
                      <div>Ormekur</div>
                      <div>{selectedParent.wormTreatment ? "Tatt" : "-"}</div>
                      <div>DHP</div>
                      <div>{selectedParent.dhp ? "Tatt" : "-"}</div>
                      <div>PiBbPi</div>
                      <div>{selectedParent.pibbpi ? "Tatt" : "-"}</div>
                      <div>Rabies</div>
                      <div>{selectedParent.rabies ? "Tatt" : "-"}</div>
                    </div>
                    <div className={css.title}>Helsetester</div>
                    <div className={css.detailsGrid}>
                      <div>HD</div>
                      <div>{`Høyre: ${translateHD(
                        selectedParent.HDRight ?? -10
                      )}, Venstre: ${translateHD(
                        selectedParent.HDLeft ?? -10
                      )}`}</div>
                      <div>AD</div>
                      <div>{`Høyre: ${translateAD(
                        selectedParent.ADRight ?? -10
                      )}, Venstre: ${translateAD(
                        selectedParent.ADLeft ?? -10
                      )}`}</div>
                      <div>Patella</div>
                      <div>{`Høyre: ${translatePatella(
                        selectedParent.PatellaRight ?? -10
                      )}, Venstre: ${translatePatella(
                        selectedParent.PatellaLeft ?? -10
                      )}`}</div>
                    </div>
                    <div className={css.title}>Dokumentasjon</div>
                    <div className={css.detailsGrid}>
                      <div>Veterinærattest</div>
                      <div></div>
                      <div>Øyelysning</div>
                      <div></div>
                      <div>DNA-test</div>
                      <div>-</div>
                      <div>MentalTest</div>
                      <div>-</div>
                    </div>
                    <p>
                      POND anbefaler kjøpere om å undersøke dokumentasjonen
                      over, dette kan gjøres via dogweb eller ved å spørre
                      oppdretteren.
                    </p>
                  </div>
                )}
                {tab == "3" && (
                  <div>
                    {selectedParent.championTitle?.map((title) => (
                      <div key={title} className={css.championContainer}>
                        <img src="/images/icons/award.svg" alt="Prisikon" />
                        <p>{title}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className={css.background} />
          </div>
        </Modal.Body>
      </Modal>
      <CreateUserDialog
        open={showCreateUser}
        description="Du må opprette bruker eller logge inn for å kontakte oppdretter"
        eventName="Registrer - Kontakt oppdretter"
        onClose={() => setShowCreateUser(false)}
      />
    </div>
  );
};

export default Profile;
