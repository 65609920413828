import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#065C53",
      light: "#065C53",
      dark: "#065C53",
    },
    secondary: {
      main: "#E2F6D1",
      light: "#E2F6D1",
      dark: "#E2F6D1",
    },
    text: {
      primary: "#065C53",
      secondary: "#065C53",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
  },
});
