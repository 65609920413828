import React from "react";
import Button from "../Button";
import css from "../../styles/sanity-styles/TextWithBackgroundIllustration.module.scss";
import { urlFor, Section } from "../../utils/sanity-common";
import { useIsMobile } from "../../hooks/useIsMobile";

interface TextWithBackgroundIllustrationProps {
  section: Section;
}

const TextWithBackgroundIllustration: React.FC<
  TextWithBackgroundIllustrationProps
> = ({ section }: TextWithBackgroundIllustrationProps) => {
  const { isMobile } = useIsMobile();

  const imageUrl =
    isMobile && section.backgroundDesktopImage
      ? urlFor(section.backgroundPhoneImage.asset._ref).url()
      : section.backgroundDesktopImage
      ? urlFor(section.backgroundDesktopImage.asset._ref).url()
      : "";

  return (
    <div
      className={css.textWithBackgroundIllustration}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "100vw 100vh",
      }}
    >
      <div className={css.textWithBackgroundIllustrationContent}>
        <div>
          <div
            className={css.textWithBackgroundIllustrationContentText}
            style={{ color: "red" }}
          >
            <h3 style={{ color: section.titleColor }}>{section?.title}</h3>
            <p style={{ color: section.textColor }}>{section?.text}</p>
          </div>
          <div className={css.textWithBackgroundIllustrationButtons}>
            {section.callToAction?.[0] && (
              <Button
                onClick={() =>
                  (window.location.href = section.callToAction?.[0].link)
                }
                label={section.callToAction?.[0].buttonLabel}
                skin={section.callToAction?.[0].buttonColor}
              />
            )}
            {section.callToAction?.[1] && (
              <Button
                onClick={() =>
                  (window.location.href = section.callToAction?.[1].link)
                }
                label={section.callToAction?.[1].buttonLabel}
                skin={section.callToAction?.[1].buttonColor}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextWithBackgroundIllustration;
