import React from "react";
import css from "../styles/Community.module.scss";
import { DogFace } from "./SVG/DogFace";
import { Paw } from "./SVG/Paw";
import { Prize } from "./SVG/Prize";

export const Community: React.FC = () => (
  <div className={css.Community}>
    <div className={css.gridItem}>
      <Paw />
      <div className={css.bold}>250+</div>
      <div>Hunderaser</div>
    </div>
    <div className={css.gridItem}>
      <Prize />
      <div className={css.bold}>400+</div>
      <div>Oppdrettere</div>
    </div>
    <div className={css.gridItem}>
      <DogFace />
      <div className={css.bold}>500+</div>
      <div>Valpeannonser</div>
    </div>
  </div>
);
