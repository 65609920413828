import React from "react";
import { useHistory } from "react-router-dom";
import css from "../styles/ArticleCard.module.scss";
import { isAfter, isBefore } from "date-fns";

interface ArticleCardProps {
  img: string;
  articleType: string;
  title: string;
  url: string;
  mobile: boolean;
  breed: string;
  date: Date;
  className?: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  img,
  title,
  url,
  articleType,
  date,
  breed,
  className,
  mobile = false,
}: ArticleCardProps) => {
  const history = useHistory();

  return (
    <div
      className={`${
        mobile ? css.articleCardMobile : css.articleCard
      } ${className}`}
      onClick={() =>
        isBefore(date, new Date()) && history.push(`/artikkel/${url}`)
      }
    >
      <img className={css.image} src={img} alt="Artikkel bilde" />
      <div className={css.content}>
        <div>
          <p className={css.type}>
            {isAfter(date, new Date()) ? `Kommer snart` : articleType}
          </p>
          <h3 className={css.title}>{title}</h3>
          <p className={css.breed}>{breed}</p>
        </div>
        {!mobile && (
          <div
            className={css.button}
            onClick={() =>
              isBefore(date, new Date()) && history.push(`/artikkel/${url}`)
            }
          >
            Les mer
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
