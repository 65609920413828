import React, { useEffect } from "react";
import css from "../../styles/Info.module.scss";

const ContactUs: React.FC = function () {
  useEffect(() => {
    window.analytics.page("Kontakt oss");
  }, []);

  return (
    <div className={css.infoPage}>
      <h4>Kontakt oss</h4>
      <h5>Har du spørsmål?</h5>
      <p>Vi er her for å hjelpe! Send en e-post til kontakt@pond.no.</p>
      <div className={css.iframeContainer}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeWLzCcqnTcjg2-7KlAZTiGzNwF99ml0rKNqu3xy7ae1yOeiQ/viewform?embedded=true"
          className={css.responsiveIframe}
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};
export default ContactUs;
