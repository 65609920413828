import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/DashboardBanner.module.scss";
import Button from "../Button";
//import ProgressBar from "../PorgressBar";

interface BannerProps {
  image: string | null;
  title: string;
  info: string;
  percentage: number;
  subTitle?: string;
}

const DashboardBanner: React.FC<BannerProps> = ({
  image,
  title,
  info,
  //percentage,
  subTitle,
}: BannerProps) => {
  // const [_progress, setProgress] = useState(0);

  // useEffect(() => {
  //   for (let i = 0; i <= percentage; i++) {
  //     setProgress(i);
  //   }
  // }, []);

  const { isMobile } = useIsMobile();
  const { logout } = useAuth0();

  return (
    <div className={css.DashboardBanner}>
      <div className={css.profileInfo}>
        <Avatar
          src={image ?? "/images/default-avatar.png"}
          className={css.profilePic}
        />
        <div>
          <div className={css.minPond}>{subTitle}</div>
          <div className={css.welcomeText}>{title}</div>
          <div className={css.infoText}>{info}</div>
        </div>
        {!isMobile && (
          <div>
            {/* <Button label="Bytt til vanlig bruker" skin="transparent" /> */}
            <Button label="Logg ut" skin="transparent" onClick={logout} />
          </div>
        )}
      </div>
      {/* <div className={css.progressContainer}>
        <div className={css.progressTextContainer}>
          <div className={css.progressText}>Hva mangler jeg?</div>
          <div className={css.progressText}>{`${progress}% fremgang`}</div>
        </div>
        <ProgressBar variant="determinate" value={progress} />
      </div> */}
    </div>
  );
};

export default DashboardBanner;
