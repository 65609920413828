import React, { useEffect, useState } from "react";
import css from "../styles/BreederOverview.module.scss";
import "rc-slider/assets/index.css";
import { useIsMobile } from "../hooks/useIsMobile";
import { User, useGetBreedersQuery } from "../api/graphql.gen";
import LoadingScreen from "../components/LoadingScreen";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { DogBreeds } from "../model/DogBreeds";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../components/Button";
import Fade from "react-reveal/Fade";

const BreederOverview: React.FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { data, isLoading } = useGetBreedersQuery({
    authenticated: isAuthenticated,
  });
  const [breed, setBreed] = useState("");
  const [kennel, setKennel] = useState("");
  const [breeders, setBreeders] = useState<User[] | undefined>();

  useEffect(() => {
    if (breed !== "") {
      setBreeders(
        data?.breeders.filter((item) =>
          item.kennel?.breeds.includes(breed)
        ) as User[]
      );
    } else {
      setBreeders(data?.breeders as User[]);
    }
  }, [breed, data]);

  useEffect(() => {
    if (kennel !== "") {
      setBreeders(
        data?.breeders?.filter((item) =>
          item.kennel?.name.toLowerCase().includes(kennel.toLowerCase())
        ) as User[]
      );
    } else {
      setBreeders(data?.breeders as User[]);
    }
    setBreed("");
  }, [kennel]);

  const { isMobile } = useIsMobile();
  useEffect(() => {
    window.analytics.page("Oppdretteroversikt");
  }, []);

  const history = useHistory();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={css.BreederOverview}>
      <div className={css.intro}>
        <h1>Oppdrettere</h1>
        <p>
          Finn oppdrettere i Norge. Utforsk deres oppdretterprofiler, les om
          deres hunder og planer for valpekull. Vi kobler deg med de riktige
          menneskene slik at du kan finne din fremtidige hund.
        </p>
      </div>
      <div className={css.filterContainer}>
        <FormControl
          size="small"
          sx={isMobile ? { width: "40%" } : { width: "20%" }}
        >
          <TextField
            id="breeder-search"
            value={kennel}
            type="text"
            size="small"
            label="Søk etter kennel"
            onChange={(e) => setKennel(e.target.value)}
            disabled={!isAuthenticated}
          />
        </FormControl>
        <FormControl
          size="small"
          sx={isMobile ? { width: "40%" } : { width: "20%" }}
          disabled={!isAuthenticated}
        >
          <InputLabel id="breed-select-label">Rase</InputLabel>
          <Select
            labelId="breed-select-label"
            id="breed-select"
            value={breed}
            label="Rase"
            autoWidth
            onChange={(e) => setBreed(e.target.value)}
          >
            <MenuItem value={""}>Alle</MenuItem>
            {DogBreeds?.sort().map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {!isAuthenticated && (
        <div className={css.notLoggedIn}>
          <h3>
            Logg inn for å søke, filtrere og få oversikt over alle oppdretterne
          </h3>
          <Button
            label="Kom i gang"
            onClick={() => {
              window.analytics.track("Opprett bruker - Oppdretteroversikt");
              loginWithRedirect();
            }}
          />
        </div>
      )}
      {breeders?.length ? (
        <div
          className={css.grid}
          style={isAuthenticated ? { paddingTop: "10rem" } : undefined}
        >
          {breeders?.map((breeder) => (
            <Fade bottom key={breeder.id}>
              <div
                className={css.card}
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/oppdretter/${breeder.id}`)
                    : undefined
                }
              >
                <div className={css.banner}>
                  {breeder.kennel?.bannerImage && (
                    <img
                      src={breeder.kennel?.bannerImage ?? undefined}
                      alt="Bannerbilde"
                    />
                  )}
                </div>
                <Avatar
                  className={css.avatar}
                  src={breeder.profilePicture ?? "/images/default-avatar.png"}
                />
                <div className={css.kennel}>{breeder.kennel?.name}</div>
                <div className={css.city}>
                  {breeder.address?.city === ""
                    ? "Ukjent"
                    : breeder.address?.city.toLowerCase().trim()}
                  , Norge
                </div>
                {breeder.kennel?.breeds.sort().map((breedName, index) => (
                  <div className={css.breed} key={breedName + breeder.id}>
                    {`${breedName}${
                      index + 1 === breeder.kennel?.breeds.length ? "" : ","
                    }`}
                  </div>
                ))}
              </div>
            </Fade>
          ))}
        </div>
      ) : (
        <div className={css.noResult}>
          <Fade bottom>
            <img src="/images/ouch.svg" alt="Ikke funnet illustrasjon" />
            <h2>Fant ingen oppdrettere</h2>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default BreederOverview;
