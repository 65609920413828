import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import LoadingScreen from "../components/LoadingScreen";
import css from "../styles/Login.module.scss";

const Auth0Reroute: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      openUrl: async (url: string) => window.location.replace(url),
    });
  }, []);

  return (
    <div className={css.Login}>
      <LoadingScreen />
    </div>
  );
};

export default Auth0Reroute;
