import React from "react";
import Button from "../components/Button";
import css from "../styles/Login.module.scss";
import { useHistory } from "react-router";

const OwnerRegistered: React.FC = () => {
  const history = useHistory();

  return (
    <div className={css.Login}>
      <div>
        <h4 className={css.title}>Velkommen til Pond</h4>
        <div className={css.buttonContainer}>
          <Button
            skin="dark"
            label="Utforsk raser"
            className={css.button}
            onClick={() => history.push("/hunderaser")}
          />
          <Button
            skin="dark"
            label="Gå til forside"
            className={css.button}
            onClick={() => history.push("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnerRegistered;
