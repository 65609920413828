import React from "react";
import css from "../../styles/Info.module.scss";

const BreederEthics: React.FC = function () {
  return (
    <div className={css.infoPage}>
      <h4>Etiske retningslinjer for oppdrettere</h4>
      <p>Kommer snart.</p>
    </div>
  );
};

export default BreederEthics;
