import { Add, HelpOutlineRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useCreateUserMutation } from "../api/graphql.gen";
import Button from "../components/Button";
import Input from "../components/NewInput";
import TextArea from "../components/TextArea";
import { DogBreeds } from "../model/DogBreeds";
import css from "../styles/Login.module.scss";
import { validateName, validatePhone } from "../utils/fieldValidator";
import { clubNames } from "../model/Clubs";
import uploadFileToBlob from "../utils/azure-storage-blob";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-bootstrap/Modal";
import TermsAndCond from "./InfoPages/TermsAndCond";
import LoadingScreen from "../components/LoadingScreen";
import AgriaAgents from "../model/AgriaAgents";

const BecomeBreeder: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();
  const [step, setStep] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>(user?.given_name ?? "");
  const [firstYear, setFirstYear] = useState<string>(user?.family_name ?? "");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [breeds, setBreeds] = useState<string[]>([""]);
  const [medicalTests, setMedicalTests] = useState<string[]>([]);
  const [motivation, setMotivation] = useState<string>("");
  const [NKK, setNKK] = useState<string>("");
  const [kennel, setKennel] = useState<string>("");
  const [clubs, setClubs] = useState<string[]>([]);
  const [NKKCheck, setNKKCheck] = useState<boolean>(false);
  const [joinAgria, setJoinAgria] = useState<boolean>(false);
  const history = useHistory();
  const [kennelNameError, setKennelNameError] = useState<string>("");
  const [firstYearError, setFirstYearError] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [termsError, setTermsError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isAgriaMember, setIsAgriaMember] = useState<boolean>();
  const [myAgent, setMyAgent] = useState<string>();
  const [profilePicture, setProfilePicture] = useState<string>(
    user?.picture ?? ""
  );
  const [bannerImage, setBannerImage] = useState<string>("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [moreTests, setMoreTests] = useState("");
  const allBreeds = DogBreeds.sort();

  const { mutate: registerBreeder } = useCreateUserMutation({
    onSuccess: () => {
      window.analytics.track("Bruker opprettet - oppdretter", {
        firstName,
        lastName,
        email: user?.email,
        breeder: true,
      });
      history.push("/bruker-opprettet/oppdretter");
    },
  });
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    const uuidFilename = new File([file], uuidv4() + file.name);
    setFileSelected(uuidFilename);
  };

  const testOptions = [
    "Prcd-PRAs",
    "Von Willebrands disease type II",
    "Von Willebrands disease",
    "rcd4-PRA",
    "rcd1-PRA",
    "HUU",
    "CLAD",
    "CCA",
    "GHN",
    "PPL",
    "Gonoskopi",
    "Øyelysning",
    "DNA",
    "Hofteleddsdysplasi",
    "Albueleddsdysplasi",
    "Patella",
    "Mentalbeskrivelse",
  ];

  const onFileUpload = async (imagesFor: string) => {
    setUploading(true);
    await uploadFileToBlob(fileSelected);
    switch (imagesFor) {
      case "profile":
        setProfilePicture(
          `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
        );
        break;
      case "banner":
        setBannerImage(
          `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
        );
        break;
    }
    setFileSelected(null);
    setUploading(false);
  };
  useEffect(() => {
    if (fileSelected) {
      if (step === 0) {
        onFileUpload("profile");
      } else if (step === 1) {
        onFileUpload("banner");
      }
    }
  }, [fileSelected]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [setStep]);

  const controlFirstStep = () => {
    if (firstName.length === 0) {
      setFirstNameError("Fornavn kan ikke være tomt");
    } else {
      setFirstNameError("");
    }
    if (lastName.length === 0) {
      setLastNameError("Etternavn kan ikke være tomt");
    } else {
      setLastNameError("");
    }
    if (!validatePhone(phoneNumber)) {
      setPhoneNumberError("Tlf må være 8 tall, eksempel 12345678");
    } else {
      setPhoneNumberError("");
    }
    if (!accepted) {
      setTermsError("Du må godta våre vilkår for å fortsette");
    } else {
      setTermsError("");
    }
    if (
      validateName(firstName) &&
      validateName(lastName) &&
      validatePhone(phoneNumber) &&
      accepted
    ) {
      setStep(step + 1);
      setFirstNameError("");
      setLastNameError("");
      setPhoneNumberError("");
    }
  };

  const getTests = () => {
    const additionalTest = moreTests.split(",");
    const result = additionalTest
      .map((test) => test.trim())
      .filter((test) => test.length !== 0);
    return [...medicalTests, ...result];
  };

  const controlSecondStep = () => {
    if (kennel.length === 0) {
      setKennelNameError("Kennelnavn kan ikke være tomt");
    } else {
      setKennelNameError("");
    }
    if (breeds[0] === "") {
      setError("Velg minst en rase");
    } else {
      setError("");
    }
    if (kennel.length !== 0 && breeds[0] !== "") {
      setStep(step + 1);
      setKennelNameError("");
      setError("");
    }
  };

  const controlThirdStep = () => {
    if (firstYear.length === 4 || firstYear.length === 0) {
      setFirstYearError("");
      registerBreeder({
        firstName,
        lastName,
        email: user?.email ?? "",
        phoneNumber,
        password: "",
        breeder: true,
        about: "",
        kennelName: kennel,
        nkkId: NKK,
        breeds,
        street,
        zip,
        city,
        url: "",
        experience: "",
        motivation,
        specialization: "",
        clubs,
        NKKCheck,
        profilePicture,
        bannerImage,
        joinAgria,
        medicalTests: getTests(),
        firstYear: firstYear !== undefined ? parseInt(firstYear) : undefined,
        isAgriaMember,
        agriaAgentId: myAgent
          ? AgriaAgents.find((agent) => agent.name === myAgent)?.id
          : undefined,
      });
    } else {
      setFirstYearError("Året må være formatet YYYY, eks. 2022");
    }
  };

  useEffect(() => {
    window.analytics.page("Opprett bruker - Oppdretter");
    if (!isAuthenticated) {
      history.push("/");
    }
  }, []);

  const updateBreeds = (value: string, index: number) => {
    const li: string[] = [];
    breeds.map((item, i) => (i === index ? li.push(value) : li.push(item)));
    setBreeds(li);
  };

  const renderFirstStep = () => (
    <div>
      <h4 className={css.title}>Opprett bruker</h4>
      <Input
        id="firstName"
        placeholder="Fornavn"
        value={firstName}
        required
        onChange={(value) => setFirstName(value)}
        type="firstName"
        className={css.input}
        error={firstNameError.length > 0}
        errorMessage={firstNameError}
      />
      <Input
        id="lastName"
        placeholder="Etternavn"
        required
        value={lastName}
        onChange={(value) => setLastName(value)}
        type="lastName"
        className={css.input}
        error={lastNameError.length > 0}
        errorMessage={lastNameError}
      />
      <Input
        id="phonenumber"
        placeholder="Telefonnummer"
        required
        value={phoneNumber}
        onChange={(value) => setPhoneNumber(value)}
        type="phonenumber"
        className={css.input}
        error={phoneNumberError.length > 0}
        errorMessage={phoneNumberError}
      />
      <Input
        id="street"
        placeholder="Adresse"
        value={street}
        onChange={(value) => setStreet(value)}
        type="street"
        className={css.input}
      />
      <Input
        id="zip"
        placeholder="Postnummer"
        value={zip}
        onChange={(value) => setZip(value)}
        className={css.input}
        type="zip"
      />
      <Input
        id="city"
        placeholder="Sted"
        value={city}
        onChange={(value) => setCity(value)}
        className={css.input}
        type="city"
      />
      <div className={css.uploadContainer}>
        <div
          className={css.imageUpload}
          onClick={() =>
            hiddenFileInput.current && hiddenFileInput.current.click()
          }
        >
          <span>Last opp profilbilde</span>
          <Add />
        </div>
        <input
          type="file"
          hidden
          ref={hiddenFileInput}
          onChange={onFileChange}
        />
        {profilePicture && (
          <div>
            <img
              src={profilePicture}
              alt="profile"
              style={{
                height: 100,
                width: 100,
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                borderRadius: 5,
              }}
            />
            <div
              onClick={() => setProfilePicture("")}
              style={{ cursor: "pointer" }}
            >
              Slett
            </div>
          </div>
        )}
        {uploading && <LoadingScreen />}
      </div>
      <FormControlLabel
        className={css.checkboxContainer}
        control={
          <Checkbox
            checked={accepted}
            onChange={() => setAccepted(!accepted)}
          />
        }
        label={`Ved å klikke “Fortsett” sier jeg meg enig i PONDs annonseregler, bruksvilkår og personvernerklæring.
        `}
      />
      <p style={{ paddingTop: 16 }}>
        Les mer om informasjonen vi innhenter og hvordan vi lagrer det{" "}
        <u
          style={{ cursor: "pointer" }}
          onClick={() => setShowTermsModal(true)}
        >
          her
        </u>
        .
      </p>
      <p className={css.errorMessage}>{termsError}</p>
      <div className={css.buttonContainer}>
        <Button
          skin="lightGreen"
          label="Tilbake"
          className={css.button}
          onClick={() => history.goBack()}
        />
        <Button
          skin="dark"
          label="Fortsett"
          className={css.button}
          onClick={() => controlFirstStep()}
        />
      </div>
      <Modal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        animation
        size="xl"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <TermsAndCond />
        </Modal.Body>
      </Modal>
      <Modal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        animation
        size="xl"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <TermsAndCond />
        </Modal.Body>
      </Modal>
    </div>
  );

  const renderSecondStep = () => (
    <div>
      <h4 className={css.title}>Om ditt oppdrett</h4>
      <p style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
        Fyll ut informasjon om ditt oppdrett. Desto mer du fyller inn, jo mer
        fullstendig vil profilen din se ut.
      </p>

      <Input
        id="kennel"
        placeholder="Kennelnavn"
        required
        value={kennel}
        onChange={(value) => setKennel(value)}
        type="name"
        className={css.input}
        error={kennelNameError.length > 0}
        errorMessage={kennelNameError}
        rightIcon={
          <Tooltip
            title={
              <span className={css.helpText}>
                Har du ikke navn på din kennel? Lag et som gjør at folk kan
                kjenne deg igjen.
              </span>
            }
            placement="right-start"
          >
            <HelpOutlineRounded className={css.helpButton} />
          </Tooltip>
        }
      />
      {breeds.map((_item, index) => (
        <TextField
          key={`breed-${index}`}
          name={`breed${index}`}
          id={`breed${index}`}
          className={css.select}
          select
          onChange={(e) => updateBreeds(e.target.value, index)}
          label="Hvilke raser avler du?"
          required
        >
          {allBreeds.map((breed, i) => (
            <MenuItem sx={{ zIndex: 100000 }} value={breed} key={i}>
              {breed}
            </MenuItem>
          ))}
        </TextField>
      ))}
      {breeds.length < 4 && (
        <div
          className={css.addButton}
          onClick={() => setBreeds([...breeds, ""])}
        >
          Legg til rase&nbsp;
          <Add />
        </div>
      )}
      <div className={css.uploadContainer}>
        <div
          className={css.imageUpload}
          onClick={() =>
            hiddenFileInput.current && hiddenFileInput.current.click()
          }
        >
          <span>Last opp banner bilde</span>
          <Add />
        </div>
        <input
          type="file"
          hidden
          ref={hiddenFileInput}
          onChange={onFileChange}
        />
        {bannerImage && (
          <div>
            <img
              src={bannerImage}
              alt="banner"
              style={{
                height: 100,
                width: 200,
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                borderRadius: 5,
              }}
            />
            <div
              onClick={() => setBannerImage("")}
              style={{ cursor: "pointer" }}
            >
              Slett
            </div>
          </div>
        )}
        {uploading && <LoadingScreen />}
      </div>

      <Input
        id="nkk"
        placeholder="NKK-medlemsnummer"
        value={NKK}
        onChange={(value) => setNKK(value)}
        type="name"
        className={css.input}
        rightIcon={
          <Tooltip
            title={
              <span className={css.helpText}>
                Er du medlem i NKK? Ved å skrive inn ditt medlemsnummer vil du
                få en NKK-badge på din profil. Medlemsnummeret ditt vil ikke
                vises for andre.
              </span>
            }
            placement="right-start"
          >
            <HelpOutlineRounded className={css.helpButton} />
          </Tooltip>
        }
      />
      <TextField
        name={`clubs`}
        select
        id={`clubs`}
        className={css.select}
        onChange={(e) => setClubs([e.target.value])}
        label="Raseklubb"
      >
        {clubNames.map((clubName, i) => (
          <MenuItem value={clubName} key={i}>
            {clubName}
          </MenuItem>
        ))}
      </TextField>
      <p>
        Du kan fylle inn og endre dette senere på
        <br /> &quot;Min POND&quot;
      </p>
      {NKK !== "" && NKK !== undefined && (
        <div className={css.agriaSection}>
          <div className={css.heading}>
            <img
              className={css.logo}
              src="/images/agria-logo.png"
              alt="Agria breeders club logo"
            />
            <div>Er du medlem i Agria Breeders Club?</div>
          </div>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                onChange={() => {
                  setIsAgriaMember(true);
                  setJoinAgria(false);
                }}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                onChange={() => {
                  setIsAgriaMember(false);
                  setMyAgent(undefined);
                }}
                label="Nei"
              />
            </RadioGroup>
          </FormControl>
          {isAgriaMember && (
            <div>
              <h4>Hvem er din rådgiver?</h4>
              <TextField
                name="myAgent"
                select
                id="myAgent"
                className={css.select}
                onChange={(e) => setMyAgent(e.target.value)}
                placeholder="Rådgiver"
              >
                {AgriaAgents.map((agent, i) => (
                  <MenuItem value={agent.name} key={i}>
                    {agent.name}
                  </MenuItem>
                ))}
              </TextField>
              <p>
                Husker du ikke hvem din rådgiver er? Send mail til{" "}
                <a href="mailto:info@agriabreedersclub.no">
                  info@agriabreedersclub.no
                </a>
              </p>
            </div>
          )}
          {isAgriaMember === false && (
            <div>
              <h4>Ønsker du å bli medlem?</h4>
              <p>Som medlem får du fordeler som:</p>
              <ul>
                <li>Valpepakker til kjøperne dine</li>
                <li>
                  Poeng du kan bruke til å kjøpe flotte produkter i Agria
                  Breeders Shop
                </li>
                <li>Nyhetsbrev om hund & helse</li>
              </ul>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setJoinAgria(!joinAgria)}
                    value={joinAgria}
                  />
                }
                label="Det er gratis å være medlem, og du trenger ikke å være forsikret i Agria. Kryss av om du ønsker å bli kontaktet av Agria breeders club"
              />
              <p>
                Ved å krysse av samtykker du for at din kontaktinformasjon blir
                sendt til Agria Breeders Club
              </p>
            </div>
          )}
          <p className={css.errorMessage}>{error}</p>
          <div className={css.buttonContainer}>
            <Button
              skin="lightGreen"
              label="Tilbake"
              className={css.button}
              onClick={() => setStep(step - 1)}
            />
            <Button
              skin="dark"
              label="Fortsett"
              className={css.button}
              onClick={() => controlSecondStep()}
            />
          </div>
        </div>
      )}
    </div>
  );

  const renderThirdStep = () => (
    <div>
      <h4 className={css.title}>Fortell mer om ditt oppdrett</h4>

      <p>Ditt første kull</p>
      <Input
        id="firstYear"
        placeholder="YYYY"
        value={firstYear}
        onChange={(value) => setFirstYear(value)}
        type="number"
        className={css.input}
        error={firstYearError.length > 0}
        errorMessage={firstYearError}
      />

      <p style={{ marginTop: "2rem" }}>
        Skriv mer om deg selv og ditt oppdrett slik at potensielle hundekjøpere
        kan lære mer om deg.
      </p>
      <TextArea
        value={motivation}
        placeholder="Jeg har drevet med oppdrett siden..."
        onChange={(value) => setMotivation(value)}
        rows={8}
        maxLength={2500}
        className={css.input}
      />

      <p style={{ marginTop: "2rem" }}>Helsetester du foretar på avlshundene</p>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={testOptions}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        onChange={(_event, value) => setMedicalTests(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder="Legg til flere"
          />
        )}
      />
      <p style={{ marginTop: "2rem" }}>
        Fant ikke testene du utfører? Legg til flere her
      </p>
      <p>
        Skriv sånn her: CMR, Primary Lens Luxation, -{" "}
        <b>Husk komma mellom hver test</b>
      </p>
      <Input
        id="moreTests"
        placeholder="Flere tester"
        value={moreTests}
        onChange={(value) => setMoreTests(value)}
        type="text"
        className={css.input}
      />
      <div style={{ padding: "3rem 0" }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => setNKKCheck(!NKKCheck)}
              value={NKKCheck}
            />
          }
          label={
            <div>
              Jeg har fulgt NKKs etiske retningslinjer for avl.{" "}
              <u>
                <a
                  href="https://www.nkk.no/getfile.php/13278450-1642752356/Dokumenter/Helse/Etiske%20grunnregler%20for%20avl%20og%20oppdrett.pdf%22%3E"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les retningslinjene her
                </a>
              </u>
            </div>
          }
        />
      </div>

      <div className={css.buttonContainer}>
        <Button
          skin="lightGreen"
          label="Tilbake"
          className={css.button}
          onClick={() => setStep(step - 1)}
        />
        <Button
          skin="dark"
          label="Fullfør"
          className={css.button}
          onClick={() => controlThirdStep()}
        />
      </div>
    </div>
  );

  return (
    <div className={css.Login}>
      {step === 0 && renderFirstStep()}
      {step === 1 && renderSecondStep()}
      {step === 2 && renderThirdStep()}
    </div>
  );
};

export default BecomeBreeder;
