import React from "react";
import { PortableText } from "@portabletext/react";
import Button from "../Button";
import css from "../../styles/sanity-styles/TextWithButton.module.scss";
import { Section } from "../../utils/sanity-common";

interface TextWithButtonProps {
  section: Section;
}

const TextWithButton: React.FC<TextWithButtonProps> = ({
  section,
}: TextWithButtonProps) => {
  return (
    <div
      className={css.textWithButton}
      style={{ background: `${section.backgroundColor}` }}
    >
      <div className={css.textWithButtonContent}>
        <PortableText value={section?.blockArray} />
        <div className={css.textWithButtonButtons}>
          {section.callToAction?.[0] && (
            <Button
              onClick={() =>
                (window.location.href = section.callToAction?.[0].link)
              }
              label={section.callToAction?.[0].buttonLabel}
              skin={section.callToAction?.[0].buttonColor}
            />
          )}
          {section.callToAction?.[1] && (
            <Button
              onClick={() =>
                (window.location.href = section.callToAction?.[1].link)
              }
              label={section.callToAction?.[1].buttonLabel}
              skin={section.callToAction?.[0].buttonColor}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextWithButton;
