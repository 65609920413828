import React from "react";
import css from "../../styles/Info.module.scss";

const OwnerEthics: React.FC = function () {
  return (
    <div className={css.infoPage}>
      <h4>Etiske retningslinjer for hundeeiere</h4>
      <p>Kommer snart.</p>
    </div>
  );
};

export default OwnerEthics;
