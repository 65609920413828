import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import css from "../../styles/FAQ.module.scss";
import { PortableText } from "@portabletext/react";
import { Section, getPosts } from "../../utils/sanity-common";

const FAQ: React.FC = function () {
  const [aboutPondSections, setAboutPondSections] = useState<Section[]>([]);
  const [aboutAccountSections, setAboutAccountSections] = useState<Section[]>(
    []
  );
  const [pondVisible, setPondVisible] = useState<boolean[]>([]);
  const [accountVisible, setAccountVisible] = useState<boolean[]>([]);

  useEffect(() => {
    window.analytics.page("FAQ");
    getFAQ();
  }, []);

  async function getFAQ() {
    const pondSections = await getPosts("faq-om-pond");
    const accountSections = await getPosts("faq-om-konto");

    setAboutPondSections(pondSections);
    setAboutAccountSections(accountSections);
  }

  // Bruk useEffect for å håndtere endringer i aboutPondSections og aboutAccountSections
  useEffect(() => {
    setPondVisible(Array(aboutPondSections.length).fill(false));
  }, [aboutPondSections]);

  useEffect(() => {
    setAccountVisible(Array(aboutAccountSections.length).fill(false));
  }, [aboutAccountSections]);

  return (
    <div className={css.FAQ}>
      <div className={css.aboutPond}>
        <div className={css.grid}>
          <h3>Om POND</h3>
          <div>
            {aboutPondSections?.map((section, index) => (
              <div key={section?.heading} className={css.questionContainer}>
                <div
                  className={css.dropDown}
                  onClick={() => {
                    const arr: boolean[] = [];
                    pondVisible.map((value, i) => {
                      index === i ? arr.push(!value) : arr.push(value);
                    });
                    setPondVisible(arr);
                  }}
                >
                  <h5 className={css.question}>{section.heading}</h5>
                  <img
                    src={
                      pondVisible[index]
                        ? "/images/icons/close.svg"
                        : "/images/icons/open.svg"
                    }
                    alt="Åpne ikon"
                  />
                </div>
                <Collapse in={pondVisible[index]} timeout="auto">
                  <p className={css.answer}>
                    <PortableText value={section.blockArray} />
                  </p>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={css.accountSection}>
        <div className={css.grid}>
          <h3>Konto</h3>
          <div>
            {aboutAccountSections?.map((section, index) => (
              <div key={section.heading} className={css.questionContainer}>
                <div
                  className={css.dropDown}
                  onClick={() => {
                    const arr: boolean[] = [];
                    accountVisible.map((value, i) => {
                      index === i ? arr.push(!value) : arr.push(value);
                    });
                    setAccountVisible(arr);
                  }}
                >
                  <h5 className={css.question}>{section.heading}</h5>
                  <img
                    src={
                      accountVisible[index]
                        ? "/images/icons/close.svg"
                        : "/images/icons/open.svg"
                    }
                    alt="Åpne ikon"
                  />
                </div>
                <Collapse in={accountVisible[index]} timeout="auto">
                  <p className={css.answer}>
                    <PortableText value={section.blockArray} />
                  </p>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
