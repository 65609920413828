import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import css from "../styles/Login.module.scss";

const Register: React.FC = () => {
  const history = useHistory();

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    window.analytics.page("Bli POND'er");
    if (!isAuthenticated) {
      history.push("/");
    }
  }, []);

  return (
    <div className={css.Login}>
      <div className={css.Register}>
        <h4 className={css.title} style={{ width: 500 }}>
          Hva beskriver deg best?
        </h4>
        <div className={css.buttonIllustrationContainer}>
          <div className={css.buttonParent}>
            <img
              src="/images/breeder_illustration.png"
              alt="Oppdretter illustrasjon"
            />
            <Button
              skin="dark"
              label="Jeg er oppdretter"
              className={css.registerButton}
              onClick={() => history.push("/registrer/oppdretter")}
            />
          </div>
          <div className={css.buttonParent}>
            <img
              src="/images/buyer_illustration.png"
              alt="Kjøper illustrasjon"
            />
            <Button
              skin="dark"
              label="Jeg er vanlig bruker"
              className={css.registerButton}
              onClick={() => history.push("/registrer/kjøper")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
