import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import {
  useGetMyDogsQuery,
  useGetUserByEmailQuery,
  useUploadDogMutation,
} from "../../api/graphql.gen";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import Input from "../../components/NewInput";
import { useIsMobile } from "../../hooks/useIsMobile";
import { champions as AllChamps } from "../../model/Champions";
import { DogBreeds } from "../../model/DogBreeds";
import css from "../../styles/NewDog.module.scss";
import uploadFileToBlob from "../../utils/azure-storage-blob";

interface RouteParams {
  id: string;
}

const NewDog: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [myDog, setMyDog] = useState(true);
  const [registered, setRegistered] = useState(true);
  const [ownersName, setOwnersName] = useState("");
  const [dogsName, setDogsName] = useState("");
  const [breed, setBreed] = useState("");
  const [color, setColor] = useState("");
  const [personality, setPersonality] = useState("");
  const [weight, setWeight] = useState<number>();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [maleGender, setMaleGender] = useState(true);
  const [medications, setMedications] = useState(false);
  const [rabies, setRabies] = useState(false);
  const [pibbpi, setPibbpi] = useState(false);
  const [dhp, setDhp] = useState(false);
  const [wormTreatment, setWormTreatment] = useState(false);
  const [medicationDetails, setMedicationDetails] = useState("");
  const [eyeCheck, setEyeCheck] = useState(true);
  const [dnaTest, setDnaTest] = useState(true);
  const [mentalTest, setMentalTest] = useState(true);
  const [ADRight, setADRight] = useState<number>();
  const [ADLeft, setADLeft] = useState<number>();
  const [HDLeft, setHDLeft] = useState<number>();
  const [HDRight, setHDRight] = useState<number>();
  const [patellaLeft, setPatellaLeft] = useState<number>();
  const [patellaRight, setPatellaRight] = useState<number>();
  const [image, setImage] = useState<string>();
  const [dnaFile, setDnaFile] = useState<string>();
  const [vetFile, setVetFile] = useState<string>();
  const [eyeFile, setEyeFile] = useState<string>();
  const [mentalFile, setMentalFile] = useState<string>();
  const [fileType, setFileType] = useState<string>("");
  const [champions, setChampions] = useState<string[]>([""]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const allBreeds = DogBreeds.sort();
  const { id } = useParams<RouteParams>();
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? -1;
  const { data } = useGetMyDogsQuery();

  const history = useHistory();

  const { mutate: uploadDog } = useUploadDogMutation({
    onSuccess: () => {
      history.replace("/min-pond/avlshunder");
    },
  });

  useEffect(() => {
    window.analytics.page("Ny hund");
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const save = () => {
    if (userId !== -1) {
      if (dogsName === "") {
        setErrorMessage("Hunden må ha et navn");
      } else if (maleGender === undefined) {
        setErrorMessage("Du må velge kjønn på hunden");
      } else if (birthDate === "") {
        setErrorMessage("Du må velge fødselsdato");
      } else if (registrationNumber === "") {
        setErrorMessage("Du må legge til et registreringsnummer");
      } else {
        setErrorMessage(undefined);
        uploadDog({
          userId: userId,
          name: dogsName,
          ADLeft: ADLeft ?? -1,
          AdRight: ADRight ?? -1,
          born: new Date(birthDate),
          ownersName,
          gender: maleGender ? "male" : "female",
          breed: breed,
          description: personality,
          dhp,
          pibbpi,
          wormTreatment,
          eye: eyeCheck,
          image,
          medicines: medications,
          rabies,
          dnaTest,
          mentalTest,
          mentalTestFile: mentalFile,
          dnaTestFile: dnaFile,
          eyeFile,
          vetCertificate: vetFile,
          HDLeft: HDLeft ?? -1,
          championTitle: champions,
          HDRight: HDRight ?? -1,
          isOwner: myDog,
          type: "Dad",
          weight: weight ?? -1,
          NKKid: registrationNumber,
          PatellaLeft: patellaLeft ?? -1,
          PatellaRight: patellaRight ?? -1,
          color,
          dogId: parseInt(id) ?? undefined,
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      const dog = data?.getMyDogs.filter(
        (item) => item.id.toString() === id
      )[0];
      if (dog) {
        setDogsName(dog.name);
        setImage(
          dog.images?.length ? dog.images[0] : "/images/placeholder-dog.png"
        );
        setBreed(dog.breed);
        setPersonality(dog.description);
        setRegistrationNumber(dog.NKKId ?? "");
        setRegistered(dog.NKKId !== undefined && dog.NKKId !== null);
        setMaleGender(dog.gender === "male");
        setOwnersName(dog?.ownersName ?? "");
        setMyDog(dog.isOwner);
        setADLeft(dog.ADLeft ?? undefined);
        setADRight(dog.ADRight ?? undefined);
        setHDLeft(dog.HDLeft ?? undefined);
        setHDRight(dog.HDRight ?? undefined);
        setPatellaLeft(dog.PatellaLeft ?? undefined);
        setPatellaRight(dog.PatellaRight ?? undefined);
        setBirthDate(format(new Date(dog.born), "yyyy-MM-dd"));
        setRabies(dog.rabies);
        setDhp(dog.dhp);
        setPibbpi(dog.pibbpi);
        setWeight(dog.weight ?? undefined);
        setColor(dog.color ?? "");
        setDnaTest(dog.dnaTest ?? false);
        setMentalTest(dog.mentalTest ?? false);
        setMedications(dog.medicines);
        setMedicationDetails(dog.healthInfo ?? "");
        setWormTreatment(dog.wormTreatment);
        setEyeCheck(dog.eye ?? false);
        setChampions(dog.championTitle ?? [""]);
      }
    }
  }, [data]);

  const updateChampions = (value: string, index: number) => {
    const arr: string[] = [];
    champions.map((champion, i) => {
      if (index === i) {
        arr.push(value);
      } else {
        arr.push(champion);
      }
    });
    setChampions(arr);
  };

  const hiddenFileInputImage = useRef<HTMLInputElement>(null);
  const hiddenFileInputVet = useRef<HTMLInputElement>(null);
  const hiddenFileInputEye = useRef<HTMLInputElement>(null);
  const hiddenFileInputDna = useRef<HTMLInputElement>(null);
  const hiddenFileInputMental = useRef<HTMLInputElement>(null);
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<File | null>(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (event: any, type: string) => {
    // capture file into state
    const file = event.target.files[0];
    const uuidFilename = new File([file], v4() + file.name);
    setFileType(type);
    setFileSelected(uuidFilename);
  };

  useEffect(() => {
    if (fileSelected) {
      onFileUpload(fileType);
    }
  }, [fileSelected]);

  const onFileUpload = async (file: string) => {
    // prepare UI
    setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    await uploadFileToBlob(fileSelected);
    if (file === "image") {
      setImage(
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    } else if (file === "vet") {
      setVetFile(
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    } else if (file === "mental") {
      setMentalFile(
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    } else if (file === "DNA") {
      setDnaFile(
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    } else if (file === "eye") {
      setEyeFile(
        `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
      );
    }

    // reset state/form
    setFileSelected(null);
    setUploading(false);
  };

  return (
    <div className={css.NewDog}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <DashboardBanner
        image="/images/icons/dashboard/paw-circle.svg"
        percentage={60}
        title="Avlshunder"
        info="Her kan du legge til egne hunder og hunder brukt til avl til oppdrett. Du kan legge til egenskaper, championater og helse; samt. linke til NKK."
      />
      <div className={css.mainContainer}>
        <div className={css.leftContainer}>
          <img
            src={image ?? "/images/placeholder-dog.png"}
            alt="bilde av hunden"
          />
          <Button
            label="Last opp bilde"
            onClick={() => {
              hiddenFileInputImage.current &&
                hiddenFileInputImage.current.click();
            }}
            disabled={uploading}
          />
          <input
            type="file"
            hidden
            ref={hiddenFileInputImage}
            onChange={(e) => onFileChange(e, "image")}
          />
          <Button
            label="Slett bilde"
            skin="noColor"
            onClick={() => setImage("/images/placeholder-dog.png")}
          />
        </div>
        <div className={css.rightContainer}>
          <div>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox checked={myDog} onChange={() => setMyDog(!myDog)} />
                }
                label="Min hund"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!myDog}
                    onChange={() => setMyDog(!myDog)}
                  />
                }
                label="Ikke min hund"
              />
            </FormGroup>
            <p className={css.warning}>
              Legg til hund her slik at du kan gi informasjon om både mor og far
              når du legger ut et valpekull. Ettersom dette ikke er din hund vil
              den ikke vises på din profil, men bare i valpekull-annonsen.{" "}
            </p>
            {!myDog && (
              <div className={css.singleContainer}>
                <Input
                  id="ownersName"
                  type="text"
                  onChange={(value) => setOwnersName(value)}
                  value={ownersName}
                  placeholder="Navn på eier/kennel"
                  className={css.singleInput}
                />
              </div>
            )}
            <div className={css.singleContainer}>
              <Input
                id="dogsName"
                type="text"
                onChange={(value) => setDogsName(value)}
                value={dogsName}
                placeholder="Navn på hund"
                className={css.singleInput}
              />
            </div>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={maleGender}
                    onChange={() => setMaleGender(!maleGender)}
                  />
                }
                label="Hannhund"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!maleGender}
                    onChange={() => setMaleGender(!maleGender)}
                  />
                }
                label="Tispe"
              />
            </FormGroup>
            <div className={css.dualContainer}>
              <FormControl>
                <InputLabel>Rase</InputLabel>
                <Select
                  name={`breed`}
                  id={`breed`}
                  onChange={(e) => setBreed(e.target.value as string)}
                  className={css.dualInput}
                  value={breed}
                  label="Rase"
                >
                  {allBreeds.map((item) => (
                    <MenuItem sx={{ zIndex: 100000 }} value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Input
                id="color"
                type="text"
                onChange={(value) => setColor(value)}
                value={color}
                className={css.dualInput}
                placeholder="Farge"
              />
            </div>
            <div className={css.dualContainer}>
              <Input
                id={`birthDate`}
                type="date"
                onChange={(value) => setBirthDate(value)}
                value={birthDate}
                placeholder="Fødselsdato"
                className={css.dualInput}
              />
              <Input
                id="weight"
                type="number"
                onChange={(value) => setWeight(parseFloat(value))}
                value={weight?.toString() ?? ""}
                className={css.dualInput}
                placeholder="Kg"
              />
            </div>
            <div className={css.singleContainer}>
              <Input
                id="nkkId"
                type="text"
                onChange={(value) => setRegistrationNumber(value)}
                value={registrationNumber}
                className={css.singleInput}
                placeholder="Registreringsnummer"
              />
              <FormGroup className={css.formGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!registered}
                      onChange={() => setRegistered(!registered)}
                    />
                  }
                  label="Ikke registrert"
                />
              </FormGroup>
            </div>
          </div>
          <div className={css.singleContainer}>
            <Input
              id="personality"
              type="text"
              onChange={(value) => setPersonality(value)}
              value={personality}
              placeholder="Om hunden"
              className={css.singleInput}
              multiline
            />
          </div>
          <div className={css.singleContainer}>
            {champions.map((champion, index) => (
              <FormControl
                key={`${champion}-${index}`}
                className={css.championContainer}
              >
                <InputLabel>Championater</InputLabel>
                <Select
                  name={`champion`}
                  id={`champion`}
                  value={champion}
                  onChange={(e) =>
                    updateChampions(e.target.value as string, index)
                  }
                  className={css.singleInput}
                  label="Championater"
                >
                  {AllChamps.map((item, i) => (
                    <MenuItem sx={{ zIndex: 100000 }} value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
            <Button
              skin="noColor"
              label="+ Legg til flere"
              onClick={() => setChampions([...champions, ""])}
            />
          </div>
          <h2>Helse</h2>
          <div>
            <div>Vaksiner</div>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wormTreatment}
                    onChange={() => setWormTreatment(!wormTreatment)}
                  />
                }
                label="Ormekur"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={dhp} onChange={() => setDhp(!dhp)} />
                }
                label="DHP"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pibbpi}
                    onChange={() => setPibbpi(!pibbpi)}
                  />
                }
                label="PiBbPi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rabies}
                    onChange={() => setRabies(!rabies)}
                  />
                }
                label="Rabies"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medications}
                    onChange={() => setMedications(!registered)}
                  />
                }
                label="Bruker medisiner"
              />
            </FormGroup>
            {medications && (
              <Input
                id="medication"
                type="text"
                onChange={(value) => setMedicationDetails(value)}
                value={medicationDetails}
                placeholder="Hvilke medisiner bruker hunden?"
                className={css.singleInput}
              />
            )}
          </div>
          <div>
            <h2>Veterinærattest</h2>
            <Button
              label="+ Last opp dokumentasjon"
              skin="noColor"
              onClick={() => {
                hiddenFileInputVet.current &&
                  hiddenFileInputVet.current.click();
              }}
              disabled={uploading}
            />
            <input
              type="file"
              hidden
              ref={hiddenFileInputVet}
              onChange={(e) => onFileChange(e, "vet")}
            />
          </div>
          <div>
            <h2>Genetikk</h2>
            <div className={css.healthGrid}>
              <div>Hofteleddsdysplasi </div>
              <FormControl className={css.inputSelect}>
                <InputLabel>Venstre</InputLabel>
                <Select
                  name={`HDLeft`}
                  id={`HDLeft`}
                  value={HDLeft ?? ""}
                  onChange={(e) =>
                    setHDLeft(parseInt(e.target.value as string))
                  }
                  label="Venstre"
                >
                  <MenuItem sx={{ zIndex: 100000 }} value={"1"}>
                    A (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"2"}>
                    B (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"3"}>
                    C (Svak grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"4"}>
                    D (Middels grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"5"}>
                    E (Sterk grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-1"}>
                    Ikke relevant
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"0"}>
                    Ikke testet
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={css.inputSelect}>
                <InputLabel>Høyre</InputLabel>
                <Select
                  name={`HDRight`}
                  id={`HDRight`}
                  value={HDRight ?? ""}
                  onChange={(e) =>
                    setHDRight(parseInt(e.target.value as string))
                  }
                  label="Høyre"
                >
                  <MenuItem sx={{ zIndex: 100000 }} value={"1"}>
                    A (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"2"}>
                    B (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"3"}>
                    C (Svak grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"4"}>
                    D (Middels grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"5"}>
                    E (Sterk grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-1"}>
                    Ikke relevant
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-2"}>
                    Ikke testet
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={css.healthGrid}>
              <div>Albueleddsdysplasi</div>
              <FormControl className={css.inputSelect}>
                <InputLabel>Venstre</InputLabel>
                <Select
                  name={`Albueleddsdysplasi-venstre`}
                  id={`Albueleddsdysplasi-venstre`}
                  value={ADLeft ?? ""}
                  onChange={(e) =>
                    setADLeft(parseInt(e.target.value as string))
                  }
                  label="Venstre"
                >
                  <MenuItem sx={{ zIndex: 100000 }} value={"0"}>
                    0 (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"1"}>
                    1 (Svak grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"2"}>
                    2 (Middels grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"3"}>
                    3 (Sterk grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-1"}>
                    Ikke relevant
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-2"}>
                    Ikke testet
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={css.inputSelect}>
                <InputLabel>Høyre</InputLabel>
                <Select
                  name={`Albueleddsdysplasi-høyre`}
                  id={`Albueleddsdysplasi-høyre`}
                  value={ADRight ?? ""}
                  onChange={(e) =>
                    setADRight(parseInt(e.target.value as string))
                  }
                  label="Høyre"
                >
                  <MenuItem sx={{ zIndex: 100000 }} value={"0"}>
                    0 (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"1"}>
                    1 (Svak grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"2"}>
                    2 (Middels grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"3"}>
                    3 (Sterk grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-1"}>
                    Ikke relevant
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-2"}>
                    Ikke testet
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={css.healthGrid}>
              <div>Patella </div>
              <FormControl className={css.inputSelect}>
                <InputLabel>Venstre</InputLabel>
                <Select
                  name={`patellaLeft`}
                  id={`patellaLeft`}
                  value={patellaLeft ?? ""}
                  onChange={(e) =>
                    setPatellaLeft(parseInt(e.target.value as string))
                  }
                  label="Venstre"
                >
                  <MenuItem sx={{ zIndex: 100000 }} value={"0"}>
                    0 (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"1"}>
                    1 (Svak grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"2"}>
                    2 (Middels grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"3"}>
                    3 (Sterk grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-1"}>
                    Ikke relevant
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-2"}>
                    Ikke testet
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={css.inputSelect}>
                <InputLabel>Høyre</InputLabel>
                <Select
                  name={`patellaRight`}
                  id={`patellaRight`}
                  value={patellaRight ?? ""}
                  onChange={(e) =>
                    setPatellaRight(parseInt(e.target.value as string))
                  }
                  label="Høyre"
                >
                  <MenuItem sx={{ zIndex: 100000 }} value={"0"}>
                    0 (Fri)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"1"}>
                    1 (Svak grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"2"}>
                    2 (Middels grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"3"}>
                    3 (Sterk grad)
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-1"}>
                    Ikke relevant
                  </MenuItem>
                  <MenuItem sx={{ zIndex: 100000 }} value={"-2"}>
                    Ikke testet
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <h2>Øyelysning</h2>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={eyeCheck}
                    onChange={() => setEyeCheck(!eyeCheck)}
                  />
                }
                label="Ja"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!eyeCheck}
                    onChange={() => setEyeCheck(!eyeCheck)}
                  />
                }
                label="Nei"
              />
            </FormGroup>
            <Button
              label="+ Last opp dokumentasjon"
              skin="noColor"
              onClick={() => {
                hiddenFileInputEye.current &&
                  hiddenFileInputEye.current.click();
              }}
              disabled={uploading}
            />
            <input
              type="file"
              hidden
              ref={hiddenFileInputEye}
              onChange={(e) => onFileChange(e, "eye")}
            />
          </div>
          <div>
            <h2>DNA test</h2>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dnaTest}
                    onChange={() => setDnaTest(!dnaTest)}
                  />
                }
                label="Ja"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!dnaTest}
                    onChange={() => setDnaTest(!dnaTest)}
                  />
                }
                label="Nei"
              />
            </FormGroup>
            <Button
              label="+ Last opp dokumentasjon"
              skin="noColor"
              onClick={() => {
                hiddenFileInputDna.current &&
                  hiddenFileInputDna.current.click();
              }}
              disabled={uploading}
            />
            <input
              type="file"
              hidden
              ref={hiddenFileInputDna}
              onChange={(e) => onFileChange(e, "DNA")}
            />
          </div>
          <div>
            <h2>Mental test</h2>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mentalTest}
                    onChange={() => setMentalTest(!mentalTest)}
                  />
                }
                label="Ja"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!mentalTest}
                    onChange={() => setMentalTest(!mentalTest)}
                  />
                }
                label="Nei"
              />
            </FormGroup>
            <Button
              label="+ Last opp dokumentasjon"
              skin="noColor"
              onClick={() => {
                hiddenFileInputMental.current &&
                  hiddenFileInputMental.current.click();
              }}
              disabled={uploading}
            />
            <input
              type="file"
              hidden
              ref={hiddenFileInputMental}
              onChange={(e) => onFileChange(e, "mental")}
            />
          </div>
          <p className={css.warning}>
            Dokumentasjon du laster opp blir ikke publisert offentlig på
            profilen din. Vi gjennomfører jevnlige stikkprøver for å verifisere
            at helsetestene faktisk er gjennomført. Oppdages det feil i
            dokumentasjonen kan du risikere å bli utestengt fra POND.
          </p>
        </div>
      </div>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div>
        <Button
          label="Tilbake"
          skin="lightGreen"
          onClick={() => history.goBack()}
        />
        <Button label="Fullfør" skin="dark" onClick={save} />
      </div>
    </div>
  );
};

export default NewDog;
