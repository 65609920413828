import React from "react";

export const Paw: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="35"
    viewBox="0 0 41 35"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.193 14.9787C20.486 14.8431 20.8197 14.843 21.1128 14.9785C29.5745 18.8901 32.7145 25.0687 31.7631 29.6354C31.2847 31.9317 29.7477 33.7068 27.5067 34.2107C25.4968 34.6626 23.1203 34.0519 20.6568 32.2678C18.1906 34.0561 15.8113 34.6685 13.7992 34.2161C11.5582 33.7121 10.0213 31.9367 9.54293 29.6402C8.59164 25.073 11.7313 18.8931 20.193 14.9787ZM19.882 29.8888C20.2838 29.5017 20.9038 29.4623 21.3504 29.824C23.736 31.7567 25.7023 32.1566 27.029 31.8583C28.3148 31.5692 29.2259 30.5801 29.53 29.1204C30.1316 26.2326 28.2613 21.0811 20.6532 17.3984C13.045 21.0837 11.1744 26.2365 11.7761 29.1252C12.0802 30.5853 12.9913 31.5746 14.2771 31.8637C15.5901 32.159 17.5295 31.7704 19.882 29.8888Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52877 11.9474C4.78673 12.513 4.46255 14.0977 5.53326 15.6528C6.60397 17.208 8.13605 17.3778 8.87809 16.8122C9.62013 16.2466 9.94431 14.662 8.8736 13.1068C7.80289 11.5516 6.27081 11.3818 5.52877 11.9474ZM3.68186 17.064C2.16207 14.8566 2.06231 11.6193 4.18759 9.99939C6.31288 8.37946 9.20523 9.48817 10.725 11.6956C12.2448 13.903 12.3446 17.1403 10.2193 18.7602C8.09399 20.3802 5.20164 19.2715 3.68186 17.064Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7235 3.82538C13.7625 4.03811 12.863 5.35394 13.2219 7.14862C13.5807 8.9433 14.9077 9.76631 15.8688 9.55358C16.8298 9.34086 17.7292 8.02502 17.3704 6.23035C17.0116 4.43567 15.6846 3.61266 14.7235 3.82538ZM10.9847 7.64381C10.4511 4.97485 11.6872 2.03936 14.2529 1.47145C16.8186 0.90354 19.074 3.06619 19.6076 5.73515C20.1412 8.40411 18.9051 11.3396 16.3394 11.9075C13.7737 12.4754 11.5183 10.3128 10.9847 7.64381Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.127 13.4448C31.0563 15 31.3805 16.5846 32.1225 17.1502C32.8646 17.7158 34.3966 17.5461 35.4674 15.9909C36.5381 14.4357 36.2139 12.851 35.4718 12.2854C34.7298 11.7198 33.1977 11.8896 32.127 13.4448ZM30.7813 19.0983C28.6561 17.4783 28.7558 14.2411 30.2756 12.0336C31.7954 9.82619 34.6877 8.71749 36.813 10.3374C38.9383 11.9573 38.8385 15.1946 37.3188 17.402C35.799 19.6095 32.9066 20.7182 30.7813 19.0983Z"
      fill="#065C53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9347 6.04474C23.5759 7.83942 24.4753 9.15525 25.4364 9.36797C26.3974 9.5807 27.7245 8.75769 28.0833 6.96301C28.4421 5.16834 27.5427 3.8525 26.5817 3.63978C25.6206 3.42705 24.2936 4.25006 23.9347 6.04474ZM24.9658 11.7219C22.4001 11.154 21.1639 8.21851 21.6976 5.54955C22.2312 2.88059 24.4866 0.717934 27.0523 1.28584C29.618 1.85376 30.8541 4.78925 30.3205 7.45821C29.7869 10.1272 27.5315 12.2898 24.9658 11.7219Z"
      fill="#065C53"
    />
  </svg>
);
