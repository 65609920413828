import { InputAdornment, TextField } from "@mui/material";
import React, { KeyboardEventHandler, ReactElement } from "react";

export interface Props {
  id: string;
  type: string;
  value: string;
  onChange: (value: string) => void;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  onBlur?: () => void;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  multiline?: boolean;
}

const Input: React.FC<Props> = function ({
  className,
  onChange,
  onKeyPress,
  placeholder,
  errorMessage,
  leftIcon,
  rightIcon,
  multiline,
  ...rest
}) {
  return (
    <TextField
      inputProps={{
        className: `${className}`,
      }}
      InputProps={{
        startAdornment: leftIcon ? (
          <InputAdornment position="start" sx={{ color: "#065C53" }}>
            {leftIcon}
          </InputAdornment>
        ) : null,
        endAdornment: rightIcon ? (
          <InputAdornment
            position="end"
            sx={{
              color: "#065C53",
            }}
          >
            {rightIcon}
          </InputAdornment>
        ) : null,
      }}
      FormHelperTextProps={{ sx: { background: "transparent" } }}
      onChange={(e) => onChange(e.target.value)}
      onKeyPress={onKeyPress}
      sx={{
        marginBottom: "0.5rem",
        borderRadius: 2,
        input: {
          background: "#fff",
          borderRadius: 2,
        },
      }}
      helperText={errorMessage}
      variant="outlined"
      label={placeholder}
      multiline={multiline ?? false}
      rows={8}
      {...rest}
    />
  );
};

export default Input;
