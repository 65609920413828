import Axios from "axios";
import { AuthApi } from "./AuthApi";

export const axios = Axios.create({
  baseURL: "https://pond-api.azurewebsites.net" + "/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const authApi = new AuthApi(axios);

axios.interceptors.request.use(authApi.requestInterceptor);
