import { AxiosInstance, AxiosRequestConfig } from "axios";

export class AuthApi {
  private accessToken?: string | null;
  private refreshToken?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private grantRequest: Promise<any> | null;
  private resolveAuth: ((value?: unknown) => void) | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public nextAuth: Promise<any>;
  public tokenListener?: (
    accessToken: string,
    refreshToken: string
  ) => void | null;

  constructor(private axios: AxiosInstance) {
    this.grantRequest = null;
    this.resolveAuth = null;
    this.nextAuth = new Promise((resolve) => {
      this.resolveAuth = resolve;
    });
  }

  setAccessToken = (accessToken?: string | null): void => {
    this.accessToken = accessToken;
  };

  setRefreshToken = (refreshToken?: string | null): void => {
    this.refreshToken = refreshToken;
  };

  /**
   * Adds Authorization headers for non-oauth requests
   */
  requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (this.accessToken && !config.headers?.Authorization) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${this.accessToken}`,
      };
    }

    return config;
  };
}
