import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import css from "../../styles/AboutPond.module.scss";
import { team } from "../../model/Team";
import { Community } from "../../components/Community";
import { SanityComponent, getPosts, Section } from "../../utils/sanity-common";

const AboutPond: React.FC = function () {
  const [sections, setSections] = useState<Section[]>([]);
  const [teamModal, setTeamModal] = useState(false);

  useEffect(() => {
    window.analytics.page("Om oss");
    getPostsType();
  }, []);

  async function getPostsType() {
    setSections(await getPosts("om-oss"));
  }

  return (
    <div className={css.AboutPond}>
      <div className={css.infoPage}>
        {sections?.map((section, index) => (
          <div key={index}>{SanityComponent(section)}</div>
        ))}
      </div>
      <div className={css.content}>
        <div className={css.community}>
          <h3>Vårt felleskap</h3>
          <Community />
        </div>
      </div>
      <Modal
        show={teamModal}
        onHide={() => setTeamModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={css.teamModal}>
            <h3>Møt teamet vårt!</h3>
            <div className={css.teamGrid}>
              {team.map((member) => (
                <div key={member.name} className={css.teamMember}>
                  <img src={member.image} alt={member.name} />
                  <div className={css.name}>{member.name}</div>
                  <div>{member.title}</div>
                  <div>{member.email.length ? member.email : "-"}</div>
                </div>
              ))}
            </div>
            <Button label="Gå tilbake" onClick={() => setTeamModal(false)} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AboutPond;
