import React, { useEffect, useState } from "react";
import css from "../../styles/Application.module.scss";
import Button from "../../components/Button";
import { useHistory, useParams } from "react-router-dom";
import {
  useApproveApplicationMutation,
  useCancelApplicationMutation,
  useDenyApplicationMutation,
  useGetApplicationQuery,
  useGetUserByEmailQuery,
} from "../../api/graphql.gen";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-bootstrap/Modal";
import { Avatar } from "@mui/material";

interface RouteParams {
  applicationId: string;
}

const Application: React.FC = () => {
  const history = useHistory();
  const [denyModal, setDenyModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const { applicationId } = useParams<RouteParams>();
  const { data } = useGetApplicationQuery({
    id: parseInt(applicationId),
  });
  const application = data?.getApplication;
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const isBreeder = userInfo?.getUserByEmail.breeder;
  const { mutate: denyApplication } = useDenyApplicationMutation({
    onSuccess: () => {
      setDenyModal(false);
      history.push("/min-pond/mine-soknader");
    },
  });
  const { mutate: cancelApplication } = useCancelApplicationMutation({
    onSuccess: () => {
      setDenyModal(false);
      history.push("/min-pond/mine-soknader");
    },
  });
  const { mutate: approveApplication } = useApproveApplicationMutation({
    onSuccess: () => {
      setApproveModal(false);
      history.push("/min-pond/mine-soknader");
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
    if (userInfo?.getUserByEmail && application) {
      if (
        userInfo.getUserByEmail.id !== application.applicant?.id &&
        userInfo.getUserByEmail.id !== application.breeder?.id
      ) {
        history.push("/");
      }
    }
  }, [isAuthenticated]);

  return (
    <div className={css.Application}>
      {application && (
        <div className={css.overview}>
          <div className={css.header}>
            <Avatar
              src={
                application.applicant?.profilePicture ??
                "/images/default-avatar.png"
              }
              className={css.avatar}
            />
            <div className={css.applicantInfo}>
              Søknad fra {application.applicant?.firstName ?? "Fornavn"}{" "}
              <span>
                fra {application.applicant?.address?.city ?? "Stedsnavn"}
              </span>
            </div>
          </div>
          <div className={css.content}>
            <h2>Hvilket kull ønsker du å søke på?</h2>
            <div className={css.breedCard}>
              <p>{application.ad?.title}</p>
              <img
                src={application.ad?.adImage ?? "/images/placeholder-dog.png"}
                alt="Annonsebilde"
              />
            </div>
            <h2>Har du erfaring med hund?</h2>
            {application.hasExperience ? (
              <div className={css.experienceItem}>Ja</div>
            ) : (
              <div className={css.experienceItem}>Nei</div>
            )}
            <h2>Hvorfor ønsker du akkurat denne rasen?</h2>
            <p>{application.reasoning}</p>
            <h2>Hva er motivasjonen din til å skaffe deg hund?</h2>
            <p>{application.otherMotivation}</p>
            <div className={css.experienceGrid}>
              {application.motivation.map((item) => (
                <div key={item} className={css.motivationItem}>
                  {item}
                </div>
              ))}
            </div>
            <h2>Fortell om deg selv</h2>
            {application.experienceDetails}
            {isBreeder ? (
              <div>
                {application.status !== "ACCEPTED" && (
                  <Button
                    label="Godkjenn for valpeliste"
                    onClick={() => setApproveModal(true)}
                  />
                )}
                {application.status !== "DENIED" && (
                  <Button
                    label="Avvis søknad"
                    onClick={() => setDenyModal(true)}
                    skin="error"
                  />
                )}
                <Button
                  label="Tilbake"
                  onClick={() => history.goBack()}
                  skin="light"
                />
              </div>
            ) : (
              <div>
                {application.status !== "DENIED" && (
                  <Button
                    label="Trekk søknad"
                    onClick={() => setDenyModal(true)}
                    skin="error"
                  />
                )}
                <Button
                  label="Tilbake"
                  onClick={() => history.goBack()}
                  skin="light"
                />
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={approveModal}
        onHide={() => setApproveModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img src="/images/puppies.svg" alt="Valper" />
          <h2>
            Ved å godkjenne søknaden setter du{" "}
            {application?.applicant?.firstName} på venteliste for valp. Søknaden
            vil være tilgjengelig under fanen “valpeliste”.
          </h2>
          <div>
            <Button
              label="Tilbake"
              skin="lightGreen"
              onClick={() => setApproveModal(false)}
            />
            <Button
              label="Godkjenn for valpeliste"
              onClick={() =>
                approveApplication({ id: parseInt(applicationId) })
              }
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={denyModal}
        onHide={() => setDenyModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img src="/images/ouch.svg" alt="Trist hund" />
          <h2>
            {userInfo?.getUserByEmail.breeder
              ? `Er du sikker på at du vil avvise søknaden til ${application?.applicant?.firstName}?`
              : "Er du sikker på at du vil trekke søknaden din?"}
          </h2>
          <div>
            <Button
              label="Tilbake"
              skin="lightGreen"
              onClick={() => setDenyModal(false)}
            />
            <Button
              label={
                userInfo?.getUserByEmail.breeder
                  ? "Avvis søknad"
                  : "Trekk søknad"
              }
              skin="error"
              onClick={() => {
                if (application) {
                  userInfo?.getUserByEmail.breeder
                    ? denyApplication({ id: parseInt(applicationId) })
                    : cancelApplication({ id: parseInt(applicationId) });
                }
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Application;
