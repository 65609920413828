import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowBackIos,
  FilterList,
  MoreVert,
  Pets,
  Send,
} from "@mui/icons-material";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import {
  User,
  useGetChatsQuery,
  useGetMessagesQuery,
  useGetUserByEmailQuery,
  useGetUserQuery,
  useSendMessageMutation,
} from "../api/graphql.gen";
import { useIsMobile } from "../hooks/useIsMobile";
import css from "../styles/Chat.module.scss";
import { useHistory } from "react-router-dom";
import { Chat } from "@navikt/ds-react";
import LoadingScreen from "../components/LoadingScreen";

const ChatScreen: React.FC = function () {
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<
    { sender: string; message: string; date: Date }[]
  >([]);
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? -1;

  const { data: myChats, isLoading } = useGetChatsQuery();

  const { data: me } = useGetUserQuery({ id: userId });

  const [selectedChat, setSelectedChat] = useState("");
  const [selectedSeller, setSelectedSeller] = useState<User>();

  const { data: chatMessages } = useGetMessagesQuery({
    chatId: selectedChat,
  });

  const { mutate: storeMessage } = useSendMessageMutation({
    onSuccess: (data) => {
      sendMessage("me", data.sendMessage.content);
    },
  });

  const socketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap>>();

  const { isMobile } = useIsMobile();

  useEffect(() => {
    window.analytics.page("Chat");
  }, []);

  useEffect(() => {
    socketRef.current = io("https://pond-chat.azurewebsites.net");
    socketRef.current.on("connect", () => {});
    socketRef.current.emit("create-room", selectedChat);
    return () => {
      if (socketRef && socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [selectedChat]);

  useEffect(() => {
    if (chatMessages?.getMessages) {
      const allMessages: React.SetStateAction<
        { sender: string; message: string; date: Date }[]
      > = [];
      chatMessages.getMessages.forEach((item) => {
        allMessages.push({
          sender: item.senderId === userId ? "me" : "other",
          message: item.content,
          date: item.created,
        });
      });
      setMessages(allMessages);
    }
  }, [chatMessages, selectedChat]);

  useEffect(() => {
    if (socketRef && socketRef.current) {
      socketRef.current.on("message", (data) => {
        setMessages([
          ...messages,
          { sender: "other", message: data.message, date: new Date() },
        ]);
      });
    }
  });

  const sendMessage = (sender: string, content: string) => {
    if (content.length > 0 && socketRef && socketRef.current) {
      setMessages([
        ...messages,
        { sender, message: content, date: new Date() },
      ]);
      socketRef.current.emit("message", {
        sender,
        message,
        room: selectedChat,
      });
      setMessage("");
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      storeMessage({
        chatId: selectedChat,
        content: message,
      });
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return isMobile ? (
    <div className={css.ChatMobile}>
      <div
        className={css.backContainer}
        onClick={() => {
          if (selectedChat !== "") {
            setSelectedChat("");
          } else {
            history.goBack();
          }
        }}
      >
        <ArrowBackIos /> Tilbake
      </div>
      {selectedChat === "" ? (
        <div className={css.list}>
          <List>
            {myChats?.getChats.map((item, index) => {
              const isBuyer = item.buyer.id === userId;
              return (
                <ListItem
                  key={index}
                  className={css.listItem}
                  onClick={() => {
                    setSelectedChat(item.id);
                    setSelectedSeller(item.seller as User);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="Bilde av samtale deltaker"
                      src={
                        isBuyer
                          ? item.seller.profilePicture ??
                            "/images/default-avatar.png"
                          : item.buyer.profilePicture ??
                            "/images/default-avatar.png"
                      }
                      className={css.avatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      isBuyer
                        ? `${item.seller.firstName} ${item.seller.lastName}`
                        : `${item.buyer.firstName} ${item.buyer.lastName}`
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {format(new Date(), "dd.MM.yy")}
                        </Typography>
                        {item.chatMessages &&
                          ` - ${
                            item.chatMessages[0]?.content.substring(0, 30) ?? ""
                          }`}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      ) : (
        <div className={css.messages}>
          {myChats?.getChats.length === 0 ? (
            <div className={css.noMessagesYet}>
              <img src="/images/coming_soon.svg" alt="hund ilustrasjon" />
              <h4>
                {me?.getUser.breeder
                  ? "Her vil alle meldinger mellom deg og kjøper dukke opp."
                  : "Her vil alle meldinger mellom deg og oppdretter dukke opp."}
              </h4>
            </div>
          ) : (
            <div className={css.messagesContainer}>
              {messages.map((content, index) => (
                <Chat
                  key={content.message + index}
                  timestamp={format(new Date(content.date), "dd.MM.yy")}
                  size="medium"
                  style={{ paddingBottom: 15 }}
                  name={
                    content.sender === "me"
                      ? me?.getUser.firstName
                      : selectedSeller?.firstName
                  }
                  avatar={
                    <Avatar
                      src={
                        content.sender === "me"
                          ? me?.getUser.profilePicture ?? ""
                          : selectedSeller?.profilePicture ?? ""
                      }
                    />
                  }
                  position={content.sender === "me" ? "right" : "left"}
                >
                  <Chat.Bubble>{content.message}</Chat.Bubble>
                </Chat>
              ))}
            </div>
          )}
          {myChats?.getChats.length === 0 ? undefined : (
            <div className={css.inputContainer}>
              <input
                id="search"
                type="search"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={css.chatInput}
                placeholder="Skriv melding...."
                onKeyDown={handleKeyDown}
                disabled={selectedChat === ""}
              />
              <Send
                className={css.sendButton}
                onClick={() =>
                  selectedChat !== "" &&
                  storeMessage({
                    chatId: selectedChat,
                    content: message,
                  })
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div className={css.Chat}>
      <div className={css.div6}>
        <List>
          {myChats?.getChats.map((item, index) => {
            const isBuyer = item.buyer.id === userId;
            return (
              <ListItem
                key={index}
                className={css.listItem}
                onClick={() => {
                  setSelectedChat(item.id);
                  setSelectedSeller(item.seller as User);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="Bilde av samtale deltaker"
                    src={
                      isBuyer
                        ? item.seller.profilePicture ??
                          "/images/default-avatar.png"
                        : item.buyer.profilePicture ??
                          "/images/default-avatar.png"
                    }
                    className={css.avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    isBuyer
                      ? `${item.seller.firstName} ${item.seller.lastName}`
                      : `${item.buyer.firstName} ${item.buyer.lastName}`
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {format(new Date(), "dd.MM.yy")}
                      </Typography>
                      {item.chatMessages &&
                        ` - ${
                          item.chatMessages[0]?.content.substring(0, 30) ?? ""
                        }`}
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className={css.div7}>
        {myChats?.getChats.length === 0 ? (
          <div className={css.noMessagesYet}>
            <img src="/images/coming_soon.svg" alt="hund ilustrasjon" />
            <h4>
              {me?.getUser.breeder
                ? "Her vil alle meldinger mellom deg og kjøper dukke opp."
                : "Her vil alle meldinger mellom deg og oppdretter dukke opp."}
            </h4>
          </div>
        ) : (
          <div className={css.messagesContainer}>
            {messages.map((content, index) => (
              <Chat
                style={{ paddingBottom: 15 }}
                key={content.message + index}
                timestamp={format(new Date(content.date), "dd.MM.yy")}
                name={
                  content.sender === "me"
                    ? me?.getUser.firstName
                    : selectedSeller?.firstName
                }
                size="medium"
                avatar={
                  <Avatar
                    src={
                      content.sender === "me"
                        ? me?.getUser.profilePicture ?? ""
                        : selectedSeller?.profilePicture ?? ""
                    }
                  />
                }
                position={content.sender === "me" ? "right" : "left"}
              >
                <Chat.Bubble>{content.message}</Chat.Bubble>
              </Chat>
            ))}
          </div>
        )}
        <div className={css.inputContainer}>
          <Pets />
          <input
            id="search"
            type="search"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={css.chatInput}
            placeholder="Skriv melding...."
            autoFocus
            onKeyDown={handleKeyDown}
            disabled={selectedChat === ""}
          />
          <Send
            className={css.sendButton}
            onClick={() =>
              selectedChat !== "" &&
              storeMessage({
                chatId: selectedChat,
                content: message,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
