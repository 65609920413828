import React, { LegacyRef } from "react";
import { DogParent } from "../../api/graphql.gen";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/ProfileOurDogs.module.scss";

export interface Props {
  dogs: DogParent[];
  onClick: (dog: DogParent) => void;
  ref?: LegacyRef<HTMLDivElement>;
}

const ProfileOurDogs: React.FC<Props> = ({ dogs, ref, onClick }: Props) => {
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const { isMobile } = useIsMobile();

  return (
    <div className={css.tabOurBreeds} ref={ref}>
      {!isMobile && <h6>Mine hunder</h6>}
      <div className={css.ourDogsContainer}>
        {dogs
          .filter((item) => item.isOwner)
          .map((dog, index) => (
            <div className={css.dogInfoContainer} key={`dogsBreed-${index}`}>
              <img
                onClick={() => onClick(dog as DogParent)}
                src={
                  dog.images && dog.images?.length > 0
                    ? dog.images[0]
                    : "/images/placeholder-dog.png"
                }
                alt={capitalize(dog.breed)}
                onError={(e) => {
                  e.currentTarget.src = "/images/placeholder-dog.png";
                }}
              />
              <div className={css.dogInfo}>
                <h5>{dog.name}</h5>
                <div className={css.nkkId}>
                  {dog.NKKId == "" ? "-" : dog.NKKId}
                </div>
                <div
                  onClick={() => onClick(dog as DogParent)}
                  className={css.parentButton}
                >
                  <img src="/images/icons/heart.svg" alt="Hjerte ikon" />
                  Om hunden
                </div>
                <div
                  onClick={() => onClick(dog as DogParent)}
                  className={css.parentButton}
                >
                  <img src="/images/icons/heart.svg" alt="Hjerte ikon" />
                  Helse
                </div>
                <div
                  onClick={() => onClick(dog as DogParent)}
                  className={css.parentButton}
                >
                  <img src="/images/icons/award.svg" alt="Premie ikon" />
                  Championater
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfileOurDogs;
