import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGetUserByEmailQuery, useGetUserQuery } from "../../api/graphql.gen";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/Dashboard.module.scss";
import Footer from "./Footer";
import LoadingScreen from "../../components/LoadingScreen";
import { Alert, AlertTitle } from "@mui/material";

const Dashboard: React.FC = () => {
  const { logout, user: authUser, isAuthenticated } = useAuth0();
  const { data: userInfo } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const userId = userInfo?.getUserByEmail.id ?? -1;
  const { data: userData, isLoading } = useGetUserQuery({ id: userId });
  const user = userData?.getUser;
  const { isMobile } = useIsMobile();
  const history = useHistory();
  const isBreeder = user?.breeder;

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.analytics.page("Min POND");
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const dashboardText = isBreeder
    ? "Her har du full oversikt over ditt oppdrett, tilrettelagt for å gjøre det enklest mulig for deg"
    : "";

  return (
    <div className={css.Dashboard}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Dashboard" next="Min profil >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image={user?.profilePicture ?? null}
          title={`Velkommen tilbake, ${user?.firstName ?? ""}!`}
          percentage={60}
          subTitle="Min POND"
          info={dashboardText}
        />
        {isBreeder && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{ marginTop: 2 }}
            icon={false}
          >
            <AlertTitle>{`QualiPet rabattkode`}</AlertTitle>
            {`«PondQP40» - Denne vil gi 40% rabatt på alle produkter, bortsett fra allerede nedsatte varer, og vil heller ikke kunne brukes i kombinasjon med andre koder.`}
          </Alert>
        )}
        <div className={css.cardContainer}>
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Min profil"
              icon={
                <img src="/images/icons/dashboard/user.svg" alt="Person ikon" />
              }
              description="Se hvordan profilen din ser ut for andre!"
              onClick={() => history.push(`/oppdretter/${user?.id}`)}
            />
          )}
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Rediger kennel"
              icon={
                <img
                  src="/images/icons/dashboard/edit.svg"
                  alt="Rediger ikon"
                />
              }
              description="Endre din kennel."
              onClick={() => history.push(`/min-pond/rediger-profil`)}
            />
          )}
          <DashboardCard
            className={css.card}
            title="Kontoinnstillinger"
            icon={
              <img
                src="/images/icons/dashboard/settings.svg"
                alt="Innstillinger ikon"
              />
            }
            onClick={() => history.push(`/min-pond/kontoinnstillinger`)}
            description="Endre din profil."
          />
          <DashboardCard
            className={css.card}
            title="Mine søknader"
            icon={<img src="/images/icons/dashboard/file.svg" alt="Fil ikon" />}
            onClick={() => history.push(`/min-pond/mine-soknader`)}
            description="Oversikt over dine søknader."
          />
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Avlshunder"
              icon={
                <img src="/images/icons/dashboard/paw.svg" alt="Pote ikon" />
              }
              description="Avlshunder og oppdretters hunder."
              onClick={() => history.push(`/min-pond/avlshunder`)}
            />
          )}
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Valpekull"
              icon={
                <img
                  src="/images/icons/dashboard/puppies.svg"
                  alt="To poter ikon"
                />
              }
              description="Legg til og administrer valpekull."
              onClick={() => history.push(`/min-pond/mine-valper`)}
            />
          )}
          <DashboardCard
            className={css.card}
            title="Favoritter"
            icon={
              <img
                src="/images/icons/dashboard/paw.svg"
                alt="Innstillinger ikon"
              />
            }
            onClick={() => history.push(`/min-pond/favoritter`)}
            description="Oversikt over dine favioritter."
          />
        </div>
        {isMobile && (
          <div className={css.buttonContainer}>
            {/* {isBreeder ? (
              <Button label="Bytt til vanlig bruker" skin="transparent" />
            ) : (
              <Button label="Bytt til oppdretter bruker" skin="transparent" />
            )} */}
            <Button label="Logg ut" skin="lightGreen" onClick={logout} />
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;

//export default withAuthenticationRequired(Dashboard, {
//  onRedirecting: () => <NotFound />,
//});
